import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Loader } from "../components/toast/Loader";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
export class UserList extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.GetActionFormat = this.GetActionFormat.bind(this);
    this.toAdddUser = this.toAdddUser.bind(this);

    const { location } = this.props;

    this.state = {
      pageLoading: true,
      userList: [],
      columns: [
        {
          dataField: "name",
          text: "Name",

          formatter: this.GetName,
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
          sort: true,
          headerAlign: "center",
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              window.location.href = `/user/${row.id}`;
            },
          },
        },
        {
          dataField: "email",
          text: "Email",
          formatter: this.GetNullText,
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
          sort: true,
          headerAlign: "center",
        },
        {
          dataField: "mobile",
          text: "Mobile",
          formatter: this.GetNullText,
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "status",
          text: "Status",
          formatter: this.GetNullText,
          // formatter: cell => selectOptions[cell],
          filter: selectFilter({
            options: selectOptions,
            placeholder: "Select",
            defaultValue:
              location.defaultSelect === undefined
                ? ""
                : location.defaultSelect,
          }),
          sort: true,
          align: "center",
          headerAlign: "center",
        },
      ],
    };
  }

  editUserDetails = (cell, row) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          type="button"
          size="sm"
          className="eoi-btn"
          onClick={() => {
            window.location.href = `/user/${row.id}`;
          }}
        >
          Edit{" "}
          <i
            className="fas fa-pencil-alt"
            style={{ fontSize: "12px", marginLeft: "4px" }}
          ></i>
        </Button>
      </div>
    );
  };

  handleUserTypeChange = async (event, id, name) => {
    const isChecked = event.target.checked;
    const confirmDialogMessage = isChecked
      ? `giving ${name} admin rights`
      : `removing admin rights of ${name}`;
    if (!window.confirm(confirmDialogMessage)) {
      return;
    }
    try {
      const res = await axios.put(`/api/admin/usertype/${id}`);

      if (res && res.data.success) {
        await axios.get("/api/me/getAllUser").then((res) => {
          this.setState({
            userList: res.data.userList,
            pageLoading: false,
          });
        });
      } else {
        throw new Error("Operation failed");
      }
    } catch (error) {
      alert(error);
    }
  };

  changeUserType = (cell, row) => {
    const { orgType, id } = row.organization;
    const isAdmin = orgType === "Admin";
    return (
      <Switch
        checked={isAdmin}
        onChange={(event) => this.handleUserTypeChange(event, id, row.name)}
        color="secondary"
        name="checkedA"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    );
  };

  componentDidMount() {
    let id = 0;
    if (this.props.match.params.id !== undefined) {
      id = this.props.match.params.id;
    }
    if (id === 0) {
      // show all the user list
      this.setState({
        columns: [
          ...this.state.columns,
          {
            dataField: "organization.name",
            text: "Organization",
            formatter: this.GetOrganizationName,
            filter: textFilter({
              placeholder: "Search",
              style: {
                boxSizing: "border-box",
              },
            }),
            sort: true,
            align: "center",
            headerAlign: "center",
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                const { website } = row.organization;

                if (website) {
                  const url = website.startsWith("http") ? website : `https://${website}`;
                  window.open(url, "_blank");
                } else {
                  alert("web url not exist");
                }
              },
            },
          },
          {
            dataField: "",
            text: "Edit",
            formatter: this.editUserDetails,
            align: "center",
            headerAlign: "center",
          },
          {
            dataField: "",
            text: "Admin",
            formatter: this.changeUserType,
            align: "center",
            headerAlign: "center",
          },
        ],
      });
      axios.get("/api/me/getAllUser").then((res) => {
        // alert(JSON.stringify(res.data.userList))
        this.setState({
          userList: res.data.userList,
          pageLoading: false,
        });
      });
    } else
      axios
        .post("/api/me/getUserByOrganisation", { organisationId: id })
        .then((res) => {
          console.log(res.data);
          this.setState({
            userList: res.data.userList,
            pageLoading: false,
          });
        });
  }

  GetActionFormat(cell, row) {
    return (
      <div>
        <Button type="button" size="sm">
          Edit
        </Button>
      </div>
    );
  }

  GetName(cell, row) {
    return <span className="linkFormat">{row.name}</span>;
  }
  GetOrganizationName(cell, row) {
    return <span className="linkFormat">{row.organization.name}</span>;
  }
  GetNullText(cell, row) {
    //console.log(this.dataField,row);
    if (this.dataField.includes(".")) {
      let field = this.dataField.split(".");
      return row[field[0]][field[1]];
    }
    if (row[this.dataField]) {
      return row[this.dataField];
    } else {
      return "NA";
    }
  }

  toAdddUser() {
    this.props.history.push("/admin/addUser/");
  }

  render() {
    const options = {
      page: 1,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "20",
          value: 20,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "All",
          value: this.state.userList.length,
        },
      ],
      sizePerPage: 10,
      pageStartIndex: 1,
      paginationSize: 10,
      prePage: "Prev",
      nextPage: "Next",
      firstPage: "First",
      lastPage: "Last",
      paginationPosition: "bottom",
    };

    const rowEvents = {};

    if (this.state.pageLoading) {
      return (
        <>
          <Loader loading={this.state.pageLoading} />
        </>
      );
    } else {
      return (
        <>
          <div style={{ display: "flex" }}>
            <Button
              className="add-btn eoi-btn eoi-add-btn"
              size="sm"
              onClick={this.toAdddUser}
            >
              ADD
            </Button>
          </div>
          {this.state.userList.length > 0 ? (
            <>
              <BootstrapTable
                id="userTable"
                striped
                hover
                keyField="id"
                data={this.state.userList}
                columns={this.state.columns}
                pagination={paginationFactory(options)}
                rowEvents={rowEvents}
                filter={filterFactory()}
              />
            </>
          ) : (
            <>
              <p>
                <Alert variant="success">
                  <Alert.Heading>User List</Alert.Heading>
                  <p>No Users found.</p>
                </Alert>
              </p>
            </>
          )}
        </>
      );
    }
  }
}
export default withRouter(UserList);

const selectOptions = {
  Active: "Active",
  Inactive: "Inactive",
};
