/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { UserContext } from "../../../../../app/utils/UserContext";
import configFile from "../../../../../../src/config"

export function AsideMenuList({ layoutProps }) {
  const user = useContext(UserContext).user.get()
  const location = useLocation();
  const activeClass = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : "";
  };

  const isAdmin = user.type === 'Admin' || null;

  const adminMenu = (
    <>
      <li className="menu-section ">
        <h4 className="menu-text">Admin</h4>
        <i className="menu-icon flaticon-more-v2"></i>
      </li>

      <li className={`menu-item ${activeClass("/admin/listOrganizations")}`}>
        <NavLink className="menu-link" to="/admin/listOrganizations">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Organizations.svg")} />
          </span>
          <span className="menu-text">Organizations</span>
          <span className="menu-text menu-text-icon"><i className="fas fa-arrow-right dash-ic"></i></span>
        </NavLink>
      </li>

      <li className={`menu-item ${activeClass("/admin/userList")}`}>
        <NavLink className="menu-link" to="/admin/userList">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/orgfinal.svg")} />
          </span>
          <span className="menu-text">Users</span>
          <span className="menu-text menu-text-icon"><i className="fas fa-arrow-right dash-ic"></i></span>
        </NavLink>
      </li>

      <li className={`menu-item ${activeClass("/admin/listRFP")}`}>
        <NavLink className="menu-link" to="/admin/listRFP">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/rfp11.svg")} />
          </span>
          <span className="menu-text">Proposals</span>
          <span className="menu-text menu-text-icon"><i className="fas fa-arrow-right dash-ic"></i></span>
        </NavLink>
      </li>

      <li className={`menu-item ${activeClass("/admin/listEOI")}`}>
        <NavLink className="menu-link" to="/admin/listEOI">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/neweoi.svg")} />
          </span>
          <span className="menu-text">EOIs</span>
          <span className="menu-text menu-text-icon"><i className="fas fa-arrow-right dash-ic"></i></span>
        </NavLink>
      </li>

      <li className={`menu-item ${activeClass("/admin/listAuction")}`}>
        <NavLink className="menu-link" to="/admin/listAuction">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/newauction.svg")} />
          </span>
          <span className="menu-text">Auctions</span>
          <span className="menu-text menu-text-icon"><i className="fas fa-arrow-right dash-ic"></i></span>
        </NavLink>
      </li>

      <li className={`menu-item ${activeClass("/admin/userQueries")}`}>
        <NavLink className="menu-link" to="/admin/userQueries">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Queries.svg")} />
          </span>
          <span className="menu-text">Queries</span>
          <span className="menu-text menu-text-icon"><i className="fas fa-arrow-right dash-ic"></i></span>
        </NavLink>
      </li>

      <li className={`menu-item ${activeClass("/admin/trackUser")}`}>
        <NavLink className="menu-link" to="/admin/trackUser">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Visitors.svg")} />
          </span>
          <span className="menu-text">Visitors</span>
          <span className="menu-text menu-text-icon"><i className="fas fa-arrow-right dash-ic"></i></span>
        </NavLink>
      </li>

      <li className={`menu-item ${activeClass("/admin/change-logs")}`}>
        <NavLink className="menu-link" to="/admin/change-logs">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Visitors.svg")} />
          </span>
          <span className="menu-text">Change Logs</span>
          <span className="menu-text menu-text-icon"><i className="fas fa-arrow-right dash-ic"></i></span>
        </NavLink>
      </li>
    </>
  );

  const nonAdminMenu = (
    <>
      <li className={`menu-item ${activeClass("/updateOrganisation")}`}>
        <NavLink className="menu-link" to="/updateOrganisation">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/orgfinal.svg")} />
          </span>
          <span className="menu-text">Update Organization</span>
          <span className="menu-text menu-text-icon"><i className="fas fa-arrow-right dash-ic"></i></span>
        </NavLink>
      </li>

      <li className={`menu-item ${activeClass("/listRFP")}`} >
        <NavLink className="menu-link" to="/listRFP">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/rfp11.svg")} />
          </span>
          <span className="menu-text">RFP</span>
          <span className="menu-text menu-text-icon"><i className="fas fa-arrow-right dash-ic"></i></span>
        </NavLink>
      </li>

      <li className={`menu-item ${activeClass("/allRFP")}`} >
        <NavLink className="menu-link" to="/allRFP">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/allrfpnew.svg")} />
          </span>
          <span className="menu-text">Show All RFP</span>
          <span className="menu-text menu-text-icon"><i className="fas fa-arrow-right dash-ic"></i></span>
        </NavLink>
      </li>

      <li className={`menu-item ${activeClass("/listEOI")}`} >
        <NavLink className="menu-link" to="/listEOI">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/neweoi.svg")} style={{ height: "28px", width: "28px" }} />
          </span>
          <span className="menu-text">EOI</span>
          <span className="menu-text menu-text-icon"><i className="fas fa-arrow-right dash-ic"></i></span>
        </NavLink>
      </li>

      <li className={`menu-item ${activeClass("/listAuction")}`}>
        <NavLink className="menu-link" to="/listAuction">
          <span className="svg-icon menu-icon">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/newauction.svg")} style={{ height: "28px", width: "28px" }} />
          </span>
          <span className="menu-text">Auctions</span>
          <span className="menu-text menu-text-icon"><i className="fas fa-arrow-right dash-ic"></i></span>
        </NavLink>
      </li>
    </>
  );

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className={`menu-item`} onClick={() => {
          window.location.href = configFile.STATIC_WEB_URL
        }}>
          <span className="menu-link">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/home.svg")} />
            </span>
            <span className="menu-text">Home</span>
            <span className="menu-text menu-text-icon"><i className="fas fa-arrow-right dash-ic"></i></span>
          </span>
        </li>

        <li className={`menu-item ${activeClass("/dashboard")}`}>
          <NavLink className="menu-link" to="/">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/ic-dashboard.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
            <span className="menu-text menu-text-icon"><i className="fas fa-arrow-right dash-ic"></i></span>
          </NavLink>
        </li>

        {isAdmin ? adminMenu : nonAdminMenu}

      </ul>
      <p className="copyright">&copy; 2020 Bonolo</p>
      {/* end::Menu Nav */}
    </>
  );
}
