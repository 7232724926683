import React, { useEffect, useState } from 'react';
import axios from 'axios';
export const ME_URL = "/api/me";
const GlobalState = React.createContext([]);

export function GlobalStateProvider({ children }) {
    const [state, setState] = useState({});
    useEffect(() => {
        const callApi = async () => {
            let userGetter = await axios.get(ME_URL);
            setState(userGetter?.data);
        }
        callApi();
    }, []);

    return (
        <GlobalState.Provider value={[state, setState]}>
            {children}
        </GlobalState.Provider>
    );
}

export default GlobalState;