import React, { Suspense, useContext } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import UpdateOrganisation from "./modules/Organisation/UpdateOrganisation";
import ListOrganization from "./modules/Organisation/ListOrganization";
import AddRFP from "./modules/RFP/AddRFP";
import ListRFP from "./modules/RFP/ListRFP";
import ListEOI from "./modules/EOI/ListEOI";
import AddEOI from "./modules/EOI/AddEOI";
import ShowAllRFP from "./modules/RFP/ShowAllRFP";
import UpdateUserProfile from "./pages/UpdateUserProfile";
import AddAuction from "./modules/Auction/AddAuction";
import LiveAuction from "./modules/Auction/LiveAuction";
import ListAuction from "./modules/Auction/ListAuction";
import viewRFP from "./modules/RFP/viewRFP";
import UserList from "./pages/UserList";
import UserContactUs from "./pages/UserContactUs";
import UserTrack from "./pages/UserTrack";
import AddUser from "./modules/User/AddUser";
import ChangeLog from "./pages/ChangeLog";
import AdminDashboard from "./pages/AdminDashboard";
//import Toaster from "./components/toast/Toaster";

import { UserContext } from "./utils/UserContext";

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect
  const { user } = useContext(UserContext);
  const userObj = user.get() || "";

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route
          path="/dashboard"
          component={userObj.type === "Admin" ? AdminDashboard : DashboardPage}
        />
        <Route path="/updateOrganisation/:id?" component={UpdateOrganisation} />
        <Route path="/addRFP" component={AddRFP} />
        <Route path="/editRFP/:id" component={AddRFP} />
        <Route path="/listRFP" component={ListRFP} />
        <Route path="/addEOI/:id/:rfpId" component={AddEOI} />
        <Route path="/editEOI/:id" component={AddEOI} />
        <Route path="/listEOI" component={ListEOI} />
        <Route path="/user/profile" component={UpdateUserProfile} />
        <Route path="/addAuction/:id/:rfpId" component={AddAuction} />
        <Route path="/editAuction/:id" component={AddAuction} />
        <Route path="/liveAuction/:id" component={LiveAuction} />
        <Route path="/listAuction" component={ListAuction} />
        <Route path="/allRFP" component={ShowAllRFP} />
        <Route path="/admin/listOrganizations" component={ListOrganization} />
        <Route path="/admin/listRFP" component={ListRFP} />
        <Route path="/admin/listEOI" component={ListEOI} />
        <Route path="/admin/listAuction" component={ListAuction} />
        <Route path="/viewRFP/:id" component={viewRFP} />
        <Route path="/userList/:id" component={UserList} />
        <Route path="/admin/userList" component={UserList} />
        <Route path="/user/:id" component={UpdateUserProfile} />
        <Route path="/admin/createRFP/:organizationId" component={AddRFP} />
        <Route path="/admin/addEOI/:orgId/:rfpId" component={AddEOI} />
        <Route path="/admin/addUser" component={AddUser} />
        <Route path="/admin/userQueries" component={UserContactUs} />
        <Route path="/admin/trackUser" component={UserTrack} />
        <Route path="/admin/change-logs" component={ChangeLog} />
        <Route path="/admin/adminDashboard" component={AdminDashboard} />
        <Redirect to="/dashboard" />
      </Switch>
      {/* <Toaster /> */}
    </Suspense>
  );
}
