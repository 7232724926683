import axios from "axios";

// export const LOGIN_URL = "a/login";
export const LOGIN_URL = `/api-auth/user/login`;
export const LOGIN_OTP_URL = `/api-auth/user/login/otp`;
export const LOGIN_FIREBASE_URL = `/api-auth/user/login/firebase`;
export const REGISTER_URL = `/api-auth/user/register`;
export const REGISTER_OTP_URL = `/api-auth/user/register/otp`;
export const REGISTER_FIREBASE_URL = `/api-auth/user/register/firebase`;
export const SEND_OTP_URL = `/api-auth/user/update/otp`;
export const VERIFY_OTP_URL = `/api-auth/user/update/verify`;
export const UPDATE_USER_URL = `/api-auth/user/update/user`;
export const REQUEST_PASSWORD_URL = "";
export const AUTH_USER_URL = `/api-auth/user/login/gmail`;
export const AUTH_USER_EXIST = `/api-auth/user/login/exist`;
export const ADMIN_REGISTER_USER_URL = `/api/admin/registerUser`;
export const USER_CONTACT_QUERY_URL = `/api/admin/contactUs`;
export const USER_TRACK_URL = `/api/admin/trackUser`;
///login/gmail checkUserExistence
export const ME_URL = "/auth/api/me";

export async function login(mobile) {
  const response = await axios.post(LOGIN_URL, { mobile });
  const data = response.data;
  return data;
}

export async function sendOtp(mobile) {
  const response = await axios.post(SEND_OTP_URL, { mobile });
  const data = response.data;
  return data;
}

export async function verifyOtp(mobile, otp) {
  const response = await axios.post(VERIFY_OTP_URL, { mobile, otp });
  const data = response.data;
  return data;
}

export async function updateUser(name, email, mobile, image) {
  const response = await axios.post(UPDATE_USER_URL, {
    name,
    email,
    mobile,
    image,
  });
  const data = response.data;
  return data;
}

export async function loginOtp(mobile, otp) {
  const response = await axios.post(LOGIN_OTP_URL, { mobile, otp });
  const data = response.data;
  return data;
}

export async function loginFirebase(mobile) {
  const response = await axios.post(LOGIN_FIREBASE_URL, { mobile });
  const data = response.data;
  return data;
}

export async function register(name, email, mobile, companyName) {
  const response = await axios.post(REGISTER_URL, {
    name,
    email,
    mobile,
    companyName,
  });
  const data = response.data;
  return data;
}

export async function registerOtp(name, email, mobile, companyName, otp) {
  const response = await axios.post(REGISTER_OTP_URL, {
    name,
    email,
    mobile,
    companyName,
    otp,
  });
  const data = response.data;
  return data;
}

export async function registerFirebase(name, email, mobile, companyName) {
  const response = await axios.post(REGISTER_FIREBASE_URL, {
    name,
    email,
    mobile,
    companyName
  });
  const data = response.data;
  return data;
}

export async function gmailLogin(obj) {
  const response = await axios.post(AUTH_USER_URL, obj);
  console.log(response.data);
  // const data = response.data.token;
  return response.data;
}

export async function checkUserExistence(email) {
  const response = await axios.post(AUTH_USER_EXIST, { email });
  console.log(response.data);
  // const data = response.data.token;
  return response.data;
}
export async function checkUserExistenceByPhone(mobile) {
  const response = await axios.post(AUTH_USER_EXIST, { mobile });
  console.log(response.data);
  // const data = response.data.token;
  return response.data.status;
}

//AUTH_USER_URL

export async function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export async function adminRegisterUser(name, email, mobile, companyName) {
  const response = await axios.post(ADMIN_REGISTER_USER_URL, {
    name,
    email,
    mobile,
    companyName,
  });
  const data = response.status;
  return data;
}

export async function userQueries() {
  const response = await axios.get(USER_CONTACT_QUERY_URL);
  console.log(response.data);
  return response.data;
}

export async function trackUsers() {
  const response = await axios.get(USER_TRACK_URL);
  console.log(response.data);
  return response.data;
}
