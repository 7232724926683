import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import BonoloField from "../../BonoloField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "moment-timezone";
import _ from "lodash";
import { Loader } from "../../components/toast/Loader";
function AddAuction(props) {
  const [startTime, setStartDate] = useState();
  const [endTime, setendTime] = useState();
  const [timeDiff, setDiffTime] = useState();
  const [pageLoading, setPageLoading] = useState(false);

  // Auction Status option
  const ENABLE = "Enable";
  /*const LIVE = "Live"
    const COMPLETE = "Complete"
    const DISABLE = "Disable" */

  const initialValues = {
    rfpId: "",
    startTime: "",
    endTime: "",
    closeTime: timeDiff,
    winnerDisclosureFee: "",
    l2DisclosureFee: "",
    l3DisclosureFee: "",
    status: ENABLE,
  };
  const AuctionSchema = Yup.object().shape({
    startTime: Yup.string(),
    closeTime: Yup.string().required("Start and end time cannot be equal."),
    endTime: Yup.string(),
    winnerDisclosureFee: Yup.number()
      .required("(*)This field is required.")
      .typeError("Must be only digits."),
    l2DisclosureFee: Yup.number()
      .required("This field is required.")
      .typeError("Must be only digits."),
    l3DisclosureFee: Yup.number()
      .required("This field is required.")
      .typeError("Must be only digits."),
  });
  let id = 0;
  let rfpId = 0;
  if (props.match.params.id !== undefined) {
    id = props.match.params.id;
  }
  if (props.match.params.rfpId !== undefined) {
    rfpId = props.match.params.rfpId;
  }

  //const [loading, setLoading] = useState(true);
  const [auctionData, setAuctionData] = useState({});
  useEffect(() => {
    if (id !== 0) {
      const fetchData = async () => {
        let res = undefined;
        if (rfpId !== 0) {
          res = await axios.get("/api/auction/getAuction?rfpId=" + rfpId);
        } else {
          res = await axios.get("/api/auction/getAuction?id=" + id);
        }
        if (res.data.response) {
          setStartDate(new Date(res.data.response.startTime));
          setendTime(new Date(res.data.response.endTime));
          setDiffTime(res.data.response.closeTime);
        }
        setPageLoading(false);
        console.log(typeof res.data.response);
        setAuctionData(res.data.response);
        //setLoading(false);
      };
      //setLoading(true);
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitHandler = (values, { setStatus, setSubmitting }) => {
    console.log(values);
    setPageLoading(true);
    if (rfpId !== 0) {
      values.rfpId = rfpId;
    }
    values.startTime = moment(startTime).format();
    values.endTime = moment(endTime).format();
    values.closeTime = timeDiff;
    //console.log(values);
    axios.post("/api/auction/saveAuction", values).then((res) => {
      setSubmitting(false);
      if (res.status === 200) {
        setStatus("success");
        props.history.push("/ListAuction");
      } else {
        setStatus("failure");
      }
      setPageLoading(true);
    });
  };

  const getDiff = () => {
    let diff = timeDiffCalc(
      moment(endTime, "DD/MM/YYYY HH:mm:ss"),
      moment(startTime, "DD/MM/YYYY HH:mm:ss")
    );
    if (!diff.includes("NaN") && !diff.includes("0 hour, 0 minutes")) {
      console.log(diff);
      setDiffTime(diff);
      //console.log(auctionData);
      if (auctionData) {
        auctionData.closeTime = diff;
        setAuctionData(auctionData);
      }
    }
  };

  function timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    console.log("calculated days", days);

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    console.log("calculated hours", hours);

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;
    console.log("minutes", minutes);

    let difference = "";
    if (days > 0) {
      difference += days === 1 ? `${days} day, ` : `${days} days, `;
    }

    difference +=
      hours === 0 || hours === 1 ? `${hours} hour, ` : `${hours} hours, `;

    difference +=
      minutes === 0 || hours === 1
        ? `${minutes} minutes`
        : `${minutes} minutes`;

    return difference;
  }

  const getSelectedDate = (date) => {
    console.log(startTime);
    console.log(date);
    if (date === startTime) {
      setStartDate(date);
    } else {
      setStartDate(date);
      setendTime("");
      setDiffTime("");
    }
    console.log(date);
    //
  };
  //console.log(timeDiffCalc(new Date('2019/10/1 04:10:00'), new Date('2019/10/2 18:20:00')));

  if (pageLoading) {
    return <Loader />;
  } else {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={_.merge({}, initialValues, auctionData)}
        validationSchema={AuctionSchema}
        onSubmit={submitHandler}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col xs="6" md="4">
                <Form.Group>
                  <label className="form-label">Start Time</label>
                  <DatePicker
                    wrapperClassName="cal-wrapper"
                    className="form-control"
                    required={true}
                    dateFormat="d MMMM, yyyy h:mm aa"
                    selected={startTime}
                    onChange={(date) => {
                      getSelectedDate(date);
                      //setStartDate(date);
                    }}
                    showTimeSelect
                    selectsStart
                    onCalendarClose={() => {
                      // setendTime("");
                      getDiff();
                    }}
                    startDate={startTime}
                    endTime={endTime}
                    name="startTime"
                  />
                </Form.Group>
              </Col>
              <Col xs="6" md="4">
                <Form.Group>
                  <label className="form-label">End Time</label>
                  <DatePicker
                    wrapperClassName="cal-wrapper"
                    className="form-control"
                    required={true}
                    dateFormat="d MMMM, yyyy h:mm aa"
                    selected={endTime}
                    onChange={(date) => setendTime(date)}
                    onCalendarClose={() => {
                      // setendTime("");
                      getDiff();
                    }}
                    showTimeSelect
                    selectsEnd
                    startDate={startTime}
                    endTime={endTime}
                    minDate={startTime}
                    name="endTime"
                  />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <BonoloField
                    label="Close Time"
                    styleClass="black-field"
                    name="closeTime"
                    formik={formik}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Form.Group>
                  <BonoloField
                    label="Winner Disclosure Fee"
                    name="winnerDisclosureFee"
                    formik={formik}
                  />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <BonoloField
                    label="Level 2 Disclosure Fee"
                    name="l2DisclosureFee"
                    formik={formik}
                  />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <BonoloField
                    label="Level 3 Disclosure Fee"
                    name="l3DisclosureFee"
                    formik={formik}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm="12" md="4">
                <Form.Group>
                  <BonoloField
                    type="select"
                    label="Status"
                    name="status"
                    formik={formik}
                    values={[
                      ["Active", "Active"],
                      ["Inactive", "Inactive"],
                    ]}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* <Form.Group controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Check me out" />
            </Form.Group>  */}
            <Row>
              <Col>
                <hr className="hr" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant="primary"
                  name="submit"
                  className="submit-btn"
                  type="submit"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

export default AddAuction;
