var configFile = {};
if (process.env.REACT_APP_ENV === "sta") {
  configFile = {
    ENDPOINT: "https://bonolo-test-app.arachnomesh.com/socket",
    STATIC_WEB_URL: "http://bonolo-test-app.arachnomesh.com/home",
    REACT_APP_URL: "https://bonolo-test-app.arachnomesh.com/",
    BLOG_URL: "https://blog.bonolo.in/",
    CLIENT_Id:
      "700187318885-v0p1rgedmebig8kre14f6t73a3caokn1.apps.googleusercontent.com",
  };
} else if (process.env.REACT_APP_ENV === "demo") {
  configFile = {
    ENDPOINT: "https://demoapp.bonolo.in/socket",
    STATIC_WEB_URL: "https://demo.bonolo.in/",
    REACT_APP_URL: "https://demoapp.bonolo.in",
    BLOG_URL: "https://blog.bonolo.in/",
    CLIENT_Id:
      "700187318885-v0p1rgedmebig8kre14f6t73a3caokn1.apps.googleusercontent.com",
  };
} else if (process.env.REACT_APP_ENV === "prod") {
  configFile = {
    ENDPOINT: "https://app.bonolo.in/socket",
    STATIC_WEB_URL: "https://bonolo.in/",
    REACT_APP_URL: "https://app.bonolo.in/",
    BLOG_URL: "https://blog.bonolo.in/",
    CLIENT_Id:
      "700187318885-v0p1rgedmebig8kre14f6t73a3caokn1.apps.googleusercontent.com",
  };
} else {
  configFile = {
    ENDPOINT: "http://127.0.0.1:4005/socket",
    STATIC_WEB_URL: "http://localhost:8080/",
    REACT_APP_URL: "http://localhost:3000/",
    BLOG_URL: "https://blog.bonolo.in/",
    CLIENT_Id:
      "700187318885-v0p1rgedmebig8kre14f6t73a3caokn1.apps.googleusercontent.com",
  };
}

export default configFile;
