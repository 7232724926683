// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { createHashHistory } from "history";
import { Loader } from "react-bootstrap-typeahead";
const hashHistory = createHashHistory();

function AddRFP(props) {
  let id = props.match.params.id;
  const [rfpData, setRFPData] = useState({});
  const [pageLoading, setPageLoading] = useState(true);
  const [attachmentDownloading, setAttachmentDownloading] = useState(false);
  const [imgData, setImgData] = useState([]);
  useEffect(() => {
    if (id !== 0) {
      const imgData = async () => {
        const res = await axios.get("/api/files/getFiles/type/"+ id);
        console.log("imgData", res)
        setImgData(res?.data);
      }      
      imgData();
      

      const fetchData = async () => {
        const res = await axios.get("/api/rfp/showRFP?id=" + id);
        setRFPData(res.data.response);
        setPageLoading(false);
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const display_images = rfpData.image_url

  if (pageLoading) {
    return <Loader loading={pageLoading} />;
  }

  const downloadAttachment = async () => {
    setAttachmentDownloading(true);
    console.log(rfpData.attachment_name);
    try {
      let response = await axios({
        url: "/api/rfp/downloadAttachment/",
        method: "GET",
        params: { q: rfpData.attachment_name },
        responseType: "blob",
      });
      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${rfpData.attachment_name}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else alert("Could not");
    } catch (err) {
      console.log(err);
      alert("Could not read attachment.");
    }
    setAttachmentDownloading(false);
    // axios.get(`/api/rfp/downloadAttachment/`, {
    //   params: { q: rfpData.attachment_name },
    // });
  };

  const handleClick = async(filename) => {
    try {
      let response = await axios({
        url: "/api/files/open-file/",
        method: "GET",
        params: { q: filename },
        responseType: 'blob' 
      });
      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); 
    link.target = '_blank'; 
    document.body.appendChild(link);
    link.click();
    link.remove();
      } else alert("Could not");
    } catch (err) {
      console.log(err);
      alert("Could not read attachment.");
    }
  };

  return (
    <>
      <Formik enableReinitialize={true}>
        {(formik) => (
          <Form
            onSubmit={formik.handleSubmit}
            className="mt-5 p-md-5 viewRFP"
            style={{ borderColor: "#aaa" }}
          >
            <Row>
              <Col className="safrp" style={{ marginBottom: "5px" }}>
                <h1>{rfpData.title}</h1>
              </Col>
            </Row>

            {/* <Row className="product-row roww">
              <Col md="4" className="bor coll">Product Image</Col>
            </Row> */}
            <Row className="roww">
              <Col className="bor coll" xs="4">
                RFP Images
              </Col>
              <Col className="py-3" style={{display:"flex", flexDirection:"row", gap:"10px", alignItems:"center"}}>
                {imgData &&
                  imgData.map((value) => (
                    <p key={value.orignal_name} onClick={() => handleClick(value.orignal_name)}
                    style={{display:"flex", cursor:"pointer", justifyContent:"center", padding:"2px", color:"blue", width:"fit-content"}}>{value.orignal_name} (<span style={{color:"green"}}>{value.document_type}</span>)</p>
                  ))}
              </Col>
            </Row>

            <Row className="roww">
              <Col className="bor coll" xs="4">
                RFP Title
              </Col>
              <Col xs="8" className="coll">
                {rfpData.title}
              </Col>
            </Row>

            <Row className="roww">
              <Col className="bor coll" xs="4">
                Product /Service Name
              </Col>
              <Col xs="8" className="coll">
                {rfpData.product_name}
              </Col>
            </Row>

            <Row className="roww">
              <Col className="bor coll" xs="4">
                Details
              </Col>
              <Col xs="8" className="coll">
                {rfpData.detail}
              </Col>
            </Row>

            <Row className="roww">
              <Col className="bor coll" xs="4">
                Delivery Type
              </Col>
              <Col xs="8" className="coll">
                {rfpData.delivery_type}
              </Col>
            </Row>

            <Row className="roww">
              <Col className="bor coll" xs="4">
                Delivery Location
              </Col>
              <Col xs="8" className="coll">
                {rfpData.delivery_location}
              </Col>
            </Row>

            <Row className="roww">
              <Col className="bor coll" xs="4">
                Delivery Schedule
              </Col>
              <Col xs="8" className="coll">
                {rfpData.delivery_schedule}
              </Col>
            </Row>

            <Row className="roww">
              <Col className="bor coll" xs="4">
                Delivery Detail
              </Col>
              <Col xs="8" className="coll">
                {rfpData.delivery_detail}
              </Col>
            </Row>

            <Row className="roww">
              <Col className="bor coll" xs="4">
                Delivery Cost Included
              </Col>
              <Col xs="8" className="coll">
                {rfpData.delivery_cost}
              </Col>
            </Row>

            <Row className="roww">
              <Col className="bor coll" xs="4">
                Installation Cost Included
              </Col>
              <Col xs="8" className="coll">
                {rfpData.installation_cost}
              </Col>
            </Row>

            <Row className="roww">
              <Col className="bor coll" xs="4">
                AMC Included
              </Col>
              <Col xs="8" className="coll">
                {rfpData.amc_cost}
              </Col>
            </Row>

            <Row className="roww">
              <Col className="bor coll" xs="4">
                Custom Cost
              </Col>
              <Col xs="8" className="coll">
                {rfpData.custom_cost}
              </Col>
            </Row>

            <Row className="roww">
              <Col className="bor coll" xs="4">
                Min. Vendor Turnover (in Lakh rupees)
              </Col>
              <Col xs="8" className="coll">
                {rfpData.min_vendor_turnover}
              </Col>
            </Row>

            <Row className="roww">
              <Col className="bor coll" xs="4">
                Min. Vendor Experience (in number of years)
              </Col>
              <Col xs="8" className="coll">
                {rfpData.vendor_experience}
              </Col>
            </Row>

            <Row className="roww">
              <Col className="bor coll" xs="4">
                Min. Vendor Networth (in Lakh rupees)
              </Col>
              <Col xs="8" className="coll">
                {rfpData.min_vendor_networth}
              </Col>
            </Row>

            <Row className="roww">
              <Col className="bor coll" xs="4">
                Status
              </Col>
              <Col xs="8" className="coll">
                {rfpData.status}
              </Col>
            </Row>

            <Row className="roww">
              <Col className="bor coll" xs="4">
                Manufacturing Year (Year)
              </Col>
              <Col xs="8" className="coll">
                {rfpData.manufacturing_year}
              </Col>
            </Row>

            <Row className="roww">
              <Col className="bor coll" xs="4">
                Custom Vendor Constraints
              </Col>
              <Col xs="8" className="coll">
                {rfpData.custom_vendor_constraints}
              </Col>
            </Row>

            <Row className="roww">
              <Col className="bor coll" xs="4">
                Technical Bid
              </Col>
              <Col xs="8" className="coll">
                {rfpData.technical_bid_required}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              BID Open Date
              </Col>
              <Col xs="8" className="coll">
                {rfpData.bid_open_date}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              Bid Code
              </Col>
              <Col xs="8" className="coll">
                {rfpData.bid_code}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              Bid Decrement Value
              </Col>
              <Col xs="8" className="coll">
                {rfpData.bid_decrement_value}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              Project Code
              </Col>
              <Col xs="8" className="coll">
                {rfpData.project_code}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              Part Code              </Col>
              <Col xs="8" className="coll">
                {rfpData.part_code}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              Part Name
              </Col>
              <Col xs="8" className="coll">
                {rfpData.part_name}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              MOC
              </Col>
              <Col xs="8" className="coll">
                {rfpData.moc}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              Organization Id
              </Col>
              <Col xs="8" className="coll">
                {rfpData.organizationId}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              Dimension
              </Col>
              <Col xs="8" className="coll">
                {rfpData.dimension}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              Finish              </Col>
              <Col xs="8" className="coll">
                {rfpData.finish}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              Qty
              </Col>
              <Col xs="8" className="coll">
                {rfpData.qty}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              Processes
              </Col>
              <Col xs="8" className="coll">
                {rfpData.processes}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              Scope
              </Col>
              <Col xs="8" className="coll">
                {rfpData.scope}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              Delivery At
              </Col>
              <Col xs="8" className="coll">
                {rfpData.delivery_at}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              Lead Time
              </Col>
              <Col xs="8" className="coll">
                {rfpData.lead_time}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              Hurdle Rate (in Rupees)
              </Col>
              <Col xs="8" className="coll">
                {rfpData.hurdle_rate}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              Payment Terms
              </Col>
              <Col xs="8" className="coll">
                {rfpData.payment_terms}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              Price Estimation (in Rupees)
              </Col>
              <Col xs="8" className="coll">
                {rfpData.price_estimation}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              LD Clause
              </Col>
              <Col xs="8" className="coll">
                {rfpData.ld_clause}
              </Col>
            </Row>
            <Row className="roww">
              <Col className="bor coll" xs="4">
              Bonus Clause
              </Col>
              <Col xs="8" className="coll">
                {rfpData.bonus_clause}
              </Col>
            </Row>
            

            {rfpData.attachment_name && (
              <Row className="roww">
                <Col className="bor coll" xs="4">
                  <span className="data">Attachment</span>
                </Col>
                <Col xs="8" className="coll">
                  <span className="data">
                    <Button
                      style={{
                        background: "none",
                        border: "none",
                        color: "blue",
                        padding: "0",
                      }}
                      onClick={downloadAttachment}
                    >
                      {rfpData.attachment_name}
                      <i
                        class="fas fa-long-arrow-alt-down"
                        style={{
                          color: "#0073e9",
                          fontSize: "12px",
                          marginLeft: "4px",
                        }}
                      ></i>
                    </Button>
                    {attachmentDownloading && (
                      <i
                        class="fas fa-spinner rotate-animate"
                        style={{ marginLeft: "4px" }}
                      ></i>
                    )}
                  </span>
                </Col>
              </Row>
            )}

            <Row
              className="roww"
              style={{ borderBottom: "2px solid rgba(57,58,74,0.20)" }}
            >
              <Col className="bor coll" xs="4">
                <span className="data">Terms Conditions</span>
              </Col>
              <Col xs="8" className="coll">
                <span className="data">{rfpData.terms_conditions}</span>
              </Col>
            </Row>

            <Row style={{ marginTop: "16px" }}>
              <Col>
                <Button
                  variant="primary"
                  className="submit-btn"
                  onClick={() => hashHistory.goBack()}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default AddRFP;
