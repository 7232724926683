// eslint-disable-next-line
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Row, Col, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import BonoloField from "../../BonoloField";
import _ from "lodash";
import { UserContext } from "../../utils/UserContext";
import configFile from "../../../config.js";

const STATIC_WEB_URL = configFile.STATIC_WEB_URL;

// Status Options
const APPROVED = "Approved";
const PENDING = "Pending";
const CANCEL = "Cancel";
// const SUBMIT_FOR_APPROVEL = "Submit For Approvel"

const initialValues = {
  organization_id: "",
  custom_vendor_constraints: "",
  techincal_details: "",
  rfpId: "",
  eoi_approval: PENDING,
  acceptTerms: false,
  deviation: "",
};

let EOISchema = Yup.object().shape({
  techincal_details: Yup.string()
    .min(5, "Must be 5-1000 characters")
    .max(1000, "Must be 5-1000 characters")
    .required("(*)Marked fields are required"),
  deviation: Yup.string()
    .min(5, "Must be 5-1000 characters")
    .max(1000, "Must be 5-1000 characters"),
  acceptTerms: Yup.bool().oneOf(
    [true],
    "You must accept the terms and conditions"
  ),
});

function AddEOI(props) {
  const history = useHistory();
  let id = 0;
  let orgId = 0;
  let rfpId = 0;
  if (props.match.params.id !== undefined) {
    id = props.match.params.id;
  }
  if (props.match.params.rfpId !== undefined) {
    rfpId = props.match.params.rfpId;
  }
  if (props.match.params.orgId !== undefined) {
    orgId = props.match.params.orgId;
  }

  const { user } = useContext(UserContext);
  const userObj = user.get() || "";

  //const [loading, setLoading] = useState(true);
  const [eoiData, setEOIData] = useState({});
  // eslint-disable-next-line
  const [approvelStatusOption, setApprovelStatusOption] = useState([
    // eslint-disable-line
    [PENDING, PENDING],
    [APPROVED, APPROVED],
    [CANCEL, CANCEL],
  ]);

  const [docName, setDocName] = useState("Add attachment");
  const [
    isTechnicalDetailsMandatoryText,
    setIsTechnicalDetailsMandatoryText,
  ] = useState("*");

  const [attachment, setAttachment] = useState({});

  useEffect(() => {
    if (document.URL.includes("editEOI")) {
      const fetchData = async () => {
        const res = await axios.get("/api/eoi/getEOI?id=" + id);
        if (res.data.response) {
          const { rfpId } = res.data.response;
          const rfpRes = await axios.get("/api/rfp/showRFP?id=" + rfpId);
          await setEOIData({
            ...res.data.response,
            ...{ rfpName: rfpRes.data.response.title },
          });
          if (res.data.response.attachment_name) {
            setDocName(res.data.response.attachment_name);
          }
        } else {
          history.push("/dashboard");
        }
      };
      fetchData();
    } else {
      fetchRfpData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRfpData = async () => {
    if (rfpId !== 0) {
      axios
        .get("/api/rfp/showRFP?id=" + rfpId)
        .then((res) => {
          setEOIData({ ...eoiData, ...{ rfpName: res.data.response.title } });
        })
        .catch((err) => {
          history.push("/dashboard");
        });
    }
  };
  const selectDocs = (e) => {
    setAttachment(e.target.files[0]);
    setDocName(e.target.files[0]["name"]);
    setIsTechnicalDetailsMandatoryText("");

    EOISchema = Yup.object().shape({
      deviation: Yup.string()
        .min(5, "Must be 5-1000 characters")
        .max(1000, "Must be 5-1000 characters"),
      acceptTerms: Yup.bool().oneOf(
        [true],
        "You must accept the terms and conditions"
      ),
    });
  };

  const submitHandler = (values, { setStatus, setSubmitting }) => {
    console.log(values);
    if (rfpId !== 0) {
      values.rfpId = rfpId;
    }

    if (orgId !== 0) {
      values.organizationId = orgId;
    }

    const formData = new FormData();

    formData.append("attachment", attachment);
    axios.post("/api/rfp/saveRFPFiles", formData).then((resp) => {
      //alert(JSON.stringify(values))
      values.attachment_name = resp?.["data"]["attachment"];
      axios.post("/api/eoi/saveEOI", values).then((res) => {
        setSubmitting(false);
        if (res.status === 200) {
          setStatus("success");
          props.history.push("/ListEOI");
        } else {
          setStatus("failure");
        }
      });
    });
  };

  return (
    <>
      <h1>RFP TITLE : {eoiData.rfpName}</h1>
      <Formik
        enableReinitialize={true}
        initialValues={_.merge({}, initialValues, eoiData)}
        validationSchema={EOISchema}
        onSubmit={submitHandler}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit} className="mt-5 p-md-5">
            <Row>
              <Col>
                <Form.Group>
                  <BonoloField
                    label="Custom Vendor Constraints"
                    name="custom_vendor_constraints"
                    formik={formik}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <BonoloField
                    placeholder="The technical details of the EOI are….."
                    label={`Technical Details${isTechnicalDetailsMandatoryText}`}
                    name="techincal_details"
                    formik={formik}
                  />
                  <div className="attachment-wrapper">
                    <span
                      onClick={() => {
                        if (docName === "Add attachment") {
                          setAttachment({});
                          setDocName("Add attachment");
                          document.getElementById("attachment").click();
                        } else {
                          setAttachment({});
                          setDocName("Add attachment");
                          document.getElementById("attachment").value = null;
                        }
                      }}
                      className="btn btn-light btn-sm font-weight-bold add-attachment"
                    >
                      {docName}
                      {docName === "Add attachment" ? (
                        <i class="fa fa-paperclip" aria-hidden="true"></i>
                      ) : (
                        <i class="fa fa-times" aria-hidden="true"></i>
                      )}
                    </span>
                  </div>
                  <input
                    id="attachment"
                    accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    type="file"
                    className="input-image"
                    name="attachment_name"
                    onChange={selectDocs}
                    hidden
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md="4">
                {userObj.type === "Admin" ? (
                  <Form.Group>
                    <BonoloField
                      type="select"
                      label="EOI Approval"
                      name="eoi_approval"
                      formik={formik}
                      values={approvelStatusOption}
                    />
                  </Form.Group>
                ) : (
                  <Form.Group controlId="eoiApproval">
                    <Form.Label>EOI Status</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Status"
                      value={formik.values.eoi_approval}
                      readOnly
                    />
                  </Form.Group>
                )}
              </Col>
              <Col>
                <Form.Group>
                  <BonoloField
                    placeholder="List out the deviations in your Bid, if any"
                    label="Deviations"
                    name="deviation"
                    formik={formik}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <div className="input-checkbox-outer">
                    <input
                      type="checkbox"
                      name="acceptTerms"
                      className="input-checkbox"
                      {...formik.getFieldProps("acceptTerms")}
                    />
                    <label className="checkbox">
                      {" "}
                      I accept{" "}
                      <a href={`${STATIC_WEB_URL}/terms-conditions.html`}>
                        terms and conditions
                      </a>
                      *
                    </label>
                  </div>
                  {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.acceptTerms}
                      </div>
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row className="row-upper" style={{ top: "-14px" }}>
              <Col>
                <hr className="hr" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  disabled={
                    document.URL.includes("editEOI") === true &&
                    eoiData &&
                    eoiData.eoi_approval !== PENDING &&
                    userObj.type === "User"
                  }
                  variant="primary"
                  className="submit-btn"
                  name="submit"
                  type="submit"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default AddEOI;
