import React from "react";
import {MdClear} from "react-icons/md"
import Modal from 'react-modal';

import "./modal.css"

let subtitle;
function afterOpenModal() {
  subtitle.style.color = '#c22a1f';
  subtitle.style.fontSize = '2em';

  }

 function CookieModal(props){
    const {isOpen,closeModal,errorType,errorMessage,modalHeading,errorHeading} = props;

    return(
        <Modal
          isOpen={isOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="gmail Login failed"
        >
          <div id = "rootFont" >
          <div style = {{display:"flex",justifyContent:"space-between"}}>
          <h2 ref={_subtitle => (subtitle = _subtitle)}>{modalHeading}</h2>
          
          <MdClear id = "icon"  onClick={closeModal}/>
          </div>
          <div style = {{paddingRight:"2em"}}>
          <p style = {{fontSize:"1em"}}>
            {errorHeading}
            </p>
            <p style = {{fontSize:"1em"}}>{errorMessage}</p>
           { errorType === "idpiframe_initialization_failed" && <p style = {{fontSize:"1em"}}>To continue goto cookie on browser setting and enable third party cookies </p>}
           </div>
           </div>
        </Modal>
    )
}



export {CookieModal}
  const customStyles = {
    overlay:{
        backgroundColor:"grey",
    },
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      width:"75vmin",
      // height:"40vmin",
      backgroundColor:"#fff",
      opacity:"1",
    }
  };