import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";

import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import axios from "axios";
import Alert from "react-bootstrap/Alert";
import { Loader } from "../components/toast/Loader";

export class ChangeLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageLoading: true,
      changeLogList: [],
      columns: [
        {
          dataField: "timestamp",
          text: "Timestamp",
          formatter: this.DateFormatter,
          //filter: textFilter(),
          sort: true,
        },
        {
          dataField: "user_name",
          text: "User",
          formatter: this.GetNullText,
          //filter: textFilter(),
          sort: true,
          align: "center",
          headerAlign: "center",
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
        },
        {
          dataField: "table_name",
          text: "Table",
          formatter: this.GetNullText,
          //filter: textFilter(),
          sort: true,
          align: "center",
          headerAlign: "center",
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
        },
        {
          dataField: "row_id",
          text: "Row",
          formatter: this.GetNullText,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "key",
          text: "Fields",
          formatter: this.ArrayToTextFormatter,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "prev_value",
          text: "Previous Value",
          formatter: this.ArrayToTextFormatter,
          align: "center",
          headerAlign: "center",
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
        },
        {
          dataField: "next_value",
          text: "New Value",
          formatter: this.ArrayToTextFormatter,
          align: "center",
          headerAlign: "center",
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
        },
      ],
      page: 1,
      sizePerPage: 10,
      totalLength: 10,
    };

    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidMount() {
    axios
      .get(
        `/api/log/getChangeLogs?count=${this.state.sizePerPage}&page=${this.state.page}`
      )
      .then((res) => {
        console.log(res.data);
        this.setState({
          changeLogList: res.data.logs,
          pageLoading: false,
          totalLength: res.data.totalCount,
        });
      })
      .catch(() => {
        this.setState({
          pageLoading: false,
        });
      });
  }

  GetNullText(cell, row) {
    if (row[this.dataField]) {
      return row[this.dataField];
    } else {
      return "NA";
    }
  }

  ArrayToTextFormatter(cell, row) {
    if (row[this.dataField]) {
      let data = row[this.dataField];
      if (data.length === 0) {
        return (
          <ul>
            <li>CREATED</li>
          </ul>
        );
      } else {
        return (
          <ul>
            {data.map((value) => (
              <li>{value}</li>
            ))}
          </ul>
        );
      }
    } else {
      return "NA";
    }
  }

  DateFormatter(cell, row) {
    if (row[this.dataField]) {
      let rawDate = new Date(row[this.dataField]);
      let date = rawDate.toLocaleString();
      return date;
    } else {
      return "NA";
    }
  }

  handleTableChange = (type, { page, sizePerPage }) => {
    this.setState({ pageLoading: true });
    axios
      .get(`/api/log/getChangeLogs?count=${sizePerPage}&page=${page}`)
      .then((res) => {
        console.log(res.data);
        this.setState({
          changeLogList: res.data.logs,
          pageLoading: false,
          page: page,
          sizePerPage: sizePerPage,
          totalLength: res.data.totalCount,
        });
      })
      .catch(() => {
        this.setState({
          pageLoading: false,
        });
      });
  };

  render() {
    if (this.state.pageLoading) {
      return <Loader loading={this.state.pageLoading} />;
    } else {
      return this.state.changeLogList.length > 0 ? (
        <div className="container">
          <TableWithRemotePagination
            columns={this.state.columns}
            data={this.state.changeLogList}
            page={this.state.page}
            sizePerPage={this.state.sizePerPage}
            totalSize={this.state.totalLength}
            onTableChange={this.handleTableChange}
          />
        </div>
      ) : (
        <>
          <p>
            <Alert variant="success">
              <Alert.Heading>Change Logs</Alert.Heading>
              <p>No Logs found.</p>
            </Alert>
          </p>
        </>
      );
    }
  }
}

const TableWithRemotePagination = ({
  columns,
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
}) => (
  <div>
    <PaginationProvider
      pagination={paginationFactory({
        custom: false,
        page,
        sizePerPage,
        totalSize,
        paginationSize: 10,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <div>
          <BootstrapTable
            remote
            keyField="id"
            data={data}
            columns={columns}
            onTableChange={onTableChange}
            filter = {filterFactory()}
            {...paginationTableProps}
          />
        </div>
      )}
    </PaginationProvider>
  </div>
);

export default ChangeLog;
