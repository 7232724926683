/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, {  useEffect } from "react";
//import React, from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen } from "../_metronic/layout";
import axios from 'axios';
export const ME_URL = "/api/me";

// Axios interceptor to intercept the request
// Currently adding only response interceptor
// to intercept the 401 response which shows user made Inactive by admin
// so intercept this response and logout the user
axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem('AUTH_TOKEN');
    window.location.replace('/auth/login/');
    alert("Sorry, your account is inactive. Please contact the administrator.")
  }
  return;
})

export default function App({ store, basename }) {
  useEffect(() => {
    if(window.location.host !== "localhost:3000"){
       // console.log(window.location.pathname);
       if(window.location.pathname === '/'){
         window.location.replace(`${window.location.origin}/home`);
       }
    }
  });
  return (
    <Provider store={store}>
      {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
      <React.Suspense fallback={<LayoutSplashScreen />}>
        {/* Override `basename` (e.g: `homepage` in `package.json`) */}
        <BrowserRouter basename={basename}>
          {/* Provide `react-intl` context synchronized with Redux state.  */}
          <I18nProvider>
            {/* Render routes with provided `Layout`. */}
            <Routes />
          </I18nProvider>
        </BrowserRouter>
      </React.Suspense>
    </Provider>
  );
}
