import React, { useState, useEffect, useContext } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import axios from "axios";
import GlobalState from "../utils/UserDetailContext";
import { Link } from "react-router-dom";
import { login } from "../modules/Auth/_redux/authCrud";
import { UserContext } from "../utils/UserContext";
import { Loader } from "../components/toast/Loader";

const ACTIVE = "Active";
const INACTIVE = "Inactive";

const initialValues = {
  name: "",
  imageUrl: "",
  mobile: "",
  email: "",
  status: INACTIVE,
};

const initialValuesOtp = {
  otp: "",
  mobile: "",
};

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Must be 3-100 characters")
    .max(100, "Must be 3-100 characters")
    .required("(*)Marked fields are required"),
  mobile: Yup.string()
    .required("(*)Marked fields are required")
    .typeError("Must be only digits")
    .matches(/^[0-9]+$/, "Must be only digits.")
    .min(10, "Must be 10 digit numeric value")
    .max(10, "Must be 10 digit numeric value"),
  email: Yup.string()
    .email("Must be valid email address")
    .required("(*)Marked fields are required"),
  status: Yup.string().default(INACTIVE),
});

const OtpSchema = Yup.object().shape({
  otp: Yup.string()
    // .email("Wrong email format")
    .min(6, "Minimum 3 symbols")
    .max(6, "Maximum 50 symbols")
    .required(),
  mobile: Yup.number().required("Mobile number is required."),
});

function UpdateUserProfile(props) {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useContext(GlobalState);
  const [userData, setUserData] = useState({});
  const [base64, setbase64] = useState("/media/custom/NoImage.jpg");
  const [fileData, setFileData] = useState({});
  const [templateFlag, setFlag] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const { user } = useContext(UserContext);
  const userObj = user.get() || "";
  const history = useHistory();
  useEffect(() => {
    const userDetail = async () => {
      let id = 0;
      if (props.match.params.id !== undefined) {
        id = props.match.params.id;
      }
      if (id && id !== 0) {
        if (userObj.type === "Admin") {
          const res = await axios.post("/api/me/getUserById", { id: id });
          if (res.data.imageUrl) {
            let path = `/uploads/${res.data.imageUrl}`;
            setbase64(path);
          }
          setUserData(res.data);
        } else {
          history.push("/dashboard");
        }
      } else {
        const res = await axios.get("/api/me");
        if (res.data.imageUrl) {
          let path = `/uploads/${res.data.imageUrl}`;
          setbase64(path);
        }
        setUserData(res.data);
      }
      setPageLoading(false);
    };
    userDetail(); // eslint-disable-next-line
  }, []);

  const submitHandler = async (values, { setStatus, setSubmitting }) => {
    if (!templateFlag) {
      const formData = new FormData();
      formData.append("otp", values.otp);
      Object.keys(userData).forEach((key) => {
        if (key === "file" && typeof userData["file"]["name"] !== "undefined") {
          formData.append(
            key,
            userData[key],
            `profile_${userData.id}_${+new Date()}`
          );
        } else {
          formData.append(key, userData[key]);
        }
      });
      console.log(values);
      const response = await axios.post("/api-auth/user/update/user", formData);
      if (response.data.msg) {
        alert(response.data.msg);
      } else {
        setFlag(true);
        const res = await axios.get("/api/me");
        setState(res.data);
      }
    } else if (userObj.type === "Admin") {
      values.file = fileData;
      values.updatedBy = "Admin";
      //setUserData(values);
      console.log(userData);
      const formData = new FormData();
      formData.append("otp", values.otp);
      Object.keys(values).forEach((key) => {
        if (key === "file" && typeof values["file"]["name"] !== "undefined") {
          formData.append(
            key,
            values[key],
            `profile_${values.id}_${+new Date()}`
          );
        } else {
          formData.append(key, values[key]);
        }
      });
      console.log(values);
      const response = await axios.post("/api-auth/user/update/user", formData);
      if (response.data.msg) {
        alert(response.data.msg);
      } else {
        setFlag(true);
        const res = await axios.get("/api/me");
        setState(res.data);
        alert("Profile updated sucessfully");
      }
    } else if (userObj.type === "User") {
      values.updatedBy = "User";
      values.file = fileData;
      setUserData(values);
      const otp = await axios.post("/api-auth/user/update/otp", values);
      if (otp.data.success) {
        setFlag(false);
      }
      //console.log(userObj);
    }
  };

  const handleChange = () => {
    const file = document.getElementById("user_file").files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      setbase64(reader.result);
      setFileData(file);
    };
  };
  const resendOTP = () => {
    //console.log(12345656,userData);
    login(userData.mobile)
      .then(async (data) => {})
      .catch((e) => {
        console.log("Failwed Res");
        console.log(e);
      });
  };

  if (pageLoading) {
    return <Loader loading={pageLoading} />;
  } else {
    return (
      <>
        {templateFlag === true ? (
          <>
            {/* <img src={base64} className="imgStyle" alt="img" /> */}
            <Formik
              enableReinitialize={true}
              initialValues={_.merge({}, initialValues, userData)}
              validationSchema={UserSchema}
              onSubmit={submitHandler}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  {/* <Row>
                    <Col xs="2">
                      <span style={{ position: "absolute", marginTop: "30px" }}>
                        <Form.Group className="position-relative">
                          <label
                            for="user_file"
                            className="file-label profile-label"
                          >
                            Browse File
                          </label>
                          <Form.File
                            className="position-relative file"
                            name="file"
                            onChange={handleChange}
                            id="user_file"
                            feedbackTooltip
                          />
                        </Form.Group>
                      </span>
                    </Col>
                    <Col xs="12" md="10" className="mt-3 update-profile-upper">
                      <Form.Group controlId="formGroupEmail">
                        <Form.Label>Name*</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Enter name"
                          {...formik.getFieldProps("name")}
                        />
                        {formik.touched["name"] && formik.errors["name"] ? (
                          <Form.Text className="text-danger" color="danger">
                            {formik.errors["name"]}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col
                      sm={{ offset: 2, span: 12 }}
                      md={5}
                      className="update-profile-upper"
                    >
                      <Form.Group controlId="formGroupEmail">
                        <Form.Label>Email Address*</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          {...formik.getFieldProps("email")}
                        />
                        {formik.touched["email"] && formik.errors["email"] ? (
                          <Form.Text className="text-danger" color="danger">
                            {formik.errors["email"]}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col md={5} sm={12} className="update-profile-upper">
                      <Form.Group controlId="formGroupPassword">
                        <Form.Label>Mobile Number*</Form.Label>
                        <Form.Control
                          type="tel"
                          placeholder="Enter mobile"
                          {...formik.getFieldProps("mobile")}
                        />
                        {formik.touched["mobile"] && formik.errors["mobile"] ? (
                          <Form.Text className="text-danger" color="danger">
                            {formik.errors["mobile"]}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    {userObj.type === "Admin" && (
                      <Col md={5} sm={12} className="update-profile-upper">
                        <Form.Group controlId="formGroupStatus">
                          <Form.Label>User Status*</Form.Label>
                          <Form.Control
                            as="select"
                            {...formik.getFieldProps("status")}
                          >
                            <option>{ACTIVE}</option>
                            <option>{INACTIVE}</option>
                          </Form.Control>
                          {formik.touched["status"] &&
                          formik.errors["status"] ? (
                            <Form.Text className="text-danger" color="danger">
                              {formik.errors["status"]}
                            </Form.Text>
                          ) : null}
                        </Form.Group>
                      </Col>
                    )}
                  </Row> */}

                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={2} className="organizationImageContainer">
                      <div>
                        <img
                          src={base64}
                          className="organizationImage"
                          alt="img"
                        />
                        <Form.Group>
                        <label htmlFor="user_file" className="organizationImageLabel">
                          Browse File
                        </label>
                        <Form.File
                          className="position-relative file"
                          name="file"
                          onChange={handleChange}
                          id="user_file"
                          feedbacktooltip="true"
                        />
                      </Form.Group>
                      </div>
                      
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={10}>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Form.Group controlId="formGroupEmail">
                            <Form.Label>Name*</Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Enter name"
                              {...formik.getFieldProps("name")}
                            />
                            {formik.touched["name"] && formik.errors["name"] ? (
                              <Form.Text className="text-danger" color="danger">
                                {formik.errors["name"]}
                              </Form.Text>
                            ) : null}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Form.Group controlId="formGroupEmail">
                            <Form.Label>Email Address*</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter email"
                              {...formik.getFieldProps("email")}
                            />
                            {formik.touched["email"] &&
                            formik.errors["email"] ? (
                              <Form.Text className="text-danger" color="danger">
                                {formik.errors["email"]}
                              </Form.Text>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Form.Group controlId="formGroupPassword">
                            <Form.Label>Mobile Number*</Form.Label>
                            <Form.Control
                              type="tel"
                              placeholder="Enter mobile"
                              {...formik.getFieldProps("mobile")}
                            />
                            {formik.touched["mobile"] &&
                            formik.errors["mobile"] ? (
                              <Form.Text className="text-danger" color="danger">
                                {formik.errors["mobile"]}
                              </Form.Text>
                            ) : null}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    {userObj.type === "Admin" && (
                      <Col xs={6} sm={6} md={6} lg={6} xl={4}>
                        <Form.Group controlId="formGroupStatus">
                          <Form.Label>User Status*</Form.Label>
                          <Form.Control
                            as="select"
                            {...formik.getFieldProps("status")}
                          >
                            <option>{ACTIVE}</option>
                            <option>{INACTIVE}</option>
                          </Form.Control>
                          {formik.touched["status"] &&
                          formik.errors["status"] ? (
                            <Form.Text className="text-danger" color="danger">
                              {formik.errors["status"]}
                            </Form.Text>
                          ) : null}
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col>
                      <hr className="hr" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Button
                          variant="primary"
                          name="submit"
                          type="submit"
                          className="submit-btn"
                        >
                          Submit
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <>
            <Link
              type="submit"
              onClick={() => {
                // console.log(userData);
                setFlag(true);
              }}
            >
              Back to login page
            </Link>
            <p>OTP screen</p>
            <Formik
              enableReinitialize={true}
              initialValues={_.merge({}, initialValuesOtp, userData)}
              validationSchema={OtpSchema}
              onSubmit={submitHandler}
            >
              {(formik) => (
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group controlId="formGroupPassword">
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                      type="tel"
                      readOnly
                      placeholder="Enter mobile"
                      {...formik.getFieldProps("mobile")}
                    />
                    {formik.touched["mobile"] && formik.errors["mobile"] ? (
                      <Form.Text className="text-danger" color="danger">
                        {formik.errors["mobile"]}
                      </Form.Text>
                    ) : null}
                  </Form.Group>
                  <Form.Group controlId="formGroupPassword">
                    <Form.Label>OTP</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter OTP"
                      {...formik.getFieldProps("otp")}
                    />
                    {formik.touched["otp"] && formik.errors["otp"] ? (
                      <Form.Text className="text-danger" color="danger">
                        {formik.errors["otp"]}
                      </Form.Text>
                    ) : null}
                  </Form.Group>
                  <Link
                    onClick={() => {
                      resendOTP();
                    }}
                  >
                    <span>Resend OTP</span>
                    {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}
                  </Link>
                  <Form.Group>
                    <Button variant="primary" name="submit" type="submit">
                      Submit
                    </Button>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </>
        )}
      </>
    );
  }
}

export default UpdateUserProfile;
