import React,{ useState, useMemo , useEffect,useContext,useRef } from "react";
import {Link} from "react-router-dom";

import { useHistory } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";
import {useHtmlClassService} from "../../_core/Layout";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DropdownTopbarItemToggler } from "../../../_partials/dropdowns";
import Badge from "@material-ui/core/Badge";
import CircularProgress from '@material-ui/core/CircularProgress'
import axios from "axios";
import moment from "moment";

import { UserContext } from "../../../../app/utils/UserContext";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};
export function HeaderMobile() {

  
  let history = useHistory();
  const [pageLoading, setpageLoading] = useState(true)

  const [notification, setnotification] = useState([]);
  const [unreadNotification, setunreadNotification] = useState(0);

  const uiService = useHtmlClassService();
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      headerMenuSelfDisplay:
          objectPath.get(uiService.config, "header.menu.self.display") === true,
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile")
    };
  }, [uiService]);


  
  const { user } = useContext(UserContext);
  const userObj = user.get() || "";

  const currentPage = useRef(1);
  const totalPage = useRef(0)
  useEffect(() => {
    
    const fetchData = async () => {
      if(userObj.type === "Admin"){
        
      const res = await axios.get("/api/notification", {
        params: {
          page: currentPage.current,
          limit:5,
        }
      });
      setpageLoading(false)
      setnotification(res.data.response.notificationResult);
      setunreadNotification(res.data.response.unreadCount)
      totalPage.current = res.data.response.totalPage;
      }else{
        
      const res = await axios.get(`/api/notification/${userObj.id}`,{
        params: {
          page: currentPage.current,
          limit:5,
        }
      });
      setpageLoading(false)
      setnotification(res.data.response.notificationResult);
      setunreadNotification(res.data.response.unreadCount);
      totalPage.current = res.data.response.totalPage;
      }
    };
    fetchData();
  }, [userObj]);


  const card = ({ notification, created_on, read, id ,notification_type,redirect_id}) => {
    const timeString = moment(created_on).fromNow();

    const redirect = async()=>{

      if(read === false){
        try {
          
        await axios.put(`/api/notification/`+id,userObj);
      //  const newData =  notification.map((item)=>{
      //     if(item.id === id){
      //       item.read = true
      //     }
      //     return item;
      //   })
      //   setnotification([...newData])
        } catch (err) {
          
        }
      }

      if(userObj.type === "Admin"){
        switch (notification_type) {
          case "user":
            history.push(`/updateOrganisation/${redirect_id}`);
            break;
          case "rfp":
            history.push(`/viewRFP/${redirect_id}`);
            break;
          case "auction":
            
            history.push({
              pathname:   `/liveAuction/${redirect_id}`,
              source:"notificationPanel"
            })
            break;
        
          default:
            break;
        }

      }else{
        switch (notification_type) {
          case "user":
            history.push("/updateOrganisation");
            break;
            case "rfp":
              history.push(`/viewRFP/${redirect_id}`);
              break;
              case "auction":
            history.push(`/liveAuction/${redirect_id}`);
            break;
            
        
          default:
            break;
        }
      
      }
    }
    return (
      <div
      onClick = {redirect}
        className="navi-item"
        style={{ backgroundColor: read ? null : "#f2f5f5", marginTop: "2px",cursor:"pointer", }}
        key={{id}}
      >
        <div className="navi-link">
          <div className="navi-icon mr-2">
            <i className="flaticon2-line-chart text-success"></i>
          </div>
          <div className="navi-text">
            <div className="font-weight-bold">{notification}</div>
            <div className="text-muted">{timeString}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
      <>
        {/*begin::Header Mobile*/}
        <div
            id="kt_header_mobile"
            className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
            {...layoutProps.headerMobileAttributes}
        >
          {/*begin::Logo*/}
          <Link to="/">
            <img alt="logo" src={layoutProps.headerLogo}/>
          </Link>
          {/*end::Logo*/}

          {/*begin::Toolbar*/}
          <div className="d-flex align-items-center">
            {layoutProps.asideDisplay && (
                <>
                  {/*begin::Aside Mobile Toggle*/}
                  <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
                    <span/>
                  </button>
                  {/*end::Aside Mobile Toggle*/}
                </>
            )}

            {/*begin::Topbar Mobile Toggle*/}
            <button
                className="btn btn-hover-text-primary p-0 ml-5"
                id="kt_header_mobile_topbar_toggle"
            >
              {/* man icon is here */}
              <span className="svg-icon svg-icon-xl" id="manIcon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
            </button>
            {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">Notifications</Tooltip>
              }
            >
              <div
                className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                id="kt_quick_notifications_toggle"
              >
                <Badge badgeContent={unreadNotification} color="secondary" max={9}>
                  <span className="svg-icon svg-icon-xl svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Code/notify.svg")}
                    />
                  </span>
                </Badge>
                <span className="pulse-ring"></span>
                <span className="pulse-ring" />
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
            <form>
              {/** Head */}
              <div
                className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
                style={{ backgroundImage: `url(${bgImage})` }}
              >
                <h4 className="d-flex flex-center rounded-top">
                  <span className="text-white">Notifications</span>
                  <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                    {unreadNotification}
                  </span>
                </h4>
                <div style={{ backgroundColor: "#fff" }}>
                  <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    className="navi navi-hover scroll my-4"
                    style={{ maxHeight: "300px", position: "relative" }}
                    onYReachEnd = {async()=>{
                      if(currentPage.current < totalPage.current){
                        setpageLoading(true)
                        currentPage.current = currentPage.current + 1;
                        const res = await axios.get("/api/notification", {
                          params: {
                            page: currentPage.current,
                            limit:5,
                          }
                        });
                        totalPage.current = res.data.response.totalPage;
                        setnotification((previousData)=>{
                          return [...previousData,...res.data.response.notificationResult]
                        })
                      }
                      
                      setpageLoading(false)

                    }}
                    
                  >
                    {notification.map((item) => {
                      return card(item);
                    })}
                    {
                      pageLoading &&  <div
                        style={{display:"flex", justifyContent:"center",alignItems:"center",height:"80px" }}
                      >
                        <CircularProgress />
                      </div>
                    }
                  </PerfectScrollbar>
                </div>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
            {/*end::Topbar Mobile Toggle*/}
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Header Mobile*/}
      </>
  );
}
