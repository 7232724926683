import React, { useState, useEffect } from "react";
import axios from "axios";
import { Doughnut } from "react-chartjs-2";
import { useHistory } from "react-router-dom";

function PiChartRow() {
  let history = useHistory();
  //use State section
  const [rfpData, setRfpData] = useState(rfpInitialState);
  const [eoiData, setEoiData] = useState(eoiInitialState);
  const [auctionData, setAuctionData] = useState(auctionInitialState);
  const [isLargeDevice, setisLargeDevice] = useState(window.innerWidth >= 900 || window.innerWidth <= 400 ? true : false)

  //useEffect section
  useEffect(() => {
    const _getData = async () => {
      try {
        const res = await Promise.all([
          _fetchRFPData(),
          _fetchAuctionData(),
          _fetchEoiData(),
        ]);
        _getRfpData(res[0].data);
        _getAuctionData(res[1].data);
        _getEoiData(res[2].data);
      } catch (error) {
        console.log(error);
      }
    };
    _getData();
    window.addEventListener("resize",updateOnResize)
    return () => {
      window.removeEventListener("resize",updateOnResize)
    }
  }, []);

  const updateOnResize = ()=>{
    if(window.innerWidth >= 900 || window.innerWidth <= 400){
      setisLargeDevice(true)
    }else{
      setisLargeDevice(false)
    }
  }

  //function to get rfp data from api response
  const _getRfpData = (data) => {
    data.map((item) => {
      switch (item.status) {
        case "In Progress":
          setRfpData((previousState) => {
            return {
              ...previousState,
              inProgress: item.rfp_count,
              total: previousState.total + parseInt(item.rfp_count),
            };
          });
          break;
        case "Review Pending":
          setRfpData((previousState) => {
            return {
              ...previousState,
              reviewPending: item.rfp_count,
              total: previousState.total + parseInt(item.rfp_count),
            };
          });
          break;
        case "Back For Edit":
          setRfpData((previousState) => {
            return {
              ...previousState,
              backForEdit: item.rfp_count,
              total: previousState.total + parseInt(item.rfp_count),
            };
          });
          break;
        case "Payment Pending":
          setRfpData((previousState) => {
            return {
              ...previousState,
              paymentPending: item.rfp_count,
              total: previousState.total + parseInt(item.rfp_count),
            };
          });
          break;
        case "Published":
          setRfpData((previousState) => {
            return {
              ...previousState,
              published: item.rfp_count,
              total: previousState.total + parseInt(item.rfp_count),
            };
          });
          break;
        case "EOI Closed":
          setRfpData((previousState) => {
            return {
              ...previousState,
              eoiClosed: item.rfp_count,
              total: previousState.total + parseInt(item.rfp_count),
            };
          });
          break;
        case "Canceled":
          setRfpData((previousState) => {
            return {
              ...previousState,
              cancelled: item.rfp_count,
              total: previousState.total + parseInt(item.rfp_count),
            };
          });
          break;
          case "Auction completed invalid":
          setRfpData((previousState) => {
            return {
              ...previousState,
              auctionCompletedInvalid: item.rfp_count,
              total: previousState.total + parseInt(item.rfp_count),
            };
          });
          break;
          case "Auction Complete Valid":
          setRfpData((previousState) => {
            return {
              ...previousState,
              auctionCompletedValid: item.rfp_count,
              total: previousState.total + parseInt(item.rfp_count),
            };
          });
          break;
          case "Auction set":
          setRfpData((previousState) => {
            return {
              ...previousState,
              auctionSet: item.rfp_count,
              total: previousState.total + parseInt(item.rfp_count),
            };
          });
          break;
          case "Ongoing auction":
          setRfpData((previousState) => {
            return {
              ...previousState,
              auctionLive: item.rfp_count,
              total: previousState.total + parseInt(item.rfp_count),
            };
          });
          break;

        default:
          break;
      }
      return null;
    });
  };
  //function to get auction data from api response
  const _getAuctionData = (data) => {
    data.map((item) => {
      switch (item.status) {
        case "Enable":
          setAuctionData((previousState) => {
            return {
              ...previousState,
              enable: item.auction_count,
              total: previousState.total + parseInt(item.auction_count),
            };
          });
          break;
        case "Auction Complete valid":
          setAuctionData((previousState) => {
            return {
              ...previousState,
              auctionCompletedValid: item.auction_count,
              total: previousState.total + parseInt(item.auction_count),
            };
          });
          break;
        case "Disable":
          setAuctionData((previousState) => {
            return {
              ...previousState,
              disable: item.auction_count,
              total: previousState.total + parseInt(item.auction_count),
            };
          });
          break;
        case "Auction Live":
          setAuctionData((previousState) => {
            return {
              ...previousState,
              live: item.auction_count,

              total: previousState.total + parseInt(item.auction_count),
            };
          });
          break;
          case "Auction Complete invalid":
          setAuctionData((previousState) => {
            return {
              ...previousState,
              auctionCompletedInvalid: item.auction_count,

              total: previousState.total + parseInt(item.auction_count),
            };
          });
          break;
          
          case "Auction yet to start":
          setAuctionData((previousState) => {
            return {
              ...previousState,
              auctionYetToStart: item.auction_count,
              total: previousState.total + parseInt(item.auction_count),
            };
          });
          break;

        default:
          break;
      }
      return null;
    });
  };
  const _getEoiData = (data) => {
    data.map((item) => {
      switch (item.status) {
        case "Approved":
          setEoiData((previousState) => {
            return {
              ...previousState,
              approved: item.eoi_count,
              total: previousState.total + parseInt(item.eoi_count),
            };
          });
          break;
        case "Pending":
          setEoiData((previousState) => {
            return {
              ...previousState,
              pending: item.eoi_count,

              total: previousState.total + parseInt(item.eoi_count),
            };
          });
          break;
        case "Cancel":
          setEoiData((previousState) => {
            return {
              ...previousState,
              cancelled: item.eoi_count,

              total: previousState.total + parseInt(item.eoi_count),
            };
          });
          break;

        default:
          break;
      }
      return null;
    });
  };
  const _piChart = (data,categoryRoute) => {
    return (
      <Doughnut
        data={data}
        width={300}
        height={150}
        
        options={{
          // maintainAspectRatio: false,
          events: ['mousemove', 'mouseout', 'click', 'touchstart',"touchmove"], 
          onClick:(event,arr)=>{
            const label = arr[0]._view.label;
            history.push({
              pathname:   categoryRoute,
              defaultSelect:label
            })

          },
          legend: {
            display:!isLargeDevice,
            position: "bottom",
            
            labels: {
              usePointStyle: true,
              boxWidth: 8,
              fontColor: "#2B3D66",
              fontSize: 10,
            },
            
          },
          cutoutPercentage: 50,
        }}
      />
    );
  };
  return (
    <div className="chartRow">
      <div className="chartContainer">
        <div style={{ display: "flex" }}>
          <p className="chartHeading">RFPs</p>
          <p className="chartHeading">Total - {rfpData.total}</p>
        </div>
        <div>
          {_piChart({
            datasets: [
              {
                data: [
                  rfpData.published,
                  rfpData.cancelled,
                  rfpData.eoiClosed,
                  rfpData.reviewPending,
                  rfpData.backForEdit,
                  rfpData.paymentPending,
                  rfpData.inProgress,
                  rfpData.auctionCompletedInvalid,
                  rfpData.auctionCompletedValid,
                  rfpData.auctionLive,
                  rfpData.auctionSet
                ],
                backgroundColor: RFP_CHART_COLOR,
              },
            ],
            labels: RFP_CHART_LABEL,
          },"/admin/listRFP")}
        </div>
      </div>
      <div className="chartContainer">
        <div style={{ display: "flex" }}>
          <p className="chartHeading">Auctions</p>
          <p className="chartHeading">Total - {auctionData.total}</p>
        </div>
        <div>
          {_piChart({
            datasets: [
              {
                data: [
                  auctionData.auctionYetToStart,
                  auctionData.live,
                  auctionData.auctionCompletedValid,
                  auctionData.auctionCompletedInvalid,
                  auctionData.disable,
                  auctionData.enable,
                ],
                backgroundColor: AUCTIONS_CHART_COLOR,
              },
            ],
            labels: AUCTION_CHART_LABEL,
          },"/admin/listAuction")}
        </div>
      </div>
      <div className="chartContainer">
        <div style={{ display: "flex" }}>
          <p className="chartHeading">EOIs</p>
          <p className="chartHeading">Total - {eoiData.total}</p>
        </div>
        <div>
          {_piChart({
            datasets: [
              {
                data: [eoiData.approved, eoiData.cancelled, eoiData.pending],
                backgroundColor: EOI_CHART_COLOR,
              },
            ],
            labels: EOI_CHART_LABEL,
          },"/admin/listEOI")}
        </div>
      </div>
    </div>
  );
}

export default PiChartRow;


//initial state rfp
const rfpInitialState = {
  backForEdit: 0,
  cancelled: 0,
  inProgress: 0,
  paymentPending: 0,
  published: 0,
  reviewPending: 0,
  eoiClosed: 0,
  total: 0,
  auctionCompletedInvalid:0,
  auctionCompletedValid:0,
  auctionSet:0,
  auctionLive:0,

};
//initial state eoi
const eoiInitialState = {
  cancelled: 0,
  approved: 0,
  pending: 0,
  total: 0,
};
//initial state auction
const auctionInitialState = {
  enable: 0,
  live: 0,
  auctionCompletedValid: 0,
  auctionCompletedInvalid: 0,
  disable: 0,
  auctionYetToStart:0,
  total: 0,
};

//api for fetching rfp count
const _fetchRFPData = () => {
  return axios.get("/api/RFP/rfpCount", {
    params: {
      q: "count_by_status",
    },
  });
};
//api for fetching Auction count
const _fetchAuctionData = () => {
  return axios.get("/api/auction/auctionCount", {
    params: {
      q: "count_by_status",
    },
  });
};
//api for fetching EOI count
const _fetchEoiData = () => {
  return axios.get("/api/EOI/eoiCount", {
    params: {
      q: "count_by_status",
    },
  });
};



const RFP_CHART_COLOR = [
  "#003f5c",
  "#a05195",
  "#d45087",
  "#f1f1f1",
  "#ff7c43",
  "#6C5FFF",
  "#ffa600",
  "#E8A09A",
  "#C6D68F",
  "#FBE29F",
  "#9BBFE0"
];


const AUCTIONS_CHART_COLOR = ["#447FFF", "#a05195", "#FBE29F", "#5dcf4e","#d45087","#E8A09A"];
const EOI_CHART_COLOR = ["#447FFF", "#a05195", "#FBE29F"];

const RFP_CHART_LABEL = [
  "Published",
  "Canceled",
  "EOI Closed",
  "Review Pending",
  "Back For Edit",
  "Payment Pending",
  "In Progress",
  "Auction completed invalid",
  "Auction Complete Valid",
  "Ongoing auction",
  "Auction set",
];
const AUCTION_CHART_LABEL =
 [
"Auction yet to start",
"Auction Live",
"Auction Complete valid",
"Auction Complete invalid",
"Disable",
"Enable"
];
const EOI_CHART_LABEL = ["Approved", "Cancel", "Pending"];
