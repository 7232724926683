/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React , { useContext } from "react";
// import { useLocation } from "react-router";
// import { NavLink } from "react-router-dom";
// import { checkIsActive } from "../../../../_helpers";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_helpers";
import { useHistory } from "react-router-dom";
import { Col, Row, Container  } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GlobalState from '../../../../../app/utils/UserDetailContext';

export function HeaderMenu({ layoutProps }) {
    // const location = useLocation();
    // const getMenuItemActive = (url) => {
    //     return checkIsActive(location, url) ? "menu-item-active" : "";
    // }
    const [state] = useContext(GlobalState);
    const history = useHistory();
    const logoutClick = () => {
        localStorage.clear();
        sessionStorage.clear();
        const toggle = document.getElementById("kt_quick_user_toggle");
        if (toggle) {
          toggle.click();
          history.push("/auth/login");
          window.location.reload(); 
        } 
    };
    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Side Nav*/}
        <Container className="d-lg-none">
          <Row>
            <Col>
              <h2 className="font-weight-bold pt-5">User Profile</h2>
                <div className="mt-4">
                <div className="symbol symbol-100 mr-5">
                <div className="symbol-label" style={{
                backgroundImage: `url(${toAbsoluteUrl(`/uploads/${state.imageUrl}`)})`
                // backgroundImage: ` ${state.name}`
                }}/>
                <i className="symbol-badge bg-success"/>
                </div>

                <div className="d-flex flex-column pt-3">
              <a
                  href="#"
                  className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              >
                {state.name}
              </a>
              <div className="navi mt-2">
                <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                <span className="navi-icon mr-1">
                  <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                          src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Mail-notification.svg"
                          )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary" >
                    {state.email}
                  </span>
                </span>
                </a>
              </div>
              <button className="btn btn-light-primary btn-bold" onClick={logoutClick}>Sign out</button>
            </div>
            </div>  
            
            <div className="separator separator-dashed mt-8 mb-5"/>

            <div className="navi navi-spacer-x-0 p-0">
            <a href="/user/profile" className="navi-item">
              <div className="navi-link">
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/General/Notification2.svg"
                        )}
                    ></SVG>
                  </span>
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold" href="/profile">My Profile</div>
                  <div className="text-muted">
                    Account settings and more
                    {" "}
                    {/* <span className="label label-light-danger label-inline font-weight-bold">
                    update
                  </span> */}
                  </div>
                </div>
              </div>
            </a>
            </div>
            </Col>
          </Row>
        </Container>
        {/*end::Side Nav*/}
    </div>;
}
