import React,{useState,useEffect} from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";

function AdminTable() {
  const [tableData, settableData] = useState([])
  useEffect(() => {
    _fetchTableData((res)=>{
      if(res !== null){
        settableData(res);
      }
    });
  },[])
  return (
      <BootstrapTable
        data={tableData}
        columns={columns}
        keyField="EOI Count"
        striped
        hover
        condensed
        rowStyle={{
          backgroundColor: "#fff",
          fontSize: "12px",
          // lineHeight: "15px",
        }}
      />
  );
}

export default AdminTable;


const nullChecker = cell => (!cell ? "0" : cell);

const columns = [
  {
    dataField: "entry",
    text: "Entries",
    align: 'center',
    headerAlign: 'center'
  },
  {
    dataField: "EOI Count",
    text: "EOI",
    formatter: nullChecker,
    align: 'center',
    headerAlign: 'center'
  },
  {
    dataField: "RFP Count",
    text: "RFP",
    formatter: nullChecker,
    align: 'center',
    headerAlign: 'center'
  },
  {
    dataField: "AuctionComplete Count",
    text: "Auction Complete",
    formatter: nullChecker,
    align: 'center',
    headerAlign: 'center'
  },
  {
    dataField: "Auction Count",
    text: "Auction",
    formatter: nullChecker,
    align: 'center',
    headerAlign: 'center'
  },
  {
    dataField: "Organization Count",
    text: "Organization",
    formatter: nullChecker,
    align: 'center',
    headerAlign: 'center'
  },
  {
    dataField: "AuctionValid Count",
    text: "Auction Valid",
    formatter: nullChecker,
    align: 'center',
    headerAlign: 'center'
  },{
    dataField: "User Count",
    text: "User Count",
    formatter: nullChecker,
    align: 'center',
    headerAlign: 'center'
  }
];



//api for fetch  queries
const _fetchTableData = async(callback) => {
  let result = [];
  try {
    let res = await axios.get("/api/admin/dashboardTable");
    res = res.data.response;
    for (let key of Object.keys(res)) {
      result.push(res[key][0]);
    }
    callback(result);
  } catch (err) {
    callback(null);
  }
};
