import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { UserContext } from "../utils/UserContext";
import Alert from "react-bootstrap/Alert";
import { trackUsers } from "../modules/Auth/_redux/authCrud";
import { Loader } from "../components/toast/Loader";
import moment from "moment";
export class UserTrack extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
      userList: [],
      columns: [
        {
          dataField: "ip",
          text: "IP",
          formatter: this.GetNullText,
          //filter: textFilter(),
          sort: true,
        },
        {
          dataField: "reference",
          text: "Campaign Name",
          formatter: this.GetNullText,
          //filter: textFilter(),
          sort: true,
          align: "center",
          headerAlign: "center",
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
              width: "100px",
            },
          }),
          //  headerAlign: "center",
        },
        // {
        //   dataField: "information",
        //   text: "Information",
        //   formatter: this.GetNullText,
        //   //filter: textFilter(),
        //   sort: true,
        //   align: "center",
        //   headerAlign: "center",
        //   filter: textFilter({
        //     placeholder: "Search",
        //     style: {
        //       boxSizing: "border-box",
        //        width:"150px"
        //     },
        //   }),
        //    headerAlign: "center",
        // },
        {
          dataField: "region",
          text: "Region ",
          formatter: this.GetNullText,
          //filter: textFilter(),
          sort: true,
          align: "center",
          headerAlign: "center",
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
              width: "100px",
            },
          }),
          //  headerAlign: "center",
        },
        {
          dataField: "created_at",
          text: "Date ",
          formatter: this.DateFormatter,
          //filter: textFilter(),
          sort: true.valueOf,
          align: "center",
          headerAlign: "center",
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
              width: "100px",
            },
          }),
          //  headerAlign: "center",
        },
      ],
    };
  }

  componentDidMount() {
    if (this.context.user.get().type !== "Admin") {
      window.history.back();
    } else {
      trackUsers().then((res) => {
        console.log("trackusers:");
        this.setState({
          userList: res.response,
          pageLoading: false,
        });
      });
    }
    
  }

  GetNullText(cell, row) {
    if (this.dataField.includes(".")) {
      let field = this.dataField.split(".");
      return row[field[0]][field[1]];
    }
    if (row[this.dataField]) {
      console.log(this.dataField);
      console.log(row);
      return row[this.dataField];
    } else {
      return "NA";
    }
  }

  DateFormatter(cell, row) {
    if (row[this.dataField]) {
      return moment(row[this.dataField]).format("lll");
    } else {
      return "NA";
    }
  }

  render() {
    const options = {
      page: 1,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "20",
          value: 20,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "All",
          value: this.state.userList.length,
        },
      ],
      sizePerPage: 5,
      pageStartIndex: 1,
      paginationSize: 10,
      prePage: "Prev",
      nextPage: "Next",
      firstPage: "First",
      lastPage: "Last",
      paginationPosition: "bottom",
    };

    if (this.state.pageLoading) {
      return <Loader loading={this.state.pageLoading} />;
    } else {
      return (
        <>
          {this.state.userList.length > 0 ? (
            <>
              <div className="container">
                <BootstrapTable
                  id="userTable"
                  striped
                  hover
                  keyField="id"
                  data={this.state.userList}
                  columns={this.state.columns}
                  filter={filterFactory()}
                  pagination={
                    this.state.userList.length > 5
                      ? paginationFactory(options)
                      : false
                  }
                />
              </div>
            </>
          ) : (
            <>
              <p>
                <Alert variant="success">
                  <Alert.Heading>User List</Alert.Heading>
                  <p>No visitors found.</p>
                </Alert>
              </p>
            </>
          )}
        </>
      );
    }
  }
}
export default UserTrack;
