import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useFormik } from "formik";
import Modal from "react-modal";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { GoogleLogin } from "react-google-login";
// import { GoogleLogout } from 'react-google-login';
import { checkUserExistence } from "../_redux/authCrud";
import { CookieModal } from "../../../components/Modal/Modal";
import configFile from '../../../../config.js';

const STATIC_WEB_URL = configFile.STATIC_WEB_URL;

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  mobile: "",
};

Modal.setAppElement("#root");

function LoginOld(props) {
  const history = useHistory();
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [gmailLoginError, setGmailLoginError] = useState({
    isOpen: false,
    errorType: "",
    errorMessage: "",
    modalHeading: "",
    errorHeading: "",
    initialPopUp: true,
  });

  const LoginSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Mobile number is not valid.")
      .min(10, "Mobile number is not valid.")
      .max(10, "Mobile number is not valid.")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    // mobile: Yup.string()
    //   // .email("Wrong email format")
    //   .min(10, "Minimum 10 Chars")
    //   .max(15, "Maximum 15 Chars")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   )
  });

  useEffect(() => {
    // if(window.gapi){
    // var auth2 = window.gapi.auth2.getAuthInstance();
    // console.log(auth2);
    // }
  });
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Login Called");
      enableLoading();
      login(values.mobile)
        .then(async (data) => {
          if (data.msg === "NOT A ACTIVE USER") {
            console.log(data.msg);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.NOT_EXIST",
              })
            );
            disableLoading();
            setTimeout(() => {
              history.push({
                pathname: "/auth/registration",
                state: {
                  number: values.mobile,
                },
              });
            }, 1000);
          } else if (data.msg === "INACTIVE USER") {
            /* User is registered but inactive */
            disableLoading();
            alert(
              "Sorry, your account is inactive. Please contact the administrator."
            );
          } else {
            //add state
            await sessionStorage.setItem("LOGIN", JSON.stringify(values));
            history.push("/auth/login-otp");
            disableLoading();
          }
        })
        .catch((e) => {
          console.log("Failwed Res");
          console.log(e);
        });
    },
  });

  //On gmail login success
  const responseGoogle = (response) => {
    // clear previous user info
    // window.document.querySelector('.google-logout').click();

    // check if user registered with this email
    checkUserExistence(response.profileObj.email).then((res) => {
      if (res.response) {
        localStorage.setItem("LOGGED_USER", JSON.stringify(res.response.user));
        // gmail login successful
        localStorage.setItem("AUTH_TOKEN", res.response.token);

        //iframe local storage issue
        var win = document.getElementsByTagName('iframe')[0].contentWindow;
        win.postMessage(JSON.stringify(
          {key: 'LOGGED_USER', data: res.response.user}
        ), "*");
        win.postMessage(JSON.stringify(
          {key: 'AUTH_TOKEN', data: res.response.token}
        ), "*");
        //end iframe

        setTimeout(() => {
          window.location.reload(); 
          history.push('/dashboard');
        }, 1000);
      } else if (res.msg === "INACTIVE USER") {
        /* User is registered but inactive */
        disableLoading();
        alert(
          "Sorry, your account is inactive. Please contact the administrator."
        );
      } else {
        // if user not registered
        history.push({
          pathname: "/auth/registration",
          state: {
            name: response.profileObj.name,
            email: response.profileObj.email,
            googleLogin: "gmail",
          },
        });
        // window.location.reload();
        // history.push('/dashboard');
      }
    });
  };

  const responseGoogleFailed = async (err) => {
    const { error } = err;
    switch (error) {
      case "idpiframe_initialization_failed":
        await setGmailLoginError({
          ...gmailLoginError,
          isOpen: true,
          errorType: "idpiframe_initialization_failed",
          modalHeading: gmailLoginError.initialPopUp
            ? "warning!!!"
            : "Error!!!",
          errorHeading: gmailLoginError.initialPopUp ? "Login failed" : "",
          errorMessage: "gmail login won't work",
          initialPopUp: false,
        });

        break;
      case "popup_closed_by_user":
        await setGmailLoginError({
          ...gmailLoginError,
          isOpen: true,
          errorType: "popup_closed_by_user",
          modalHeading: "Error!!!",
          errorHeading: "Login failed",
          errorMessage: "pop up closed",
        });
        break;
      case "access_denied":
        await setGmailLoginError({
          ...gmailLoginError,
          isOpen: true,
          errorType: "access_denied",
          modalHeading: "Error!!!",
          errorHeading: "Login failed",
          errorMessage: "access denied by user",
        });

        break;
      default:
        await setGmailLoginError({
          ...gmailLoginError,
          isOpen: true,
          errorType: "popup_closed_by_user",
          modalHeading: "Error!!!",
          errorHeading: "Login failed",
          errorMessage: "some error occurred on login",
        });
        break;
    }
  };

  // const logout = (response) => {
  //   console.log("logout");
  // }

  function closeModal() {
    setGmailLoginError({ ...setGmailLoginError, isOpen: false });
  }

  return (
    <>
      <CookieModal
        isOpen={gmailLoginError.isOpen}
        errorType={gmailLoginError.errorType}
        errorMessage={gmailLoginError.errorMessage}
        modalHeading={gmailLoginError.modalHeading}
        errorHeading={gmailLoginError.errorHeading}
        closeModal={closeModal}
      />

      <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
        <span className="font-weight-bold text-dark-50">
          Don't have an account yet?
        </span>
        <Link
          to="/auth/registration"
          className="font-weight-bold ml-2"
          id="kt_login_signup"
        >
          Sign Up!
        </Link>
      </div>
      <div className="login-form login-signin" id="kt_login_signin_form">
        {/* begin::Head */}
        {/* <GoogleLogout
          className="google-logout"
          clientId="700187318885-v0p1rgedmebig8kre14f6t73a3caokn1.apps.googleusercontent.com"
          buttonText="Logout"
          onLogoutSuccess={logout}
        ></GoogleLogout> */}
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.LOGIN.TITLE" />
          </h3>

          <p className="text-muted font-weight-bold">
            Enter your mobile and receive OTP to login.
          </p>
        </div>
        {/* end::Head */}

        {/*begin::Form*/}
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          {formik.status ? (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          ) : null}

          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Mobile"
              type="tel"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "mobile"
              )}`}
              name="mobile"
              {...formik.getFieldProps("mobile")}
            />
            {formik.touched.mobile && formik.errors.mobile ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.mobile}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
            <button
              id="kt_login_signin_submit"
              type="submit"
              // disabled={formik.isSubmitting}
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            >
              <span>Request OTP</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
            <GoogleLogin
              clientId="201500336140-rnjv1fb9aomahqr1tbcstqaguar765gi.apps.googleusercontent.com"
              buttonText="Login With Gmail"
              onSuccess={responseGoogle}
              onFailure={responseGoogleFailed}
              cookiePolicy={"single_host_origin"}
            />
          </div>
        </form>
        {/*end::Form*/}
      </div>
      <iframe title="iframe" style={{display:"none", visibility:"hidden"}} src={`${STATIC_WEB_URL}iframe.html`}>https://bonolo.in/iframe.html</iframe>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(LoginOld));