import React from "react";
import LiveAlerts from "../components/LiveAlerts";
import LiveStastics from "../components/LiveStastics";
import PiChartRow from "../components/PiChartRow";
import AdminTable from "../components/AdminTable";

function AdminDashboard() {
  return (
    <div className="adminDashBoard">
      <LiveAlerts />
      <hr />
      <LiveStastics />
      <PiChartRow />
      <hr />
      <AdminTable />
    </div>
  );
}

export default AdminDashboard;
