/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
// import { shallowEqual, useSelector } from "react-redux";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { UserContext } from "./utils/UserContext";

export function Routes() {
    const { user } = useContext(UserContext);
    const userObj = user.get();
   const isAuthorized = userObj != null;
   //const isAuthorized = false;
    return (
        <Switch>
            {
                (!isAuthorized) ? (
                    <Route>
                        <AuthPage />
                    </Route>
                ) :
                    (
                        <Layout>
                            <BasePage />
                        </Layout>
                    )
            }
            <Route path="/error" component={ErrorsPage} />
            <Route path="/logout" component={Logout} />



        </Switch>
    );
}
