import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
export default function BonoloField({
  label,
  name,
  formik,
  type,
  values,
  options,
  styleClass,
  placeholder,
  handleChange,
  defaultSelected,
}) {
  // console.log(placeholder);
  const [singleSelections, setSingleSelections] = useState([]);
  //console.log(options);
  type = type || "text";
  values = values || [];
  React.state = {
    // Initially, no file is selected
    selectedFile: null,
  };
  let control = null;
  // if(defaultSelected){
  //   setSingleSelections([defaultSelected]);
  // }
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };
  const onFileChange = (event) => {
    // this.props.someFunctionHere(event.target.files[0]);
    //console.log();
    // alert(event);
    // Update the state
    //this.setState({ selectedFile: event.target.files[0] });
    // this.setState({selectedFile: event.target.files[0]}, () => {
    //   if (this.props.onChange) {
    //     this.props.onChange(React.state);
    //   }
    // })
  };
  const className = getInputClasses(name);

  switch (type) {
    case "text":
      control = (
        <Form.Control
          className={styleClass}
          disabled={label === "Close Time" ? true : false}
          name={name}
          placeholder={placeholder}
          {...formik.getFieldProps(name)}
        />
      );
      break;
    case "radio":
      control = (
        <Form.Check
          type="radio"
          aria-label="radio"
          className={styleClass}
          name={name}
          {...formik.getFieldProps(name)}
        />
      );
      break;
    case "textarea":
      control = (
        <Form.Control
          className={styleClass}
          as="textarea"
          name={name}
          placeholder={placeholder}
          {...formik.getFieldProps(name)}
        />
      );
      break;
    case "file":
      control = (
        <Form.File
          className={styleClass}
          name={name}
          id="org_file"
          onChange={onFileChange}
          {...formik.getFieldProps(name)}
        />
      );
      // control = <Form.Control className={className} as="file" name={name} placeholder={`Enter ${label}`}  {...formik.getFieldProps(name)} />
      break;
    // <input type="file" onChange={this.onFileChange} />
    case "active_inactive":
      control = (
        <Form.Control
          as="select"
          className={styleClass}
          name={name}
          id={name}
          {...formik.getFieldProps(name)}
        >
          <option disabled value="null">
            -- select an option --
          </option>
          <option value={true} id="Active" selected>
            Active
          </option>
          <option value={false} id="Inactive">
            Inactive
          </option>
        </Form.Control>
      );
      break;
    case "select":
      control = (
        <Form.Control
          as="select"
          className={styleClass}
          name={name}
          {...formik.getFieldProps(name)}
        >
          {values.map((r) => {
            return (
              <option value={r[0]} key={r[0]}>
                {r[1]}
              </option>
            );
          })}
        </Form.Control>
      );
      break;
    case "typeahead":
      control = (
        <Typeahead
          id="basic-typeahead-single"
          labelKey={name}
          name="typeahead_component"
          className={styleClass}
          onChange={
            (e) => {
              setSingleSelections(e);
              formik.values.productCategoryId = e[0];
              if (formik.values.productCategoryId) {
                formik.errors["productCategoryId"] = null;
                // console.log('123');
              } else {
                formik.errors["productCategoryId"] =
                  "Category is a required field.";
              }
              // console.log(formik.errors);
            }
            //setSingleSelections
          }
          options={options}
          //defaultSelected={options}
          placeholder="Choose a product category..."
          selected={singleSelections}
        />
      );
      break;
    case "selectArray":
      control = (
        <Form.Control
          as="select"
          className={className}
          name={name}
          {...formik.getFieldProps(name)}
          multiple
        >
          {Object.keys(values).map((value, index) => {
            return (
              <option value={values[index].id} key={values[index].id}>
                {values[index].name}
              </option>
            );
          })}
        </Form.Control>
      );
      break;

    default:
      break;
  }

  return (
    <>
      <Form.Label>{label}</Form.Label>
      {control}
      {formik.touched[name] && formik.errors[name] ? (
        <Form.Text className="text-danger" color="danger">
          {formik.errors[name]}
        </Form.Text>
      ) : null}
    </>
  );
}
