import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Table, Col } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import InputModal from "./InputModal";
import moment from "moment";
function AuctionDate({ socket, props, socketStatus, setRemainingTime }) {
  const [value, setvalue] = useState();

  useEffect(() => {
    if (socketStatus) {
      socket.on("dateAndRFPId", (data) => {
        setvalue(data.date);
        setRemainingTime(data.remainingMilliSeconds);
      });
    }
  }, [socketStatus, setRemainingTime, socket]);
  return (
    <>
      <h3>{value}</h3>
    </>
  );
}

function UserRank({ socket, props, socketStatus, isVendor, onChangeAuction, onChangeL1Bid }) {
  const [value, setvalue] = useState("-");

  useEffect(() => {
    if (socketStatus) {
      socket.on("getRankAndAuctionStatus", (res) => {

        setvalue(res.user);

        if (res.auction === "invalid") {
          onChangeAuction(1);
        } else if (res.auction === "valid") {
          onChangeAuction(0);
        }

        onChangeL1Bid(res.l1bid);
      });
    }
    // eslint-disable-next-line
  }, [socketStatus, isVendor, socket]);
  if (isVendor === "1") {
    return (
      <>
        <Col
          className="product-main-col2"
          xs={{ order: 1, span: 12 }}
          md={{ order: 2, span: 4 }}
        >
          <div className="bid-circle">
            <h1>{value}</h1>
            <span className="bid-circle-small"></span>
          </div>
        </Col>
      </>
    );
  } else {
    return <></>;
  }
}

function RankTable({ socket, rfpId, socketStatus }) {
  const [value, setvalue] = useState([]);

  const [inputModal, setInputModal] = useState({
    show: false,
    bidId: "",
    bidPrice: "",
  });
  const [userInChange, setUserInChange] = useState({
    rfpId: "",
    user_Id: "",
  });
  useEffect(() => {
    if (socketStatus) {
      socket.on("rankTableData", (res) => {
        setvalue(res.data);
      });
    }
  }, [socket, socketStatus, value]);

  const updateBid = (bidId, bidValue, userStatus, isPriceUpdate) => {
    setInputModal({
      show: false,
      bidId: "",
      bidPrice: "",
    });
    if (isPriceUpdate) {
      if (socketStatus) {
        console.log("bid updated");
        console.log(`userstatus::::::::${userStatus}`);
        let temp = {};
        temp["bidId"] = bidId;
        temp["rfpId"] = userInChange.rfpId;
        temp["userId"] = userInChange.user_Id;
        temp["bidPrice"] = parseInt(bidValue);
        temp["userStatus"] = userStatus;
        console.log(`bid price:${typeof parseInt(bidValue)}`);
        socket.emit("updateBid", temp);
        let temp2 = {};
        temp2["rfpId"] = rfpId;
        temp2["socketId"] = socket.id;
        socket.emit("rankTable", temp2);
      }
    }
  };

  //old table row code at bottom *1
  // var tbody = "";
  // if (value.length > 0) {
  //   for (var i = 0; i < value.length; i++) {
  //     let data = value[i];
  //     let date = moment(data.createdAt).format("LLL");
  //     let tr = `<tr><td>${data.userId}</td><td>${data.price}</td><td>${i +
  //       1}</td><td>${date}</td></tr>`;
  //     tbody = tbody + "" + tr;
  //   }
  // }

  return (
    <>
      {value.length > 0 ? (
        <>
          <div className="container">
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Vendor</th>
                  <th>Price</th>
                  <th>Rank</th>
                  <th>Date</th>
                </tr>
              </thead>
              {/* <tbody dangerouslySetInnerHTML={{__html: tbody}}> */}
              <tbody>
                {value.map((currentValue, i) => (
                  <>
                    <tr>
                      <td
                        style={
                          currentValue.isActive
                            ? { textDecoration: "none" }
                            : { textDecoration: "line-through" }
                        }
                      >
                        {currentValue.userId}
                        {/* {!currentValue.isActive && (
                          <span
                            class="far fa-times-circle bid"
                            style={{}}
                          ></span>
                        )} */}
                      </td>
                      <td className="bid-price">
                        {currentValue.isActive ? currentValue.price : "---"}
                        
                        <span
                          class="far fa-edit bid-edit"
                          onClick={() => {
                            setUserInChange({
                              rfpId: currentValue.rfpId,
                              user_Id: currentValue.primaryId,
                            });
                            setInputModal({
                              show: true,
                              bidId: currentValue.id,
                              bidPrice: currentValue.price,
                            });
                          }}
                        ></span>
                      </td>
                      <td>{currentValue.isActive ? i + 1 : "---"}</td>
                      <td>{moment(currentValue.createdAt).format("LLL")}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </Table>
            {inputModal.show && (
              <InputModal
                callBack={updateBid}
                show={inputModal.show}
                bidId={inputModal.bidId}
                bidPrice={inputModal.bidPrice}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <p>
            {" "}
            <Alert variant="success">
              <Alert.Heading>Live Auction Page</Alert.Heading>
              <p>There are no Auction found.</p>
            </Alert>
          </p>
        </>
      )}
    </>
  );
}

export { AuctionDate, UserRank, RankTable };

// *1
