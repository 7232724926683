import React, { useEffect, useState } from "react";
import Badge from "@material-ui/core/Badge";
import { useHistory } from "react-router-dom";
import { BsFillBellFill } from "react-icons/bs";
import {Row, Col} from "react-bootstrap";
import axios from "axios";

const initialState = {
  rfpWithLessThen10EOI: 0,
  invalidAuctions: 0,
  pendingRFP: 0,
  pendingEOIs: 0,
  unreadQueries: 0,
  auctionToBeSet: 0,
};
function LiveAlerts() {
  let history = useHistory();
  //state
  const [alertsData, setalertsData] = useState(initialState);

  //use effect
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await Promise.all([
          _fetchRFpLessThen10EOiAPI(),
          _fetchPendingRFPApi(),
          _fetchEoiData(),
          _fetchQuery()
        ]);
        _getRfpWithLessThen10Eoi(res[0].data);
        _getPendingRfp(res[1].data);
        _getEoiData(res[2].data);
        _getQueries(res[3].data)
      } catch (error) {
        console.log(error);
        console.log("error" + error);
      }
    };
    getData();
  }, []);

  //function to get rfp with less then 10 rfp from api response
  const _getRfpWithLessThen10Eoi = (data) => {
    setalertsData((previousState) => {
      return { ...previousState, rfpWithLessThen10EOI: data.rfpCount.count };
    });
  };
  //function to get rfp with status pending
  const _getPendingRfp = (data) => {
    data.map((item) => {
      if (item.status === "Review Pending") {
        setalertsData((previousState) => {
          return {
            ...previousState,
            pendingRFP: item.rfp_count,
          };
        });
      }
      if (item.status === "Auction completed invalid") {
        setalertsData((previousState) => {
          return {
            ...previousState,
            invalidAuctions: item.rfp_count,
          };
        });
      }
      if (item.status === "EOI Closed") {
        setalertsData((previousState) => {
          return {
            ...previousState,
            auctionToBeSet: item.rfp_count,
          };
        });
      }
      return null;
    });
  };
  //function to get eoi data
  const _getEoiData = (data) => {
    data.map((item) => {
      if (item.status === "Pending") {
        setalertsData((previousState) => {
          return {
            ...previousState,
            pendingEOIs: item.eoi_count,
          };
        });
      }
      return null;
    });
  };
  //function to get queries from api response
  const _getQueries = (data) => {
    data.map((item) => {
      if (item.resolved_status === false) {
        setalertsData((previousState) => {
          return {
            ...previousState,
            unreadQueries:
              previousState.unreadQueries + parseInt(item.resolved_count),
          };
        });
      }

      return null;
    });
  };

  const _liveAlertCard = (alertLabel, data,route,defaultSelect,rfpHavingLessEoi) => {
    return (
      <div style={{ paddingTop: "20px", paddingBottom: "10px" }} >
        <Badge style={{cursor:"pointer"}} badgeContent={data} color="secondary" showZero onClick={()=>_routeHandler(route,defaultSelect,rfpHavingLessEoi)}>
          <p className="liveAlertCard">{alertLabel}</p>
        </Badge>
      </div>
    );
  };

  const _routeHandler = (route,defaultSelect,rfpHavingLessEoi)=>{
    if(rfpHavingLessEoi){
      history.push({
        pathname:   `/${route}`,
        rfpHavingLessEoi:true
      })
    }else{
      history.push({
        pathname:   `/${route}`,
        defaultSelect:defaultSelect
      })
    }
    
  }
  return (
    <Row className="liveAlert">
      <Col xs={12} sm={2} md={2} lg={2} xl={2} className="bellCard">
        <div
          style={{
            padding: "10px",
            backgroundColor: "#447FFF",
            borderRadius: "9px",
          }}
        >
          <BsFillBellFill
            style={{ height: "20px", width: "20px", color: "#fff" }}
          />
        </div>
        <p>LIVE ALERTS</p>
      </Col>
      <Col xs={4}  sm={2} md={2} lg={2} xl={2}>
        {_liveAlertCard(
          "RFPs with less than 10 EOI",
          alertsData.rfpWithLessThen10EOI,
          "admin/listRFP",
          undefined,
          true
        )}
      </Col>
      <Col xs={4}  sm={2} md={2} lg={2} xl={2}>
        {_liveAlertCard("Invalid Auctions", alertsData.invalidAuctions,"admin/listAuction","Auction completed invalid")}
      </Col>
      <Col xs={4}  sm={2} md={2} lg={2} xl={2}>{_liveAlertCard("Pending RFPs", alertsData.pendingRFP,"admin/listRFP","Review Pending")}</Col>

      <Col xs={4}  sm={2} md={2} lg={2} xl={2}>{_liveAlertCard("Pending EOIs", alertsData.pendingEOIs,"admin/listEOI","Pending")}</Col>


      <Col xs={4}  sm={2} md={2} lg={2} xl={2}>
        {_liveAlertCard("Auctions to be set", alertsData.auctionToBeSet,"admin/listRFP","EOI Closed")}
      </Col>
    </Row>
  );
}

export default LiveAlerts;

const _fetchRFpLessThen10EOiAPI = () => {
  return axios.get("/api/RFP/rfpCount", {
    params: {
      q: "eoi_less_than",
      p: "10",
    },
  });
};
// const _getInvalidAuctions = () => {};
const _fetchPendingRFPApi = () => {
  return axios.get("/api/RFP/rfpCount", {
    params: {
      q: "count_by_status",
    },
  });
};
//api for fetching EOI count
const _fetchEoiData = () => {
  return axios.get("/api/EOI/eoiCount", {
    params: {
      q: "count_by_status",
    },
  });
};

//api for fetching queries
const _fetchQuery = () => {
  return axios.get("/api/admin/query");
};
