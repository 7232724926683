import React, { useEffect, useState, useContext, useRef } from "react";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import socketIOClient from "socket.io-client";
import axios from "axios";
import GlobalState from "../../utils/UserDetailContext";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import BonoloField from "../../BonoloField";
import { Button, Row, Col, InputGroup, Form } from "react-bootstrap";
import { UserRank, AuctionDate, RankTable } from "./AuctionComponent";
import configFile from "../../../config";
import { UserContext } from "../../utils/UserContext";
import { Loader } from "../../components/toast/Loader";
// import TimeOver from "./designs/TimeOver";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
// import AuctionExtend from "./designs/AuctionExtend"
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
// import BootstrapTable from 'react-bootstrap-table-next';
/**
 * socket url for local , test and prod
 */
const ENDPOINT = configFile.ENDPOINT;
const SNACKBAR_AUTO_HIDE = 30000;
//const ENDPOINT = 'https://bonolo-test-app.arachnomesh.com/socket';
//const ENDPOINT = 'http://127.0.0.1:4005/socket';
// console.log(ENDPOINT);
const BidSchema = Yup.object().shape({
  bid_value: Yup.number()
    .min(1, "Amount cannot be zero.")
    .typeError("Must be only digits.")
    .required("Amount is required."),
});
const initialValues = {
  bid_value: "",
};

// Status Options
const AUCTION_COMPLETED_VALID = "Auction Complete Valid";
const AUCTION_COMPLETED_INVALID = "Auction completed invalid";

let AUCTION_SET_TO_COMPLETED_VALID = false;

const timelineColour = ["#011021", "#b5091a"];
function LiveAuction(props) {
  const history = useHistory();
  const [state, setState] = useContext(GlobalState); // eslint-disable-line
  const [auctionStatus, setAuctionStatus] = useState(-1);
  const [bidAmount, setBidAmount] = useState("0");
  const [l1BidAmount, setL1BidAmount] = useState("-");
  const [auctionExtended, setAuctionExtended] = useState(false);
  const [socket, setSocket] = useState(); // eslint-disable-line
  const [status, setStatus] = useState(socket);
  const [rfpDeatils, setRFPDetails] = useState();
  const { user } = useContext(UserContext);
  const userObj = user.get() || "";
  const [userType, setUserType] = useState(); // eslint-disable-line
  const [remainingMilliSeconds, setRemainingMilliSeconds] = useState({
    time: "",
    unit: "",
  }); // eslint-disable-line
  const [isBidData, setIsBidData] = useState(false);
  const [invalidBidMessage, setInvalidBidMessage] = useState("Invalid Bid");
  const [pageLoading, setPageLoading] = useState(true);
  const [auctionTimeLine, setAuctionTimeline] = useState({
    fiveMinutesLeft: false,
    thirtySecondsLeft: false,
    intervalSet: false,
    timeLineColorIndex: 0,
  });
  const [organizationName, setOrganizationName] = useState("");
  const [rankDisplay, setRankDisplay] = useState("");
  // const [auctionEnded,setAuctionEnded] = useState(false);
  const auctionEnded = useRef(false);
  const rfpData = useRef({});
  const currentRemainingTime = useRef(Infinity);
  const [hasRecievedTime, sethasRecievedTime] = useState(false);

  const [isDisconnected, setisDisconnected] = useState(false);
  const [haveServerOutage, sethaveServerOutage] = useState(false);

  let location = useLocation();

  let newDate = new Date(); // eslint-disable-line
  const submitHandler = (values, { setStatus, setSubmitting }) => {
    if (socket && props.match.params.id) {
      let temp = {};
      temp["socketId"] = socket.id;
      temp["auctionId"] = props.match.params.id;
      socket.emit("clientData", temp); // send rfpId and client Id to backend
      let temp2 = {};
      temp2["socketId"] = socket.id;
      temp2["userId"] = userObj.id;
      temp2["rfpId"] = props.match.params.id;
      socket.emit("clientRank", temp2); // send userId and rfpId and client id i.e; socket id
      if (userObj.type === "Admin") {
        let temp3 = {};
        temp3["rfpId"] = props.match.params.id;
        temp3["socketId"] = socket.id;
        socket.emit("rankTable", temp3);
      }
    }
    //console.log(values.bid_value);
    axios
      .post(`/api/auction/saveBid`, {
        price: parseInt(values.bid_value),
        rfp: parseInt(props.match.params.id),
      })
      .then((res) => {
        //  console.log(res.data);
        if (res.data.bid) {
          setBidAmount(res.data.bid);
          setInvalidBidMessage("");
          // setBidAmount(res.data.rank);
          // setAuctionStatus(1);
        } else {
          let bidDataChanging = res.data.success.toString();
          setIsBidData(bidDataChanging);
          setInvalidBidMessage(res.data.error_msg);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    console.log("check socket connection", status);
    let socketCon = socketIOClient(
      `${ENDPOINT}?rfp=${props.match.params.id}&user=${userObj.id}`,
      { transports: ["websocket"] }
    );
    //setSocket(temp);
    console.log("debug", socketCon);
    /**
     * check socket connetion
     */
    if (socketCon && props.match.params.id) {
      socketCon.on("connect", function() {
        setStatus(true);
        let temp = {};
        temp["socketId"] = socketCon.id;
        temp["auctionId"] = props.match.params.id;
        setTimeout(() => {
          socketCon.emit("clientData", temp); // send rfpId and client Id to backend
        }, 500);

        let temp2 = {};
        temp2["socketId"] = socketCon.id;
        temp2["userId"] = userObj.id;
        temp2["rfpId"] = props.match.params.id;
        setTimeout(() => {
          socketCon.emit("clientRank", temp2); // send userId and rfpId and client id i.e; socket id
        }, 500);

        if (userObj.type === "Admin") {
          let temp3 = {};
          temp3["rfpId"] = props.match.params.id;
          temp3["socketId"] = socketCon.id;
          setTimeout(() => {
            socketCon.emit("rankTable", temp3);
          }, 500);
        }

        socketCon.on("dateAndRFPId", ({ remainingMilliSeconds }) =>
          pendingAucionTime(remainingMilliSeconds)
        );
        socketCon.on("getRankAndAuctionStatus", ({ user, l1bid }) => {
          setRankDisplay(user);
          setL1BidAmount(l1bid);
        });

        sethasRecievedTime(true);
      });
      socketCon.on("disconnect", (message) => {
        console.log("change status if scoket disconnected");
        setStatus(false); // change status if scoket disconnected
      });
      setSocket(socketCon);
    }
    const getAuctionData = async () => {
      _getAuctionData(props.match.params.id, (err, res) => {
        if (!err) {
          const momentStartTime = moment(res.data.response[0].startTime);
          const momentEndTime = moment(res.data.response[0].endTime);
          let isAuctionLive =
            momentStartTime.isBefore(moment()) &&
            momentEndTime.isAfter(moment());
          if (isAuctionLive) {
            _updateRfpQuery(props.match.params.id, 5);
          }
        }
      });
      const validateRFP = await axios.get(
        `/api/auction/validateAuctionByUser?rfpId=${props.match.params.id}`
      );
      setUserType(validateRFP.data.response);
      console.log(validateRFP.data.response);
      if (validateRFP.data.success) {
        const res = await axios.get(
          `/api/rfp/getRFPWithBid?id=${props.match.params.id}`
        );
        // setRankDisplay(res.data.response.rank);
        setAuctionStatus(res.data.response.auctionStatus);
        setRFPDetails(res.data.response);
        rfpData.current = res.data.response;
        const { organizationId } = res.data.response;
        const organizationResponse = await axios.get(
          `/api/organization/getOrganization?id=${organizationId}`
        );
        setOrganizationName(organizationResponse.data.response.name);
        setBidAmount(res.data.response.bid);
        setL1BidAmount(res.data.response.l1bid);
        setPageLoading(false);
      } else {
        setPageLoading(false);
        history.push("/dashboard");
      }
    };
    getAuctionData();
    return () => {
      if (socketCon) {
        socketCon.emit("stop", `${props.match.params.id}_${userObj.id}`);
      }
      //closeSocket(); // close socket when component destroyed
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const pendingAucionTime = (remainingMilliSeconds) => {
    let unit = "";
    if (remainingMilliSeconds > currentRemainingTime.current) {
      setAuctionExtended(true);
    }
    currentRemainingTime.current = remainingMilliSeconds;
    if (remainingMilliSeconds < 0) {
      if (auctionEnded.current === false) {
        auctionEnded.current = true;
      }
      remainingMilliSeconds = -1 * remainingMilliSeconds;
      unit = " ago";
    }
    if (remainingMilliSeconds > 3600 * 24 * 1000) {
      auctionEnded.current ? (unit = "days" + unit) : (unit = "days");
      remainingMilliSeconds = remainingMilliSeconds / (3600 * 24 * 1000);
      let intergerValue = Math.floor(remainingMilliSeconds);
      let decimalPart = remainingMilliSeconds % 1;
      decimalPart = (decimalPart / 100) * 24;
      remainingMilliSeconds = (intergerValue + decimalPart).toFixed(2);
    } else if (remainingMilliSeconds > 3600 * 1000) {
      auctionEnded.current ? (unit = "hour" + unit) : (unit = "hour");
      remainingMilliSeconds = remainingMilliSeconds / (3600 * 1000);
      let intergerValue = Math.floor(remainingMilliSeconds);
      let decimalPart = remainingMilliSeconds % 1;
      decimalPart = (decimalPart / 100) * 60;
      remainingMilliSeconds = (intergerValue + decimalPart).toFixed(2);
    } else if (remainingMilliSeconds > 60 * 1000) {
      auctionEnded.current ? (unit = "min" + unit) : (unit = "min");
      remainingMilliSeconds = remainingMilliSeconds / (60 * 1000);
      let intergerValue = Math.floor(remainingMilliSeconds);
      let decimalPart = remainingMilliSeconds % 1;
      decimalPart = (decimalPart / 100) * 60;
      remainingMilliSeconds = (intergerValue + decimalPart).toFixed(2);
      if (
        !auctionTimeLine.fiveMinutesLeft &&
        remainingMilliSeconds <= 5 &&
        auctionEnded.current === false
      ) {
        setAuctionTimeline({
          ...auctionTimeLine,
          fiveMinutesLeft: true,
          timeLineColorIndex: 1,
        });
      }
    } else {
      remainingMilliSeconds = Math.round(
        (remainingMilliSeconds + Number.EPSILON) / 1000
      );
      if (remainingMilliSeconds > 30 && auctionEnded.current === false) {
        setAuctionTimeline((auctionTimeLine) => ({
          fiveMinutesLeft: true,
          thirtySecondsLeft: auctionTimeLine.thirtySecondsLeft,
          timeLineColorIndex: 1,
          intervalSet: false,
        }));
      }
      if (
        remainingMilliSeconds <= 30 &&
        auctionEnded.current === false &&
        auctionTimeLine.intervalSet === false
      ) {
        setAuctionTimeline((auctionTimeLine) => ({
          fiveMinutesLeft: auctionTimeLine.fiveMinutesLeft,
          thirtySecondsLeft: auctionTimeLine.thirtySecondsLeft,
          intervalSet: true,
          timeLineColorIndex: 0,
        }));
        changeTimeLineColor();
      } else if (remainingMilliSeconds > 0 && auctionEnded.current === true) {
        setAuctionTimeline({
          fiveMinutesLeft: false,
          thirtySecondsLeft: false,
          intervalSet: true,
          timeLineColorIndex: 0,
        });
        // clearInterval(setTimelineBlinkInterval);
      }

      auctionEnded.current ? (unit = "sec" + unit) : (unit = "sec");
    }

    setRemainingMilliSeconds({ time: remainingMilliSeconds, unit: unit });
    if (hasRecievedTime === false) {
      sethasRecievedTime(true);
    }
  };

  const changeTimeLineColor = () => {
    let count = 1;
    setInterval(async () => {
      await setAuctionTimeline({
        ...auctionTimeLine,
        timeLineColorIndex: count % 2,
      });
      count++;
    }, 1000);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      setAuctionExtended(false);
      return;
    }
    setAuctionExtended(false);
  };

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      axios({
        method: "get",
        url: "/api/ping",
        mode: "no-cors",
        timeout: 5000,
      })
        .then((res) => {
          sethaveServerOutage(false);
          setisDisconnected(false);
        })
        .catch((err) => {
          axios({
            method: "get",
            url: "https://randomuser.me/api/?results=1&inc=gender",
            mode: "no-cors",
            timeout: 5000,
          })
            .then((res) => {
              sethaveServerOutage(true);
            })
            .catch(() => {
              sethaveServerOutage(false);
              setisDisconnected(true);
            });
        });

      return;
    }
    return setisDisconnected(true);
  };

  useEffect(() => {
    const webPing = setInterval(() => {
      handleConnectionChange();
    }, 5000);
    return () => {
      clearInterval(webPing);
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, []);

  window.addEventListener("online", handleConnectionChange);
  window.addEventListener("offline", handleConnectionChange);

  if (pageLoading || hasRecievedTime === false) {
    return <Loader />;
  } else {
    if (isDisconnected === false && haveServerOutage === false) {
      if (
        rfpDeatils &&
        socket &&
        status &&
        userObj.type === "User" &&
        userType !== "Buyer" &&
        auctionEnded.current === false
      ) {
        return (
          <>
            <Snackbar
              open={auctionExtended}
              autoHideDuration={SNACKBAR_AUTO_HIDE}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert onClose={handleCloseSnackbar} severity="info">
                auction extended by 5 minutes
              </Alert>
            </Snackbar>

            <div className="vu-box">
              <i className="fas fa-check fa-2x vu-icon"></i>
              <span className="vu-text">
                {auctionStatus === 0 ? "Valid Auction" : "Invalid Auction"}
              </span>
            </div>
            <Row>
              <Col className="product-info">
                <div className="product-content">
                  <AuctionDate
                    socket={socket}
                    props={props}
                    socketStatus={status}
                    setRemainingTime={(value) => {
                      // setRemainingMilliSeconds(value)
                    }}
                  />
                  <p className="liveAuction__orgName">
                    organization Name: {organizationName}
                  </p>
                  <p className="liveAuction__productTitle">
                    {rfpDeatils.title}
                  </p>
                  <p className="liveAuction__productTnc">
                    {rfpDeatils.terms_conditions}{" "}
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="product-main">
              <Col
                md={{ order: 1, span: 4 }}
                xs={{ order: 2, span: 12 }}
                className="product-main-col1 text-sm-center pr-3"
              >
                <div className="padding-right-auction">
                <h4>My Bid</h4>
                {bidAmount
                  ? <h1 className="ml-5">
                      <span className="font-roboto">&#8377;</span>
                      {bidAmount}
                    </h1>
                  : "-"
                }
                </div>

                <div>
                <h4>L1 Bid</h4>
                {l1BidAmount
                  ? <h1 className="ml-5">
                      <span className="font-roboto">&#8377;</span>
                      {l1BidAmount}
                    </h1>
                  : "-"
                }
                </div>
              </Col>
              <UserRank
                socket={socket}
                props={props}
                socketStatus={status}
                isVendor="1"
                // rank={rankDisplay}
                onChangeAuction={(value) => {
                  if (value !== auctionStatus) setAuctionStatus(value);
                }}
                onChangeL1Bid={(value) => {
                  setL1BidAmount(value);
                }}
              />
              <Col
                md={{ order: 3, span: 4 }}
                xs={{ order: 3, span: 12 }}
                className="product-main-col3"
              >
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={BidSchema}
                  onSubmit={submitHandler}
                >
                  {(formik) => (
                    <Form onSubmit={formik.handleSubmit} className="">
                      {/* <label for="bid_value">Next Bid</label><br className="" /> */}
                      <Row>
                        <Col className="pl-3">
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                <span className="font-roboto">&#8377;</span>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <BonoloField
                              name="bid_value"
                              id="bid_value"
                              formik={formik}
                            />
                            <InputGroup.Append>
                              <Button
                                variant="primary"
                                name="submit"
                                type="submit"
                              >
                                Submit
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                          <ErrorMessage name="bid_value">
                            {(msg) => <div className="text-danger">{msg}</div>}
                          </ErrorMessage>
                          <div className="text-danger">
                            {isBidData && <p>{invalidBidMessage}</p>}
                          </div>

                          <p style={{ fontSize: 15 }}>
                            Minimum bid decrement Value:{" "}
                            {rfpDeatils.bid_decrement_value}
                          </p>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
            <Row className="product-count-down dw">
              <Col className="product-count-down-inner">
                <div
                  className="product-count-down-box"
                  style={{
                    backgroundColor:
                      timelineColour[auctionTimeLine.timeLineColorIndex],
                  }}
                >
                  {/* <i className="fas fa-stopwatch fa-1x"></i><span>{(status)moment({}).seconds(remainingMilliSeconds/1000).format("H:mm:ss") ? 'Online' : 'Offline'}</span> */}
                  <i className="fas fa-stopwatch fa-1x"></i>
                  <span>
                    {!auctionEnded.current
                      ? remainingMilliSeconds.time +
                        " " +
                        remainingMilliSeconds.unit
                      : "Offline"}
                  </span>
                </div>
              </Col>
            </Row>
          </>
        );
      } else if (
        userObj.type === "Admin" &&
        userType !== "Buyer" &&
        rfpDeatils &&
        socket &&
        status &&
        auctionEnded.current === false
      ) {
        return (
          <>
            <Row xs={12} sm={12} md={12} lg={12} xl={12}>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <div className="vu-box">
                  <i className="fas fa-check fa-2x vu-icon"></i>
                  <span className="vu-text">
                    {auctionStatus === 0 ? "Valid Auction" : "Invalid Auction"}
                  </span>
                </div>
              </Col>
              <Col></Col>
              <Col>
                <Snackbar
                  open={auctionExtended}
                  autoHideDuration={SNACKBAR_AUTO_HIDE}
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <Alert onClose={handleCloseSnackbar} severity="info">
                    auction extended by 5 minutes
                  </Alert>
                </Snackbar>
              </Col>
            </Row>
            <Row>
              <Col className="product-info">
                <div className="product-content">
                  <AuctionDate
                    socket={socket}
                    props={props}
                    socketStatus={status}
                    setRemainingTime={(value) => {
                      // setRemainingMilliSeconds(value)
                    }}
                  />
                  <p className="liveAuction__orgName">
                    Organization Name: {organizationName}
                  </p>
                  <p className="liveAuction__productTitle">
                    {rfpDeatils.title}
                  </p>
                  <p className="liveAuction__productTnc">
                    {rfpDeatils.terms_conditions}{" "}
                  </p>
                </div>
              </Col>
            </Row>
            <Row xs={12} sm={12} md={12} lg={12} xl={12}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <UserRank
                  socket={socket}
                  props={props}
                  socketStatus={status}
                  isVendor="0"
                  // rank={rankDisplay}
                  onChangeAuction={(value) => {
                    if (value !== auctionStatus) setAuctionStatus(value);
                  }}
                  onChangeL1Bid={(value) => {
                    setL1BidAmount(value);
                  }}
                />
                <RankTable // For Admin
                  rfpId={props.match.params.id}
                  socket={socket}
                  socketStatus={status}
                ></RankTable>
              </Col>
            </Row>
            <Row
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="product-count-down "
            >
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="product-count-down-inner"
              >
                <div
                  className="product-count-down-box"
                  style={{
                    backgroundColor:
                      timelineColour[auctionTimeLine.timeLineColorIndex],
                  }}
                >
                  <i className="fas fa-stopwatch fa-1x"></i>
                  <span>
                    {status
                      ? remainingMilliSeconds.time +
                        " " +
                        remainingMilliSeconds.unit
                      : "Offline"}
                  </span>
                </div>
              </Col>
            </Row>
          </>
        );
      } else if (
        rfpDeatils &&
        socket &&
        status &&
        userType === "Buyer" &&
        remainingMilliSeconds.time > 0
      ) {
        if (auctionEnded.current === false) {
          return (
            <>
              <Row xs={12} sm={12} md={12} lg={12} xl={12}>
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <div className="vu-box">
                    <i className="fas fa-check fa-2x vu-icon"></i>
                    <span className="vu-text">
                      {auctionStatus === 0
                        ? "Valid Auction"
                        : "Invalid Auction"}
                    </span>
                  </div>
                </Col>
                <Col></Col>
                <Col>
                  <Snackbar
                    open={auctionExtended}
                    autoHideDuration={SNACKBAR_AUTO_HIDE}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Alert onClose={handleCloseSnackbar} severity="info">
                      auction extended by 5 minutes
                    </Alert>
                  </Snackbar>
                </Col>
              </Row>
              <Row>
                <Col className="product-info">
                  <div className="product-content">
                    <AuctionDate
                      socket={socket}
                      props={props}
                      socketStatus={status}
                      setRemainingTime={(value) => {
                        // setRemainingMilliSeconds(value)
                      }}
                    />
                    <p className="liveAuction__orgName">
                      organization Name: {organizationName}
                    </p>
                    <p className="liveAuction__productTitle">
                      {rfpDeatils.title}
                    </p>
                    <p className="liveAuction__productTnc">
                      {rfpDeatils.terms_conditions}{" "}
                    </p>
                  </div>
                </Col>
              </Row>
              <UserRank
                socket={socket}
                props={props}
                socketStatus={status}
                isVendor="0"
                // rank={rankDisplay}
                onChangeAuction={(value) => {
                  if (value !== auctionStatus) setAuctionStatus(value);
                }}
                onChangeL1Bid={(value) => {
                  setL1BidAmount(value);
                }}
              />

              <Row
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="product-count-down "
              >
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="product-count-down-inner"
                >
                  <div
                    className="product-count-down-box"
                    style={{
                      backgroundColor:
                        timelineColour[auctionTimeLine.timeLineColorIndex],
                    }}
                  >
                    <i className="fas fa-stopwatch fa-1x"></i>
                    <span>
                      {status
                        ? remainingMilliSeconds.time +
                          " " +
                          remainingMilliSeconds.unit
                        : "Offline"}
                    </span>
                  </div>
                </Col>
              </Row>
            </>
          );
        } else {
          return (
            <>
              <Row className="RankComp">
                <Col
                  xs="12"
                  className="d-flex flex-column justify-content-center align-items-center"
                >
                  {rfpDeatils.status === "Auction Complete Valid" && (
                    <div className="liveAuctionContent">
                      <img
                        src="/media/custom/correctsign.png"
                        alt="correct sign"
                        className="pb-5"
                      />
                      <h3>Congratulations!</h3>
                      <p>
                        Your hurdle rate was crossed <br />
                        The Bonolo team will contact you soon for the process
                        ahead. We look forward to you for completing more
                        transactions on Bonolo.
                      </p>
                    </div>
                  )}

                  {rfpDeatils.status === "Auction completed invalid" && (
                    <div className="liveAuctionContent">
                      <img
                        src="/media/custom/wrongsign.png"
                        alt="correct sign"
                        className="pb-5"
                      />
                      <h3>Bad Luck!</h3>
                      <p>
                        Your hurdle rate was not crossed <br />
                        Unfortunately, your Hurdle rate was not crossed. The
                        Bonolo team will contact you soon for the refund of your
                        Earnest money. We look forward to you for completing
                        more transactions on Bonolo.
                      </p>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="product-count-down dw">
                <Col className="product-count-down-inner">
                  <div className="product-count-down-box">
                    {/* <i className="fas fa-stopwatch fa-1x"></i><span>{(status)moment({}).seconds(remainingMilliSeconds/1000).format("H:mm:ss") ? 'Online' : 'Offline'}</span> */}
                    <i className="fas fa-stopwatch fa-1x"></i>
                    <span>Time Over</span>
                  </div>
                </Col>
              </Row>
            </>
          );
        }
      } else if (userObj.type === "Admin") {
        if (AUCTION_SET_TO_COMPLETED_VALID === false) {
          if (location.source !== "notificationPanel") {
            _updateRfpQuery(props.match.params.id, auctionStatus);
            AUCTION_SET_TO_COMPLETED_VALID = true;
          }
        }
        return (
          <>
            <Row xs={12} sm={12} md={12} lg={12} xl={12}>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <div className="vu-box">
                  <i className="fas fa-check fa-2x vu-icon"></i>
                  <span className="vu-text">
                    {auctionStatus === 0 ? "Valid Auction" : "Invalid Auction"}
                  </span>
                </div>
              </Col>
              <Col></Col>
              <Col>
                <Snackbar
                  open={auctionExtended}
                  autoHideDuration={SNACKBAR_AUTO_HIDE}
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <Alert onClose={handleCloseSnackbar} severity="info">
                    auction extended by 5 minutes
                  </Alert>
                </Snackbar>
              </Col>
            </Row>
            <Row>
              <Col className="product-info">
                <div className="product-content">
                  <AuctionDate
                    socket={socket}
                    props={props}
                    socketStatus={status}
                    setRemainingTime={(value) => {
                      // setRemainingMilliSeconds(value)
                    }}
                  />
                  <p className="liveAuction__orgName">
                    organization Name: {organizationName}
                  </p>
                  <p className="liveAuction__productTitle">
                    {rfpDeatils.title}
                  </p>
                  <p className="liveAuction__productTnc">
                    {rfpDeatils.terms_conditions}
                  </p>
                </div>
              </Col>
            </Row>
            <Row xs={12} sm={12} md={12} lg={12} xl={12}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <UserRank
                  socket={socket}
                  props={props}
                  socketStatus={status}
                  isVendor="0"
                  // rank={rankDisplay}
                  onChangeAuction={(value) => {
                    if (value !== auctionStatus) setAuctionStatus(value);
                  }}
                  onChangeL1Bid={(value) => {
                    setL1BidAmount(value);
                  }}
                />
                <RankTable
                  rfpId={props.match.params.id}
                  socket={socket}
                  socketStatus={status}
                ></RankTable>
              </Col>
            </Row>
            <Row className="product-count-down dw">
              <Col className="product-count-down-inner">
                <div className="product-count-down-box">
                  {/* <i className="fas fa-stopwatch fa-1x"></i><span>{(status)moment({}).seconds(remainingMilliSeconds/1000).format("H:mm:ss") ? 'Online' : 'Offline'}</span> */}
                  <i className="fas fa-stopwatch fa-1x"></i>
                  <span>Offline</span>
                </div>
              </Col>
            </Row>
          </>
        );
      } else if (auctionEnded.current === true) {
        if (rankDisplay === "L1") {
          return (
            <>
              <Snackbar
                open={auctionExtended}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <Alert onClose={handleCloseSnackbar} severity="info">
                  auction extended by 5 minutes
                </Alert>
              </Snackbar>
              <Row className="RankComp OneRankComp">
                <Col
                  xs="12"
                  className="d-flex flex-column justify-content-center align-items-center"
                >
                  <h2>My Rank</h2>
                  <div className="circle">
                    <h1>L1</h1>
                  </div>
                  <div className="liveAuctionContent">
                    <h3>Congratulations!</h3>
                    <p>
                      Your Bid is placed at L1. Please wait till the Bonolo team
                      contacts you for the process ahead. We look forward to you
                      for participating in more purchases on Bonolo.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="product-count-down dw">
                <Col className="product-count-down-inner">
                  <div className="product-count-down-box">
                    {/* <i className="fas fa-stopwatch fa-1x"></i><span>{(status)moment({}).seconds(remainingMilliSeconds/1000).format("H:mm:ss") ? 'Online' : 'Offline'}</span> */}
                    <i className="fas fa-stopwatch fa-1x"></i>
                    <span>Time Over</span>
                  </div>
                </Col>
              </Row>
            </>
          );
        } else if (rankDisplay === "L2") {
          return (
            <>
              <Row className="RankComp NoRankComp">
                <Col
                  xs="12"
                  className="d-flex flex-column justify-content-center align-items-center"
                >
                  <h2>My Rank</h2>
                  <div className="circle">
                    <h1>L2</h1>
                  </div>
                  <div className="liveAuctionContent">
                    <h3>THANK YOU FOR PARTICIPATING...</h3>
                    <p>
                      Thank you for participating. Please wait till the Bonolo
                      team contacts you for the refund of your Earnest money. We
                      look forward to you for participating in more purchases on
                      Bonolo.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="product-count-down dw">
                <Col className="product-count-down-inner">
                  <div className="product-count-down-box">
                    {/* <i className="fas fa-stopwatch fa-1x"></i><span>{(status)moment({}).seconds(remainingMilliSeconds/1000).format("H:mm:ss") ? 'Online' : 'Offline'}</span> */}
                    <i className="fas fa-stopwatch fa-1x"></i>
                    <span>Time Over</span>
                  </div>
                </Col>
              </Row>
            </>
          );
        } else if (rankDisplay === "L3") {
          return (
            <>
              <Row className="RankComp NoRankComp">
                <Col
                  xs="12"
                  className="d-flex flex-column justify-content-center align-items-center"
                >
                  <h2>My Rank</h2>
                  <div className="circle">
                    <h1>L3</h1>
                  </div>
                  <div className="liveAuctionContent">
                    <h3>THANK YOU FOR PARTICIPATING...</h3>
                    <p>
                      Thank you for participating. Please wait till the Bonolo
                      team contacts you for the refund of your Earnest money. We
                      look forward to you for participating in more purchases on
                      Bonolo.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="product-count-down dw">
                <Col className="product-count-down-inner">
                  <div className="product-count-down-box">
                    {/* <i className="fas fa-stopwatch fa-1x"></i><span>{(status)moment({}).seconds(remainingMilliSeconds/1000).format("H:mm:ss") ? 'Online' : 'Offline'}</span> */}
                    <i className="fas fa-stopwatch fa-1x"></i>
                    <span>Time Over</span>
                  </div>
                </Col>
              </Row>
            </>
          );
        } else {
          return (
            <>
              <Row className="RankComp NoRankComp">
                <Col
                  xs="12"
                  className="d-flex flex-column justify-content-center align-items-center"
                >
                  <h2>My Rank</h2>
                  <div className="circle circleRed">
                    <img src="/media/custom/crosssign.png" alt="alt sign" />
                  </div>
                  <div className="liveAuctionContent">
                    <h3>THANK YOU FOR PARTICIPATING...</h3>
                    <p>
                      Thank you for participating. Please wait till the Bonolo
                      team contacts you for the refund of your Earnest money. We
                      look forward to you for participating in more purchases on
                      Bonolo.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="product-count-down dw">
                <Col className="product-count-down-inner">
                  <div className="product-count-down-box">
                    {/* <i className="fas fa-stopwatch fa-1x"></i><span>{(status)moment({}).seconds(remainingMilliSeconds/1000).format("H:mm:ss") ? 'Online' : 'Offline'}</span> */}
                    <i className="fas fa-stopwatch fa-1x"></i>
                    <span>Time Over</span>
                  </div>
                </Col>
              </Row>
            </>
          );
        }
      } else if (
        userType === "Buyer"
      ) {
        return <Loader />;
      } else {
        return (
          <Row>
            <Col className="d-flex justify-content-center align-items-center flex-column">
              <img
                src={toAbsoluteUrl("/media/custom/connectionlost.png")}
                alt="conntection lost"
                className="connectionLost"
              />
              <div className="liveAuctionContent">
                <h3>Connection Lost</h3>
                <p>
                  There seems to be a problem with your internet connection.
                  Please check your network settings and then try again
                </p>
              </div>
              <NavLink className="btn btn-primary submit-btn" to="/">
                Go To Dashboard
              </NavLink>
            </Col>
          </Row>
        );
      }
    } else {
      return isDisconnected ? (
        <Row>
          <Col className="d-flex justify-content-center align-items-center flex-column">
            <img
              src={toAbsoluteUrl("/media/custom/connectionlost.png")}
              alt="conntection lost"
              className="connectionLost"
            />
            <div className="liveAuctionContent">
              <h3>Connection Lost</h3>
              <p>
                There seems to be a problem with your internet connection.
                Please check your network settings and then try again
              </p>
            </div>
            <NavLink className="btn btn-primary submit-btn" to="/">
              Go To Dashboard
            </NavLink>
          </Col>
        </Row>
      ) : (
        <div class="loading">
          <h1>500</h1>
          <h2>
            Internal Server Error <b>:(</b>
          </h2>
        </div>
      );
    }
  }
}

export default LiveAuction;

//api for updating rfp status
const _updateRfpQuery = async (rfpId, auctionStatus) => {
  let status = "";
  if (auctionStatus === 0) {
    status = AUCTION_COMPLETED_VALID;
  } else if (auctionStatus === 1) {
    status = AUCTION_COMPLETED_INVALID;
  } else {
    status = "Ongoing auction";
  }
  try {
    const res = await axios.post("/api/RFP/updateRFP", {
      id: rfpId,
      status: status,
    });

    return res;
  } catch (error) {
    return error;
  }
};

//api for getting auction
const _getAuctionData = async (rfpId, callback) => {
  let res = null;
  let err = null;
  try {
    res = await axios.get("/api/auction/getAuction", {
      params: {
        rfpId: rfpId,
        startTime: 1,
      },
    });
    callback(err, res);
  } catch (error) {
    err = error;
    callback(err, res);
  }
};
