import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsFillBarChartFill } from "react-icons/bs";
import { Link } from "react-router-dom";

import { Row, Col } from "react-bootstrap";

//initial state
const initialState = {
  activeUsers: 0,
  totalUsers: 0,
  totalOrganization: 0,
  unfilledOrganization: 0,
  totalQueries: 0,
  unReadQueries: 0,
};
function LiveStastics() {
  //use State section
  const [data, setdata] = useState(initialState);
  const [isSmallDevice, setisSmallDevice] = useState(
    window.innerWidth <= 1150 ? true : false
  );

  //useEffect section
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await Promise.all([
          _fetchUser(),
          _fetchOrganization(),
          _fetchQuery(),
        ]);
        _getUser(res[0].data);
        _getOrganization(res[1].data);
        _getQueries(res[2].data);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
    window.addEventListener("resize", updateOnResize);
    return () => {
      window.removeEventListener("resize", updateOnResize);
    };
  }, []);

  const updateOnResize = () => {
    if (window.innerWidth <= 1150) {
      setisSmallDevice(true);
    } else {
      setisSmallDevice(false);
    }
  };

  //function to generate user data from api response
  const _getUser = (data) => {
    let inActiveUser = 0;
    data.map((item) => {
      if (item.status === "Inactive") {
        inActiveUser = item.user_count;
      } else {
        setdata((previousState) => {
          return {
            ...previousState,
            totalUsers: parseInt(inActiveUser) + parseInt(item.user_count),
            activeUsers: item.user_count,
          };
        });
      }
      return null;
    });
  };

  //function to generate organization data from api response
  const _getOrganization = (data) => {
    
    setdata((previousState) => {
      return {
        ...previousState,
        totalOrganization: data.total_org,
        unfilledOrganization: data.unfilled_org,
      };
    });
  };
  //function to get queries from api response
  const _getQueries = (data) => {
    data.map((item) => {
      if (item.resolved_status === false) {
        setdata((previousState) => {
          return {
            ...previousState,
            unReadQueries:
              previousState.unReadQueries + parseInt(item.resolved_count),
          };
        });
      }
      setdata((previousState) => {
        return {
          ...previousState,
          totalQueries:
            previousState.totalQueries + parseInt(item.resolved_count),
        };
      });

      return null;
    });
  };

  // const _arrowIcon = (hasIncreased) => {
  //   if (hasIncreased) {
  //     return <BsArrowUp className="upArrow" />;
  //   } else {
  //     return <BsArrowDown className="downArrow" />;
  //   }
  // };
  const _stasticsCard = ({
    heading,
    dataLabel1,
    dataLabel2,
    data1,
    data2,
    route,
    routeStatus,
  }) => {
    return (
      <div className="stasticsCard">
        <p className="heading">{heading}</p>
        <div className="row">
          <Link
            to={{
              pathname: route,
              defaultSelect: undefined,
            }}
          >
            <p className="datalabel1">{dataLabel1}</p>
          </Link>
          <p className="data">{data1}</p>
        </div>
        <div className="row">
          <Link
            to={{
              pathname: route,
              defaultSelect: routeStatus,
            }}
          >
            <p className="datalabel2">{dataLabel2}</p>
          </Link>
          <p className="data">{data2}</p>
        </div>
      </div>
    );
  };
  return (
    <div className="liveStastics">
      {!isSmallDevice && (
        <div className="liveStastics__iconContainer">
          <BsFillBarChartFill className="icon" />
          <p>LIVE STATISTICS</p>
        </div>
      )}
      <Row className="liveStastics__cardContainer">
        <Col xs={12} sm={4} md={4} lg={4} xl={4}>
          {_stasticsCard({
            heading: "Users",
            dataLabel1: "TOTAL USERS",
            dataLabel2: "ACTIVE USERS",
            data1: data.totalUsers,
            data2: data.activeUsers,
            route: "/admin/userList",
            routeStatus: "Active",
          })}
        </Col>
        <Col xs={12} sm={4} md={4} lg={4} xl={4}>
          {_stasticsCard({
            heading: "Organisations",
            dataLabel1: "TOTAL ORGANISATIONS",
            dataLabel2: "UNFILLED DETAILS",
            data1: data.totalOrganization,
            data2: data.unfilledOrganization,
            route: "/admin/listOrganizations",
            routeStatus: "true",
          })}
        </Col>
        <Col xs={12} sm={4} md={4} lg={4} xl={4}>
          {_stasticsCard({
            heading: "Queries",
            dataLabel1: "TOTAL QUERIES",
            dataLabel2: "UNREAD QUERIES",
            data1: data.totalQueries,
            data2: data.unReadQueries,
            route: "/admin/userQueries",
            routeStatus: "false",
          })}
        </Col>
      </Row>
    </div>
  );
}

export default LiveStastics;

//api for fetching userCount
const _fetchUser = () => {
  return axios.get("/api/admin/userCount", {
    params: {
      q: "count_by_status",
    },
  });
};

//api for fetching organization count
const _fetchOrganization = () => {
  return axios.get("/api/organization/organizationCount", {
    params: {
      q: "count_by_status",
    },
  });
};
//api for fetching queries
const _fetchQuery = () => {
  return axios.get("/api/admin/query");
};
