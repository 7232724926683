import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register, gmailLogin } from "../_redux/authCrud";
import { registerFirebase } from "../_redux/authCrud";
import firebase from 'firebase';
import configFile from '../../../../config.js';
import 'bootstrap/dist/css/bootstrap.min.css';

const STATIC_WEB_URL = configFile.STATIC_WEB_URL;

const firebaseConfig = {
  apiKey: "AIzaSyD2hHK-fkCBqufSxayJyYAPFRxfkcZ7jZg",
  authDomain: "bengaws-23ec9.firebaseapp.com",
  projectId: "bengaws-23ec9",
  storageBucket: "bengaws-23ec9.appspot.com",
  messagingSenderId: "70873337552",
  appId: "1:70873337552:web:cf973b4a3bd851381058d5",
  measurementId: "G-6P1HXENF8S"
};

function Registration(props) {
  const login = { tupe: "OTP" };
  const initialValues = {
    name: "",
    email: "",
    mobile: "",
    companyName: "",
    acceptTerms: false,
  };
  if (props.location.state) {
    console.log(props.location.state.googleLogin);
    initialValues.mobile = props.location.state.number;
    initialValues.email = props.location.state.email;
    initialValues.name = props.location.state.name;
    login.type = props.location.state.googleLogin;
  }

  !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
  let auth = firebase.auth();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [mynumber, setnumber] = useState("");
  const [otp, setotp] = useState("");
  const [show, setshow] = useState(false);
  const [final, setfinal] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  useEffect(() => {
    // console.log(props.location.state.number);
    return () => new AbortController().abort();
  })
  const history = useHistory();
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Must be 3-50 characters")
      .max(50, "Must be 3-50 characters")
      .trim()
      .matches(/[a-zA-Z]+$/, "Invalid Name")
      .required("Please enter your name"),
    companyName: Yup.string()
      .min(3, "Must be 3-50 characters")
      .max(50, "Must be 3-50 characters")
      .required("Please enter your company name"),
    email: Yup.string()
      .email("Invalid email address")
      .min(3, "Must be 3-50 characters")
      .max(50, "Must be 3-50 characters")
      .required("Please enter your email address"),
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Invalid Mobile Number.")
      .typeError("Must be only digits")
      .min(10, 'Enter 10 digit numeric value')
      .max(10, 'Enter 10 digit numeric value')
      .required("Please enter your 10 digit mobile number"),
    acceptTerms: Yup.bool().oneOf([true], "You must accept the terms and conditions"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      if(login.type === "gmail"){
        console.log(initialValues.gmail);
        //initialValues.gmail;
        
        gmailLogin(values).then((res)=>{
          console.log(res);
          if(res.msg !== "User Register Successful"){
            setStatus(
              intl.formatMessage({
                id:res.msg,
              })
            );
          }else{
            console.log(res.response)
            if(res.response.token){
                console.log(res.response.token);
                localStorage.setItem('AUTH_TOKEN', res.response.token);
                window.location.reload();
                history.push('/dashboard');
            }
          }
        }).catch((err)=>{
          console.log(err);
        });
        console.log("gmillogin");
      }else{
        register(values.name, values.email, values.mobile, values.companyName)
        .then(async (data) => {
          if(data.success){
              // sessionStorage.setItem("OTP",JSON.stringify({"otpvalue":data.message}))
              sessionStorage.setItem("REGISTER",JSON.stringify(values));
              history.push('/auth/registration-otp');
              disableLoading();
          }else{
              setStatus(
                intl.formatMessage({
                  id:data.message,
                })
              );
              disableLoading();
          }
        }).catch((e) => {
          console.log("Failwed Res")
          console.log(e);
        })
      }
    },
  });

  // Sent OTP
  const sendOtp = () => {

    if (mynumber === "" || mynumber.length !== 10) {
      setErrorMsg("Invalid Mobile");
      setSuccessMsg("");
      return;
    }

    if (login.type === "gmail") {
      console.log(initialValues.gmail);

      gmailLogin({ name, email, mobile: mynumber, companyName }).then((res) => {
        console.log(res);
        if (res.msg !== "User Register Successful") {
          setErrorMsg(res.msg);
          setSuccessMsg("");
        } else {
          if (res.response.token) {
            console.log(res.response.token);
            localStorage.setItem('AUTH_TOKEN', res.response.token);
            window.location.reload();
            history.push('/dashboard');
          }
        }
      }).catch((err) => {
        console.log(err);
      });
    } else {
      register(name, email, mynumber, companyName)
        .then(async (data) => {
          if (data.success) {
            setErrorMsg("");

            let number = "+91" + mynumber;

            let verify = new firebase.auth.RecaptchaVerifier(
              "recaptcha-container"
            );
            auth.signInWithPhoneNumber(number, verify)
              .then((result) => {
                setfinal(result);
                setSuccessMsg("OTP sent");
                setErrorMsg("");
                setshow(true);
              })
              .catch((err) => {
                setErrorMsg(err.message);
                setSuccessMsg("");
              });
          } else {
            setErrorMsg(data.message);
            setSuccessMsg("");
            disableLoading();
          }
        }).catch((e) => {
          console.log("Failwed Res")
          console.log(e);
        })
    }
  };

  // Validate OTP
  const ValidateOtp = () => {
    if (otp === null || final === null) return;
    final
      .confirm(otp)
      .then((result) => {
        // success

        registerFirebase(name, email, mynumber, companyName)
          .then((data) => {
            if (data.status === "fail") {
              setErrorMsg(data.message);
              setSuccessMsg("");
              // history.push('/auth/registration');
            } else {
              if (data.response) {
                console.log(data.response.token);
                localStorage.setItem('AUTH_TOKEN', data.response.token);
                //disableLoading();
                window.location.reload();
                history.push('/dashboard');
              }
            }
          })
      })
      .catch((err) => {
        setErrorMsg("Wrong OTP");
        setSuccessMsg("");
      });
  };


  // return 

  return (
    <>
      <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
        <span className="font-weight-bold text-dark-50">Already have an account?</span>
        <Link to="/auth/login#" className="font-weight-bold ml-2" id="kt_login_signup">Sign In!</Link>
      </div>
      <div className="login-form login-signin" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.REGISTER.TITLE" />
          </h3>
          <p className="text-muted font-weight-bold">Enter your details to create your account</p>
        </div>

        {/* <form
          id="kt_login_signin_form"
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          onSubmit={formik.handleSubmit}
        > */}
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}
        {(login.type === "gmail") ? (
          <>
            {/* begin: Mobile */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Mobile"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "mobile"
                )}`}
                onChange={(e) => {
                  setnumber(e.target.value);
                }}
                name="mobile"
                {...formik.getFieldProps("mobile")}
              />
              {formik.touched.mobile && formik.errors.mobile ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.mobile}</div>
                </div>
              ) : null}
            </div>
            {/* end: Mobile */}

            {/* begin: Company Name*/}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Company Name"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "companyName"
                )}`}
                name="companyName"
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
                // {...formik.getFieldProps("companyName")}
              />
              {formik.touched.companyName && formik.errors.companyName ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.companyName}</div>
                </div>
              ) : null}
            </div>
            {/* end: Company Name */}
          </>
        ) : (
          <>
            {/* begin: name */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Name"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "name"
                )}`}
                name="name"
                autoFocus={true}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                // {...formik.getFieldProps("name")}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.name}</div>
                </div>
              ) : null}
            </div>
            {/* end: name */}

            {/* begin: email */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Email"
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                // {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            {/* end: email */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Mobile"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "mobile"
                )}`}
                onChange={(e) => {
                  setnumber(e.target.value);
                }}
                name="mobile"
              // {...formik.getFieldProps("mobile")}
              />
              {formik.touched.mobile && formik.errors.mobile ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.mobile}</div>
                </div>
              ) : null}
            </div>
            {/* end: Mobile */}

            {/* begin: Company Name*/}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Company Name"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "companyName"
                )}`}
                name="companyName"
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
                // {...formik.getFieldProps("companyName")}
              />
              {formik.touched.companyName && formik.errors.companyName ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.companyName}</div>
                </div>
              ) : null}
            </div>
            {/* end: Company Name */}
          </>
        )}

        <div
          style={{
            display: !show ? "block" : "none",
          }}
        >
          <div id="recaptcha-container"></div>
        </div>

        <div
          className="form-group fv-plugins-icon-container"
          style={{
            display: show ? "block" : "none",
          }}
        >
          <input
            placeholder="OTP"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "otp"
            )}`}
            name="otp"
            onChange={(e) => {
              setotp(e.target.value);
            }}
          // {...formik.getFieldProps("otp")}
          />
          {formik.touched.otp && formik.errors.otp ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.otp}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container"
        >
          {errorMsg ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{errorMsg}</div>
            </div>
          ) : null}

          {successMsg ? (
            <div className="fv-plugins-message-container">
              <div className="fv-success-block">{successMsg}</div>
            </div>
          ) : null}
        </div>

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <div className="input-checkbox-outer">
            <input
              type="checkbox"
              name="acceptTerms"
              className="input-checkbox"
              {...formik.getFieldProps("acceptTerms")}
            />
            <label className="checkbox">I accept <a href={`${STATIC_WEB_URL}/terms-conditions.html`}>terms and conditions</a></label>
          </div>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div
          className="form-group d-flex flex-wrap flex-center"
        >
          <div
            style={{
              display: !show ? "block" : "none",
            }}
          >
            <button
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              onClick={sendOtp}
            >
              <span>Submit</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
          <div
            style={{
              display: !show ? "block" : "none",
            }}
          >
            <Link to="/auth/login">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancel
              </button>
            </Link>
          </div>
        </div>

        <div
          className="form-group d-flex flex-wrap flex-center"
        >
          <div
            style={{
              display: show ? "block" : "none",
            }}
          >
            <a href={() => false} onClick={sendOtp}>
              <span>Resend OTP</span>
            </a>
          </div>

          <div
            style={{
              display: show ? "block" : "none",
            }}
          >
            <button
              // type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              onClick={ValidateOtp}
            >
              <span>Submit</span>
              {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}
            </button>
          </div>
          <div
            style={{
              display: show ? "block" : "none",
            }}
          >
            <Link to="/auth/login">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                Cancel
              </button>
            </Link>
          </div>
        </div>
        {/* </form> */}
      </div>
    </>
  );
}
export default injectIntl(connect(null, auth.actions)(Registration));
