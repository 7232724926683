import axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import { Alert, Modal, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";

// Custom imports
import { UserContext } from "../../utils/UserContext";
import { Loader } from "../../components/toast/Loader";
import ListOrganization from "../Organisation/ListOrganization";

const selectOptions = {
  "In Progress": "In Progress",
  "Review Pending": "Review pending",
  "Back For Edit": "Back for edit",
  "Payment Pending": "Payment pending",
  Published: "Published",
  "EOI Closed": "EOI closed",
  "Submit For Review": "Submit For Review",
  Canceled: "Canceled",
  "Auction set": "Auction Set",
  "Ongoing auction": "Ongoing auction",
  "Auction Complete Valid": "Auction Complete Valid",
  "Auction completed invalid": "Auction completed invalid",
};

export class ListRFP extends Component {
  static contextType = UserContext;

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    // binding of this to function
    this.setOrgId = this.setOrgId.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.GetActionFormat = this.GetActionFormat.bind(this);
    this.GetActionFormat3 = this.GetActionFormat3.bind(this);

    const { location } = this.props;

    if (window.location.href.split("/").includes("all")) {
      this.rfpFlag = 1;
    }
    this.state = {
      pageLoading: true,
      userType: "",
      userObj: {},
      rfpList: [],
      isPopoverOpen: false,
      selectedRFPId: "",
      selectedOrgId: "",
      adminColumns: [
        {
          dataField: "id",
          text: "ID",
          formatter: this.GetNullText,
          sort: true,
          hidden: true,
        },
        {
          dataField: "title",
          text: "Title",
          formatter: this.GetTitle,
          filter: textFilter({
            placeholder: "Search",
          }),
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              window.location.href = `/viewRFP/${row.id}`;
            },
          },
          sort: true,
          headerAlign: "center",
        },
        {
          dataField: "organization_name",
          text: "Organization",
          filter: textFilter({
            placeholder: "Search",
          }),
          formatter: this.GetOrganizationName,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              window.location.href = `/updateOrganisation/${row.organizationId}`;
            },
          },
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "product_name",
          text: "Product Name",
          filter: textFilter({
            placeholder: "Search",
          }),
          formatter: this.GetNullText,
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "hurdle_rate",
          text: "Hurdle Rate",
          filter: textFilter({
            placeholder: "Search",
          }),
          formatter: this.GetHurdleRate,
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "status",
          text: "Status",
          formatter: (cell) => selectOptions[cell],
          filter: selectFilter({
            options: selectOptions,
            placeholder: "RFP status",
            className: "my-custom-text-filter",
            caseSensitive: false,
            defaultValue:
              location.defaultSelect === undefined
                ? ""
                : location.defaultSelect,
            id: "id",
          }),
          sort: true,
          id: "dpra",
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "auction_start",
          text: "Auction Date",
          formatter: this.DateFormatter,
          filter: textFilter({
            placeholder: "Search",
          }),
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "",
          text: "Auction",
          formatter: this.GetActionFormat3,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "",
          text: "Create EOI",
          formatter: (cell, row, i) =>
            this.CreateEOIFormat(cell, row, i, this.handleClick),
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "isPublic",
          text: "Public Status",
          formatter: this._makePublicFormatter,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "",
          text: "Action",
          formatter: this.GetActionFormat,
          align: "center",
          headerAlign: "center",
        },
      ],
      userColumn: [
        {
          dataField: "id",
          text: "ID",
          formatter: this.GetNullText,
          sort: true,
          hidden: true,
        },
        {
          dataField: "title",
          text: "Title",
          formatter: this.GetTitle,
          // filter: textFilter(),
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              window.location.href = `/viewRFP/${row.id}`;
            },
          },
          sort: true,
        },
        {
          dataField: "organization_name",
          text: "Organization",
          // filter: textFilter(),
          formatter: this.GetOrganizationName,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              window.location.href = `/updateOrganisation/${row.organizationId}`;
            },
          },
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "product_name",
          text: "Product Name",
          // filter: textFilter(),
          formatter: this.GetNullText,
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "hurdle_rate",
          text: "Hurdle Rate",
          // filter: textFilter(),
          formatter: this.GetHurdleRate,
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "status",
          text: "Status",
          formatter: (cell) => selectOptions[cell],
          // filter: qualityFilter,
          sort: true,
          id: "dpra",
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "auction_start",
          text: "Auction Date",
          formatter: this.DateFormatter,

          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "",
          text: "Action",
          formatter: this.GetActionFormat,
          align: "center",
          headerAlign: "center",
        },
      ],
    };
  }

  // make rfp public to show on landing page
  _makePublicFormatter = (cell, row) => {
    if (row.isPublic === true && row.status === "Published") {
      return (
        <div>
          <Button
            type="button"
            size="sm"
            className="bRadius6"
            onClick={() => this._onClickPublicStatus(row)}
          >
            Remove Public
          </Button>
        </div>
      );
    } else if (row.status !== "Published") {
      return (
        <div>
          <Button type="button" size="sm" className="bRadius6" disabled={true}>
            Make Public
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <Button
            type="button"
            size="sm"
            className="bRadius6"
            onClick={() => this._onClickPublicStatus(row)}
          >
            Make Public
          </Button>
        </div>
      );
    }
  };

  _onClickPublicStatus = (row) => {
    console.log(row);
    const { id, status, isPublic } = row;
    if (status === "Published") {
      _updateRFPData(id, !isPublic, (err) => {
        if (err === null) {
          axios
            .get("/api/rfp/listRFP")
            .then((res) => {
              this.setState({
                rfpList: res.data.response,
                pageLoading: false,
              });
            })
            .catch(() => {
              this.setState({
                pageLoading: false,
              });
            });
        }
      });
    }
  };

  componentDidMount() {
    const { user } = this.context;
    const userObj = user.get();
    const { location } = this.props;

    this.setState({
      userObj: userObj,
    });
    if (location.rfpHavingLessEoi === undefined) {
      axios
        .get("/api/rfp/listRFP")
        .then((res) => {
          this.setState({
            rfpList: res.data.response,
            pageLoading: false,
          });
        })
        .catch(() => {
          this.setState({
            pageLoading: false,
          });
        });
    } else {
      _rfpWithLessThen10EOI((err, res) => {
        if (err === null) {
          this.setState({
            rfpList: res,
            pageLoading: false,
          });
        } else {
          this.setState({
            pageLoading: false,
          });
        }
      });
    }
  }

  GetActionFormat(cell, row) {
    return (
      <div>
        <Button
          type="button"
          size="sm"
          className="eoi-btn"
          href={"/editRFP/" + row.id}
        >
          Edit{" "}
          <i
            class="fas fa-pencil-alt"
            style={{ fontSize: "12px", marginLeft: "4px" }}
          ></i>
        </Button>
      </div>
    );
  }
  GetTitle(cell, row) {
    return <span className="linkFormat">{row.title}</span>;
  }
  GetOrganizationName(cell, row) {
    return <span className="linkFormat">{row.organization_name}</span>;
  }
  GetHurdleRate(cell, row) {
    return <span className="float-right">{row.hurdle_rate}</span>;
  }
  setOrgId(id) {
    this.setState({ selectedOrgId: id }, function() {
      if(this.state.selectedOrgId && this.state.selectedRFPId){
        window.location.href = `/admin/addEOI/${this.state.selectedOrgId}/${this.state.selectedRFPId}`;
      }
    });
  }

  // handle click of create EOI button
  // Open the select organization modal
  handleClick(id) {
    this.setState({ isPopoverOpen: true, selectedRFPId: id });
  }

  // Provide Element for create EOI column
  CreateEOIFormat(cell, row, i, handleClick) {
    return (
      <div>
        <Button
          type="button"
          size="sm"
          className="eoi-btn"
          onClick={() => handleClick(row.id)}
        >
          Create EOI
        </Button>
      </div>
    );
  }

  GetActionFormat3(cell, row, rowIndex) {
    let status = this.state.rfpList[rowIndex].status;
    return (
      <div>
        <Button
          disabled={
            !["published", "EOI Closed"].includes(status) &&
            this.state.userType !== "Admin"
          }
          type="button"
          className="eoi-btn"
          size="sm"
          href={"/addAuction/0/" + row.id}
        >
          Auction
        </Button>
      </div>
    );
  }

  DateFormatter(cell, row) {
    if (row[this.dataField]) {
      return moment(row[this.dataField]).format("lll");
    } else {
      return "To be decided";
    }
  }

  GetNullText(cell, row) {
    if (row[this.dataField]) {
      return row[this.dataField];
    } else {
      return "NA";
    }
  }

  setAddDiv(type) {
    if (type === "User") {
      return (
        <div style={{ display: "flex" }}>
          <Button className="eoi-btn eoi-add-btn" size="sm" href="/addRFP">
            ADD
          </Button>
        </div>
      );
    } else {
      return "";
    }
  }

  render() {
    console.log("length", this.state.rfpList.length);
    const options = {
      page: 1,
      sizePerPageList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "All",
          value: this.state.rfpList.length,
        },
      ],
      sizePerPage: 5,
      pageStartIndex: 1,
      paginationSize: 10,
      prePage: "Prev",
      nextPage: "Next",
      firstPage: "First",
      lastPage: "Last",
      paginationPosition: "bottom",
    };

    if (this.state.pageLoading) {
      return <Loader loading={this.state.pageLoading} />;
    } else {
      return (
        <>
          {this.setAddDiv(this.state.userObj.type)}
          {this.state.rfpList.length > 0 ? (
            <>
              <BootstrapTable
                hover
                id="listRfpTable"
                keyField="id"
                className="dd"
                data={this.state.rfpList}
                columns={
                  this.state.userObj.type === "Admin"
                    ? this.state.adminColumns
                    : this.state.userColumn
                }
                filter={filterFactory()}
                pagination={
                  this.state.rfpList.length > 5
                    ? paginationFactory(options)
                    : false
                }
              />
            </>
          ) : (
            <>
              <p>
                <Alert variant="success">
                  <Alert.Heading>RFP List</Alert.Heading>
                  <p>No RFP found.</p>
                </Alert>
              </p>
            </>
          )}
          <div>
            {/* This modal is use to select organization when Admin goes to add EOI for a RFP */}
            <Modal
              size="xl"
              show={this.state.isPopoverOpen}
              onHide={() =>
                this.setState({
                  isPopoverOpen: false,
                  selectedRFPId: "",
                  selectedOrgId: "",
                })
              }
            >
              <Modal.Header closeButton>
                <Modal.Title>Select Organisation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ListOrganization setIdToUpperComponent={this.setOrgId} />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() =>
                    this.setState({
                      isPopoverOpen: false,
                      selectedRFPId: "",
                      selectedOrgId: "",
                    })
                  }
                >
                  Close
                </Button>
                {/* <Button
                  variant="primary"
                  disabled={
                    !this.state.selectedOrgId || !this.state.selectedRFPId
                  }
                  href={`/admin/addEOI/${this.state.selectedOrgId}/${this.state.selectedRFPId}`}
                >
                  Next
                </Button> */}
              </Modal.Footer>
            </Modal>
          </div>
        </>
      );
    }
  }
}
export default withRouter(ListRFP);

const _updateRFPData = async (id, status, callback) => {
  try {
    const res = await axios.post("/api/RFP/updateRFP", {
      params: {
        queryType: "updatePublicStatus",
        id: id,
        isPublic: status,
      },
    });
    if (res.data.success === true) {
      callback(null);
    } else {
      throw new Error("failed updation");
    }
  } catch (err) {
    callback(err);
  }
};

const _rfpWithLessThen10EOI = async (callback) => {
  try {
    const res = await axios.get("/api/RFP/rfpHavingLessEoi");
    if (res.data.success) {
      callback(null, res.data.response);
    } else {
      throw new Error("failed geeting data");
    }
  } catch (err) {
    callback(err, null);
  }
};
