import React, { createContext } from 'react';
import axios from 'axios';
export const ME_URL = "/api/me";

export const UserContext = createContext({});

export function UserContextProvider({ children }) {
    let userGetter = getCurrentUser();
    return (
        <UserContext.Provider value={{ user: userGetter }}>
            {children}
        </UserContext.Provider>
    );
}

export function getCurrentUser() {
    let status = 'pending';
    let user = null;
    const token = localStorage.getItem("AUTH_TOKEN");
    let p = null;
    if (token != null) {
        p = axios.get(ME_URL)
            .then(
                res => {
                    status = 'loggedin';
                    user = res.data;
                },
                err => {
                    status = 'login_failed';
                    user = null;
                });
    }
    else {
        status = 'login_failed';
        user = null;
    }

   

    return {
        get() {
            if(!localStorage.getItem("AUTH_TOKEN")){
                return null;
            }else{
                if (status === 'pending') {
                    throw p;
                }
                else if (status === 'login_failed') {
                    return null;
                }
                else {
                    return user;
                }
            }
        }
    }
}

