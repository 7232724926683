import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { adminRegisterUser } from "../../modules/Auth/_redux/authCrud";
import { Row, Col } from 'react-bootstrap';


export function AddUser() {

    const initialValues = {
        name: "",
        email: "",
        mobile: "",
        companyName: "",
        acceptTerms: false,
    };

    const history = useHistory();
    const RegistrationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Name is required"),
        companyName: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Company name is required"),
        email: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Email is required"),
        mobile: Yup.string()
            .matches(/^[0-9]+$/, "Mobile number is not valid.")
            .min(10, 'Mobile number is not valid.')
            .max(10, 'Mobile number is not valid.')
            .required("Mobile is required"),
        acceptTerms: Yup.bool().required(
            "You must accept the terms and conditions"
        ),
    });


    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: RegistrationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            adminRegisterUser(values.name, values.email, values.mobile, values.companyName)
                .then(async (data) => {
                    if (data === 200) {
                        console.log(data)
                        setSubmitting(false);
                        history.push('/admin/userList');
                    }
                    else {
                        alert(data.message)
                    }
                }).catch((e) => {
                    console.log("Failwed Res")
                    console.log(e);
                })
        },
    });

    return (
        <div>
            <form
                id="kt_login_signin_form"
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                onSubmit={formik.handleSubmit}
            >
                {/* begin: Alert */}
                {formik.status && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
                )}
                {/* end: Alert */}

                <Row>
                    {/* begin: name */}
                    <Col>
                        <div className="form-group fv-plugins-icon-container">
                            <input
                                placeholder="Name"
                                type="text"
                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                    "name"
                                )}`}
                                name="name"
                                autoFocus={true}
                                {...formik.getFieldProps("name")}
                            />
                            {formik.touched.name && formik.errors.name ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.name}</div>
                                </div>
                            ) : null}
                        </div>
                    </Col>
                    {/* end: name */}

                    {/* begin: email */}
                    <Col>
                        <div className="form-group fv-plugins-icon-container">
                            <input
                                placeholder="Email"
                                type="email"
                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                    "email"
                                )}`}
                                name="email"
                                {...formik.getFieldProps("email")}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.email}</div>
                                </div>
                            ) : null}
                        </div>
                    </Col>
                </Row>
                {/* end: email */}
                <Row>
                    <Col>
                        <div className="form-group fv-plugins-icon-container">
                            <input
                                placeholder="Mobile"
                                type="text"
                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                    "mobile"
                                )}`}
                                name="mobile"
                                {...formik.getFieldProps("mobile")}
                            />
                            {formik.touched.mobile && formik.errors.mobile ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.mobile}</div>
                                </div>
                            ) : null}
                        </div>
                    </Col>
                    {/* end: Mobile */}

                    {/* begin: Company Name*/}
                    <Col>
                        <div className="form-group fv-plugins-icon-container">
                            <input
                                placeholder="Company Name"
                                type="text"
                                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                    "companyName"
                                )}`}
                                name="companyName"
                                {...formik.getFieldProps("companyName")}
                            />
                            {formik.touched.companyName && formik.errors.companyName ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.companyName}</div>
                                </div>
                            ) : null}
                        </div>
                    </Col>
                </Row>
                {/* end: Company Name */}


                <div className="form-group d-flex flex-wrap flex-center">
                    <button
                        type="submit"
                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                        <span>Submit</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default AddUser
