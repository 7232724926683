import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { UserContext } from "../../utils/UserContext";
import moment from "moment";
import Alert from "react-bootstrap/Alert";
import { Loader } from "../../components/toast/Loader";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
// import socketIOClient from "socket.io-client";
// const ENDPOINT = "http://127.0.0.1:4000";
//console.log("config file",configFile);

const selectOptions = {
  Disable: "Disable",
  Enable: "Enable",
  "Auction yet to start": "Auction yet to start",
  "Auction Live": "Auction Live",
  "Auction Complete valid": "Auction Complete valid",
  "Auction Complete invalid": "Auction Complete invalid",
};

export class ListAuction extends Component {
  static contextType = UserContext;
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.socket = null;
    this.GetActionFormat = this.GetActionFormat.bind(this);
    const { location } = this.props;
    this.state = {
      pageLoading: true,
      isUserAdminType: false,
      auctionList: [],
      adminColumn: [
        {
          dataField: "id",
          text: "ID",
          formatter: this.GetNullText,
          hidden: true,
        },
        {
          dataField: "rfp_title",
          text: "RFP",
          formatter: this.GetRfpTitle,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              window.location.href = `/viewRFP/${row.rfpId}`;
            },
          },
          sort: true,
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
        },
        {
          dataField: "rfp_organizationName",
          text: "Organization",
          formatter: this.GetOrganizationName,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              window.location.href = `/updateOrganisation/${row.rfp_organizationId}`;
            },
          },
          sort: true,
          align: "center",
          headerAlign: "center",
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
        },
        {
          dataField: "startTime",
          text: "Start Time",
          formatter: this.GetNullText,
          sort: true,
          align: "center",
          headerAlign: "center",
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
        },
        {
          dataField: "endTime",
          text: "End Time",
          formatter: this.GetNullText,
          sort: true,
          align: "center",
          headerAlign: "center",
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
        },

        {
          text: "Status",
          dataField: "status",
          formatter: (cell) => selectOptions[cell],
          filter: selectFilter({
            options: selectOptions,
            placeholder: "Select",
            className: "my-custom-text-filter",
            defaultValue:
              location.defaultSelect === undefined
                ? ""
                : location.defaultSelect,
            caseSensitive: false,
            id: "id",
          }),
          id: "dpra",
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "",
          text: "Action",
          formatter: this.GetActionFormat,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "",
          text: "Auction",
          formatter: this.ViewActionFormat,
          align: "center",
          headerAlign: "center",
        },
      ],

      userColumn: [
        {
          dataField: "id",
          text: "ID",
          formatter: this.GetNullText,
          hidden: true,
        },
        {
          dataField: "rfp_title",
          text: "RFP",
          formatter: this.GetRfpTitle,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              window.location.href = `/viewRFP/${row.rfpId}`;
            },
          },
          sort: true,
        },
        {
          dataField: "rfp_organizationName",
          text: "Organization",
          formatter: this.GetOrganizationName,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              window.location.href = `/updateOrganisation/${row.rfp_organizationId}`;
            },
          },
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "startTime",
          text: "Start Time",
          formatter: this.GetNullText,
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "endTime",
          text: "End Time",
          formatter: this.GetNullText,
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "",
          text: "Auction",
          formatter: this.ViewActionFormat,
          align: "center",
          headerAlign: "center",
        },
      ],
    };
  }

  async componentDidMount() {
    const { user } = this.context;
    const userObj = user.get();
    if (userObj.type === "Admin") {
      this.setState({
        isUserAdminType: true,
      });
    } else {
      this.setState({
        isUserAdminType: false,
      });
    }
    await axios
      .get("/api/auction/listAuction")
      .then((res) => {
        res.data.response.map((e) => {
          e.endTime = moment(e.endTime).format("LLL");
          e.startTime = moment(e.startTime).format("LLL");
          return e;
        });

        console.log("string" + res.data.response);
        this.setState({
          auctionList: res.data.response,
          pageLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          pageLoading: false,
        });
      });
  }

  GetActionFormat(cell, row) {
    return (
      <div>
        <Button
          type="button"
          size="sm"
          className="bRadius6"
          href={"/editAuction/" + row.id}
        >
          Edit
        </Button>
      </div>
    );
  }

  ViewActionFormat = (cell, row) => {
    let plainDate = new Date();
    let todayDate = moment(plainDate).format("LLL");
    console.log("this is row EndTime", row.endTime);
    console.log("this is row StartTime", row.startTime);
    console.log("this is today's date ", todayDate);
    console.log("diff ", moment().diff(row.startTime, "seconds"));
    if (
      moment().diff(row.startTime, "seconds") > 0 ||
      this.state.isUserAdminType
    ) {
      localStorage.setItem("auctionTableId", row.id);
      return (
        <>
          <Button
            type="button"
            size="sm"
            href={"/liveAuction/" + row.rfpId}
            // onClick={() => {
            //   this.props.history.push(`/liveAuction/${row.rfpId}`);
            // }}
            className="bRadius6"
          >
            Show Auction
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            type="button"
            size="sm"
            href="#"
            className="bRadius6"
            disabled
          >
            Show Auction
          </Button>
        </>
      );
    }
  };

  GetRfpTitle(cell, row) {
    return <span className="linkFormat">{row.rfp_title}</span>;
  }
  GetOrganizationName(cell, row) {
    return <span className="linkFormat">{row.rfp_organizationName}</span>;
  }

  GetNullText(cell, row) {
    //console.log(this.dataField,row);
    if (this.dataField.includes(".")) {
      // this condition use to access the nested object (2 layer)
      let field = this.dataField.split(".");
      if (row[field[0]][field[1]]) return row[field[0]][field[1]];
    }
    if (row[this.dataField]) {
      return row[this.dataField];
    } else {
      return "NA";
    }
  }

  render() {
    const options = {
      page: 1,
      sizePerPageList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "All",
          value: this.state.auctionList.length,
        },
      ],
      sizePerPage: 5,
      pageStartIndex: 1,
      paginationSize: 10,
      prePage: "Prev",
      nextPage: "Next",
      firstPage: "First",
      lastPage: "Last",
      paginationPosition: "bottom",
    };

    //default sorting
    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ];

    if (this.state.pageLoading) {
      return <Loader loading={this.state.loading} />;
    } else {
      return (
        <>
          {/* pre cache the connection lost image for live auction page */}
          <img
            src={toAbsoluteUrl("/media/custom/connectionlost.png")}
            alt="conntection lost"
            style={{ height: 0, width: 0, display: "none" }}
          />
          {this.state.auctionList.length > 0 ? (
            <>
              <div className="container">
                <BootstrapTable
                  striped
                  hover
                  id="listAuctionTable"
                  keyField="id"
                  defaultSorted={defaultSorted}
                  data={this.state.auctionList}
                  columns={
                    this.state.isUserAdminType
                      ? this.state.adminColumn
                      : this.state.userColumn
                  }
                  filter={filterFactory()}
                  pagination={
                    this.state.auctionList.length > 5
                      ? paginationFactory(options)
                      : false
                  }
                />
                {/* <ListRFP/> */}
              </div>
            </>
          ) : (
            <>
              <p>
                <Alert variant="success">
                  <Alert.Heading>Auction List</Alert.Heading>
                  <p>No Auction found.</p>
                </Alert>
              </p>
            </>
          )}
        </>
      );
    }
  }
}

export default withRouter(ListAuction);
