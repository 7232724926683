import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  selectFilter,
  textFilter,
} from "react-bootstrap-table2-filter";
import axios from "axios";
import { Alert, Button } from "react-bootstrap";
import moment from "moment";
import GlobalState from "../../utils/UserDetailContext";
import { Loader } from "../../components/toast/Loader";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

const eoiApprovalOptions = {
  Approved: "Approved",
  Pending: "Pending",
  Cancel: "Cancel",
  "Submit For Approvel": "Submit For Approvel",
};

const rfpStatusOption = {
  "In Progress": "In Progress",
  "Review Pending": "Review pending",
  "Back For Edit": "Back for edit",
  "Payment Pending": "Payment pending",
  Published: "Published",
  "EOI Closed": "EOI closed",
  "Submit For Review": "Submit For Review",
  Canceled: "Canceled",
  "Auction set": "Auction Set",
  "Ongoing auction": "Ongoing auction",
  "Auction Complete Valid": "Auction Complete Valid",
  "Auction completed invalid": "Auction completed invalid",
};

const rfpStatusFilter = selectFilter({
  options: rfpStatusOption,
  placeholder: "Select", // custom the input placeholder
  className: "my-custom-text-filter", // custom classname on input
  // defaultValue: '', // default filtering value
  // comparator: Comparator.LIKE, // default is Comparator.EQ
  caseSensitive: false, // default is true
  // style: { ... }, // your custom styles on input
  // withoutEmptyOption: true,  // hide the default select option
  id: "id", // assign a unique value for htmlFor attribute, it's useful when you have same dataField across multiple table in one page
});

export class ListEOI extends Component {
  static contextType = GlobalState;

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.GetActionFormat = this.GetActionFormat.bind(this);
    this.userType = "";
    const { location } = this.props;
    this.state = {
      pageLoading: true,
      isUserAccount: true,
      eoiList: [],
      adminColumns: [
        {
          dataField: "id",
          text: "ID",
          formatter: this.GetNullText,
          hidden: true,
        },
        {
          dataField: "rfpId",
          text: "RFP ID",
          formatter: this.GetNullText,
          hidden: true,
        },
        {
          dataField: "title",
          text: "RFP Title",
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
          formatter: this.GetTitle,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              window.location.href = `/viewRFP/${row.rfpId}`;
            },
          },
        },
        {
          dataField: "product_name",
          text: "Product Name",
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
          formatter: this.GetProductName,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              window.location.href = `/viewRFP/${row.rfpId}`;
            },
          },
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "organization_name",
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
          text: "Organization",
          formatter: this.GetOrganizationName,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              window.location.href = `/updateOrganisation/${row.eoi_organization_id}`;
            },
          },
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "status",
          text: "RFP Status",
          formatter: this.GetStatus,
          sort: true,
          // eslint-disable-next-line
          formatter: (cell) => rfpStatusOption[cell],
          filter: rfpStatusFilter,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "eoi_approval",
          text: "EOI Status",
          formatter: this.GetEoiApproval,
          sort: true,
          // eslint-disable-next-line
          formatter: (cell) => eoiApprovalOptions[cell],
          filter: selectFilter({
            options: eoiApprovalOptions,
            placeholder: "Select",
            className: "my-custom-text-filter",
            defaultValue:
              location.defaultSelect === undefined
                ? ""
                : location.defaultSelect,
            caseSensitive: false,
            id: "id",
          }),
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "auction_start",
          text: "Auction Date",
          formatter: this.DateFormatter,
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "",
          text: "Action",
          formatter: this.GetActionFormat,
          align: "center",
          headerAlign: "center",
        },
      ],
      userColumns: [
        {
          dataField: "id",
          text: "ID",
          formatter: this.GetNullText,
          hidden: true,
        },
        {
          dataField: "rfpId",
          text: "RFP ID",
          formatter: this.GetNullText,
          hidden: true,
        },
        {
          dataField: "title",
          text: "RFP Title",
          formatter: this.GetTitle,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              window.location.href = `/viewRFP/${row.rfpId}`;
            },
          },
        },
        {
          dataField: "product_name",
          text: "Product Name",
          formatter: this.GetProductName,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              // console.log(row);
              window.location.href = `/viewRFP/${row.rfpId}`;
            },
          },
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "status",
          text: "Status",
          formatter: this.GetNullText,
          //filter: textFilter(),
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "eoi_approval",
          text: "EOI Approval",
          formatter: this.GetNullText,
          //filter: textFilter(),
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "auction_start",
          text: "Auction Date",
          formatter: this.DateFormatter,
          //filter: textFilter(),
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "",
          text: "Action",
          formatter: this.GetActionFormat,
        },
      ],
    };
  }

  componentDidMount() {
    axios
      .get("/api/eoi/listEOI")
      .then((res) => {
        console.log(res);
        this.setState({
          eoiList: res.data.response,
          isUserAccount: res.data.isUserAccount ? true : false,
          pageLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          pageLoading: false,
        });
      });
  }

  GetActionFormat(cell, row) {
    console.log(row.eoi_approval, this.userType);
    return (
      <div>
        {row.eoi_approval === "Approved" && this.userType === "User" ? (
          <Button type="button" size="sm" href="/#" disabled>
            Edit
          </Button>
        ) : (
          <Button type="button" size="sm" href={"/editEOI/" + row.id}>
            Edit
          </Button>
        )}
      </div>
    );
  }

  ViewRfpFormat(cell, row) {
    return (
      <Button type="button" size="sm" href={"/viewRFP/" + row.rfpId}>
        View RFP
      </Button>
    );
  }

  GetOrganizationName(cell, row) {
    let orgName = JSON.stringify(row.organization_name);
    if (orgName === "null") {
      return <span>NA</span>;
    } else {
      return <span className="linkFormat">{row.organization_name}</span>;
    }
  }

  GetStatus(cell, row) {
    return <span>{row.status}</span>;
  }

  GetTitle(cell, row) {
    return <span className="linkFormat">{row.title}</span>;
  }

  GetProductName(cell, row) {
    return <span className="linkFormat">{row.product_name}</span>;
  }

  GetEoiApproval(cell, row) {
    return <span>{row.eoi_approval}</span>;
  }

  GetNullText(cell, row) {
    // console.log(this.dataField);
    if (this.dataField.includes(".")) {
      let field = this.dataField.split(".");
      return row[field[0]][field[1]];
    } else if (row[this.dataField]) {
      return row[this.dataField];
    } else {
      return "NA";
    }
  }

  DateFormatter(cell, row) {
    if (row[this.dataField]) {
      return moment(row[this.dataField]).format("lll");
    } else {
      return "To be decided";
    }
  }

  render() {
    this.userType = this.context[0]["type"];
    // console.log("user details",this.context[0]);
    console.log("user details", this.userType);
    const options = {
      page: 1,
      sizePerPageList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "All",
          value: this.state.eoiList.length,
        },
      ],
      sizePerPage: 5,
      pageStartIndex: 1,
      paginationSize: 10,
      prePage: "Prev",
      nextPage: "Next",
      firstPage: "First",
      lastPage: "Last",
      paginationPosition: "bottom",
    };

    //default sorting
    const defaultSorted = [
      {
        dataField: "id",
        order: "desc",
      },
    ];

    if (this.state.pageLoading) {
      return <Loader loading={this.state.pageLoading} />;
    } else {
      return (
        <>
          {this.state.eoiList.length > 0 ? (
            <>
              <BootstrapTable
                striped
                hover
                className="eoitable"
                id="eoiTable"
                keyField="id"
                data={this.state.eoiList}
                defaultSorted={defaultSorted}
                columns={
                  this.state.isUserAccount
                    ? this.state.userColumns
                    : this.state.adminColumns
                }
                filter={filterFactory()}
                pagination={
                  this.state.eoiList.length > 5
                    ? paginationFactory(options)
                    : false
                }
              />
            </>
          ) : (
            <>
              <p>
                {" "}
                <Alert variant="success">
                  <Alert.Heading>EOI List</Alert.Heading>
                  <p>No EOI found.</p>
                </Alert>
              </p>
            </>
          )}
        </>
      );
    }
  }
}
export default withRouter(ListEOI);
