import React from "react";


export function LayoutSplashScreen() {
  return (
    <div id="splash-screen" className="kt-splash-screen">
      {/* <img src="/media/custom/dot-loader.gif" alt="Bonolo logo" /> */}
      {/* <img src="/media/custom/logo.png" alt="Bonolo logo" />
      <svg className="splash-spinner" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
      </svg> */}
    </div>
  );
}
