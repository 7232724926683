// eslint-disable-next-line
import React, { useState, useEffect, withRouter } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import BonoloField from "../../BonoloField";
import _ from "lodash";
import { useContext } from "react";
import { UserContext } from "../../utils/UserContext";
import { useHistory } from "react-router-dom";
import configFile from "../../../config.js";
import Modal from "react-bootstrap/Modal";
import { Loader } from "../../components/toast/Loader";

const STATIC_WEB_URL = configFile.STATIC_WEB_URL;

function AddRFP(props) {
  const history = useHistory();
  //  const [categoriesData, setCategoriesData] = useState({});

  // eslint-disable-next-line
  const [docName, setDocName] = useState("Add attachment");
  // eslint-disable-next-line
  const [attachment, setAttachment] = useState({});
  const [fileCategory, setFileCategory] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState({
    drawingPdf: null,
    drawingDwg: null,
    rfq: null,
    qapFormat: null,
    progressReportFormat: null,
    nda: null,
    termsConditions: null,
    billingFormat: null,
  });
  const [initialUploadedFiles, setInitialUploadedFiles] = useState({});
  const { user } = useContext(UserContext);
  const userObj = user.get() || "";
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

//Scope options
  const MTC = "MTC";
  const NABL = "NABL";
  const QAP = "QAP";
  const PACKAGING = "PACKAGING";
  const LOGISTICS = "LOGISTICS";
  const GRN = "GRN";
  const INSTALLATION = "INSTALLATION";
  const COMMISSIONING = "COMMISSIONING";

  // Status Options
  const IN_PROGRESS = "In Progress";
  const REVIEW_PENDING = "Review Pending";
  const BACK_FOR_EDIT = "Back For Edit";
  const PAYMENT_PENDING = "Payment Pending";
  const PUBLISHED = "Published";
  // const INACTIVE = "Inactive";
  const CANCELED = "Canceled";
  const SUBMIT_FOR_REVIEW = "Submit For Review";
  const EOI_CLOSED = "EOI Closed";
  const AUCTION_SET = "Auction Set";
  const ONGOING_AUCTION = "Ongoing auction";
  const AUCTION_COMPLETED_VALID = "Auction Complete Valid";
  const AUCTION_COMPLETED_INVALID = "Auction completed invalid";

  const [statusOption, setStatusOption] = useState([
    [IN_PROGRESS, IN_PROGRESS],
    [SUBMIT_FOR_REVIEW, SUBMIT_FOR_REVIEW],
  ]);


  // const OPTION1 = "Option 1";
  // const OPTION2 = "Option 2";

  // const [paymentOptions, setPaymentOptions] = useState([
  //   [OPTION1, OPTION1],[OPTION2, OPTION2]
  // ])

   // eslint-disable-next-line 
  const [scopeOptions, setScopeOption] = useState([
    [MTC, MTC],
    [NABL, NABL],
    [QAP, QAP],
    [PACKAGING, PACKAGING],
    [LOGISTICS, LOGISTICS],
    [GRN, GRN],
    [INSTALLATION, INSTALLATION],
    [COMMISSIONING, COMMISSIONING],
  ])

  const initialValues = {
    title: "",
    product_name: "",
    delivery_type: "",
    delivery_location: "",
    delivery_schedule: "",
    delivery_detail: "",
    terms_conditions: "",
    detail: "",
    delivery_cost: "Yes",
    installation_cost: "Yes",
    amc_cost: "",
    custom_cost: "",
    manufacturing_year: "",
    vendor_experience: "",
    min_vendor_turnover: "",
    min_vendor_networth: "",
    custom_vendor_constraints: "",
    hurdle_rate: "",
    status: IN_PROGRESS,
    technical_bid_required: "",
    image_url: "",
    bid_open_date:"",
    bid_code:"",
    project_code:"",
    part_code:"",
    part_name:"",
    moc:"",
    finish:"",
    qty:"",
    scope:"",
    processes:"",
    dimension:"",
    delivery_at:"",
    lead_time:"",
    payment_terms:"",
    price_estimation:"",
    ld_clause:"",
    bonus_clause:"",
    acceptTerms: false,
  };


  const fileCategories = [
    "drawingPdf",
    "drawingDwg",
    "rfq",
    "qapFormat",
    "progressReportFormat",
    "nda",
    "termsConditions",
    "billingFormat",
  ];

  const handleCategoryClick = async (category) => {
    if (uploadedFiles[category]) {
      setUploadedFiles((prevState) => ({
        ...prevState,
        [category]: null,
      }));
    } else {
      setFileCategory(category);
      document.getElementById('attachment').click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && fileCategory) {
      setUploadedFiles((prevState) => ({
        ...prevState,
        [fileCategory]: file,
      }));
      setDocName(file.name);
    }
  };


  if (userObj.type === "Admin") {
    initialValues["bid_decrement_value"] = "";
  }

  let RFPSchema = Yup.object().shape({
    title: Yup.string()
      .min(5, "Must be 5-1000 characters")
      .max(1000, "Must be 5-1000 characters")
      .required("(*)Marked fields are required"),
    // attachment_name: Yup.string(),
    product_name: Yup.string()
      .min(5, "Must be 5-100 characters")
      .max(100, "Must be 5-100 characters")
      .required("(*)Marked fields are required"),
    delivery_type: Yup.string()
      .min(5, "Must be 5-100 characters")
      .max(100, "Must be 5-100 characters")
      .required("(*)Marked fields are required"),
    delivery_location: Yup.string()
      .min(5, "Must be 5-100 characters")
      .max(100, "Must be 5-100 characters")
      .required("(*)Marked fields are required"),
    delivery_schedule: Yup.string()
      .min(5, "Must be 5-100 characters")
      .max(100, "Must be 5-100 characters")
      .required("(*)Marked fields are required"),
    delivery_detail: Yup.string()
      .min(5, "Must be 5-1000 characters")
      .max(1000, "Must be 5-1000 characters")
      .required("(*)Marked fields are required"),
    terms_conditions: Yup.string()
      .min(5, "Must be 5-1000 characters")
      .max(1000, "Must be 5-1000 characters"),
    detail: Yup.string()
      .min(5, "Must be 5-1000 characters")
      .max(1000, "Must be 5-1000 characters")
      .required("(*)Marked fields are required"),
    amc_cost: Yup.number()
      .typeError("Must be only numeric value")
      .max(99999, "Should be less than 1,00,000")
      .required("(*)Marked fields are required"),
    custom_cost: Yup.string()
      .typeError("Must be only digits")
      .min(5, "Custom cost must be at least 5 characters")
      .max(1000, "Custom cost exceeds the maximum limit of cost."),
    manufacturing_year: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits.")
      .min(4, "Must be 4 digit numeric value")
      .max(4, "Must be 4 digit numeric value"),
    min_vendor_turnover: Yup.number()
      .max(9999999, "Min vendor turnover exceeds the maximum limit of cost.")
      .typeError("Must be only digits")
      .required("(*)Marked fields are required"),
    vendor_experience: Yup.number()
      .max(100, "The text entered exceeds the maximum length.")
      .typeError("Must be only digits")
      .required("(*)Marked fields are required"),
    min_vendor_networth: Yup.number()
      // .min(5, "Vendor networth must be at least 5 characters")
      .max(99999, "The networth entered exceeds the maximum limit.")
      .required("(*)Marked fields are required"),
    custom_vendor_constraints: Yup.string()
      .min(5, "Must be 5-1000 characters")
      .max(1000, "Must be 5-1000 characters"),
    hurdle_rate: Yup.number()
      .max(9999999, "The text entered exceeds the maximum length.")
      .typeError("Must be only digits")
      .required("(*)Marked fields are required"),
    bid_open_date: Yup.string()
      .min(5, "Must be 5-100 characters")
      .max(100, "Must be 5-100 characters")
      .nullable(),
      // .required("(*)Marked fields are required"),
    bid_code: Yup.string()
      .min(5, "Must be 5-1000 characters")
      .max(1000, "Must be 5-1000 characters")
      .nullable(),
      // .required("(*)Marked fields are required"),
    project_code: Yup.string()
      .min(5, "Must be 5-1000 characters")
      .max(1000, "Must be 5-1000 characters")
      .nullable(),
      // .required("(*)Marked fields are required"),
    part_code: Yup.string()
    .min(5, "Must be 5-1000 characters")
    .max(1000, "Must be 5-1000 characters")
    .nullable(),
    // .required("(*)Marked fields are required"),
    part_name: Yup.string()
    .min(5, "Must be 5-1000 characters")
    .max(1000, "Must be 5-1000 characters")
    .nullable(),
    // .required("(*)Marked fields are required"),
    moc: Yup.string()
    .min(5, "Must be 5-1000 characters")
    .max(1000, "Must be 5-1000 characters")
    .nullable(),
    // .required("(*)Marked fields are required"),
    
    dimension: Yup.string()
    .min(5, "Must be 5-1000 characters")
    .max(1000, "Must be 5-1000 characters")
    .nullable(),
    // .required("(*)Marked fields are required"),
    finish: Yup.string()
    .min(5, "Must be 5-1000 characters")
    .max(1000, "Must be 5-1000 characters")
    .nullable(),
    // .required("(*)Marked fields are required"),
    qty: Yup.number()
    .max(9999999, "The text entered exceeds the maximum length.")
      .typeError("Must be only digits")
      .nullable(),
      // .required("(*)Marked fields are required"),
      processes: Yup.string()
    .min(5, "Must be 5-1000 characters")
    .max(1000, "Must be 5-1000 characters")
    .nullable(),
    // .required("(*)Marked fields are required"),
    delivery_at: Yup.string()
      .min(5, "Must be 5-100 characters")
      .max(100, "Must be 5-100 characters")
      .nullable(),
      // .required("(*)Marked fields are required"),
      lead_time: Yup.string()
      .min(5, "Must be 5-1000 characters")
      .max(1000, "Must be 5-1000 characters")
      .nullable(),
      // .required("(*)Marked fields are required"),
      price_estimation: Yup.number()
      .max(9999999, "The text entered exceeds the maximum length.")
        .typeError("Must be only digits")
        .nullable(),
        // .required("(*)Marked fields are required"),

        ld_clause: Yup.string()
      .min(5, "Must be 5-1000 characters")
      .max(1000, "Must be 5-1000 characters")
      .nullable(),
      // .required("(*)Marked fields are required"),
      bonus_clause: Yup.string()
      .min(5, "Must be 5-1000 characters")
      .max(1000, "Must be 5-1000 characters")
      .nullable(),
      // .required("(*)Marked fields are required"),


    

    acceptTerms: Yup.bool().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
    // earnest_money: Yup.number()
    //   .max(9999, "The text entered exceeds the maximum length.")
    //   .typeError("Must be only digits.")
    //   .required("Earnest money is required."),
    technical_bid_required: Yup.string()
      .min(5, "Must be 5-1000 characters")
      .max(1000, "Must be 5-1000 characters")
      // .typeError("Must be only digits.")
      .required("(*)Marked fields are required"),
  });
  if (userObj.type === "Admin") {
    RFPSchema = RFPSchema.concat(
      Yup.object({
        bid_decrement_value: Yup.number()
          .required("Decrement value is required.")
          .typeError("Must be only digits."),
        // .max(999, "The text entered exceeds the maximum length.")
      })
    );
  }
  let id = 0;
  let organizationId = 0;
  // let imgArray =[];
  if (props.match.params.id !== undefined) {
    id = props.match.params.id;
  }

  // if this component is navigated from ListOrganisation to add RFP by admin
  // It will have params organizationId
  if (props.match.params.organizationId !== undefined) {
    organizationId = props.match.params.organizationId;
  }

  //const [loading, setLoading] = useState(true);
  const [rfpData, setRFPData] = useState({});
  const [fileData, setFileData] = useState([]);
  const [base64, setbase64] = useState([]);
  const [imagePath, setImgArray] = useState([]);
  // const[categoriesPair,setcategoriesPair] = useState({});
  useEffect(() => {
    // console.log("debug",initialValues);
    if (id !== 0) {
      const fetchData = async () => {
        const imgRes = await axios.get("/api/files/getFiles/type/" + id);
        if (imgRes && imgRes.data) {
          const files = imgRes.data;
          const fileMapping = {
            DRAWINGPDF: "drawingPdf",
            DRAWINGDWG: "drawingDwg",
            RFQ: "rfq",
            QAPFORMAT: "qapFormat",
            PROGRESSREPORTFORMAT: "progressReportFormat",
            NDA: "nda",
            TERMSCONDITIONS: "termsConditions",
            BILLINGFORMAT: "billingFormat",
          };

          const updatedUploadedFiles = { ...uploadedFiles };

          files.forEach((file) => {
            const key = fileMapping[file.document_type];
            if (key) {
              updatedUploadedFiles[key] = file;
            }
          });

          setUploadedFiles(updatedUploadedFiles);
          setInitialUploadedFiles(updatedUploadedFiles);
        }

        const res = await axios.get("/api/rfp/getRFP?id=" + id);
        if (res.data.response) {
          setRFPData(res.data.response);
          setPageLoading(false);
          if (res.data.response.image_url) {
            let imgs = res.data.response.image_url.map((e) => {
              return `/uploads/${e}`;
            });
            if (res.data.response.attachment_name) {
              setDocName(res.data.response.attachment_name);
            }

            setImgArray(res.data.response.image_url);
            setbase64(imgs);
          }

          // Status option will depends on user type
          // type "User" will only have two option IN_PROGRESS and SUBMIT_FOR_REVIEW
          let status = res.data.response.status;
          if (userObj.type === "User") {
            // RFP status is IN_PROGRESS or BACK_FOR_EDIT then application is editable and
            // User have two option IN_PROGRESS and SUBMIT_FOR_REVIEW
            // else application is not editable

            if ([IN_PROGRESS, BACK_FOR_EDIT].includes(status)) {
              setRFPData({ ...res.data.response, status: IN_PROGRESS });
              setStatusOption([
                [IN_PROGRESS, IN_PROGRESS],
                [SUBMIT_FOR_REVIEW, SUBMIT_FOR_REVIEW],
              ]);
            } else {
              setStatusOption([[status, status]]);
            }
          }
          if (userObj.type === "Admin") {
            // If user type is "Admin" then it will have all the status option and
            // can set any status (May change in future)
            setStatusOption([
              [IN_PROGRESS, IN_PROGRESS],
              [REVIEW_PENDING, REVIEW_PENDING],
              [BACK_FOR_EDIT, BACK_FOR_EDIT],
              [PAYMENT_PENDING, PAYMENT_PENDING],
              [PUBLISHED, PUBLISHED],
              // [INACTIVE, INACTIVE],
              [CANCELED, CANCELED],
              [EOI_CLOSED, EOI_CLOSED],
              [AUCTION_SET, AUCTION_SET],
              [ONGOING_AUCTION, ONGOING_AUCTION],
              [AUCTION_COMPLETED_VALID, AUCTION_COMPLETED_VALID],
              [AUCTION_COMPLETED_INVALID, AUCTION_COMPLETED_INVALID],
            ]);
          }
        } else {
          history.push("/dashboard");
        }
      };
      fetchData();
    } else {
      setStatusOption([[IN_PROGRESS, IN_PROGRESS]]);
      setPageLoading(false);
    }
    fetchCategoriesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCategoriesData = async () => {
    let url = "/api/organization/getCategories";
    const res = await axios.get(url);
    // console.log(res.data.response);
    let d = res.data.response.reduce((acc, e) => {
      if (e["id"] > 0) {
        acc[e["id"]] = e["name"];
      }
      return acc;
    }, {});
    //setcategoriesPair(d);
    console.log(d);
    //  if(id !== 0){
    //   console.log(rfpData);
    //  }

    //setCategoriesData(Object.values(d));
  };

  // Submit the RFP application for the review
  const submitHandler = (values, { setStatus, setSubmitting }) => {
    console.log(2343525);
    const formData = new FormData();
    // fileData.forEach((element) => {
    //   console.log(element);
    //   formData.append("imgCollection", element);
    // });
    // formData.append("attachment", attachment);
    imagePath.forEach((element) => {
      console.log(element);
      formData.append("imgUrl", element);
    });
    if (userObj.type === "User") {
      values.status = SUBMIT_FOR_REVIEW;
    } else if (userObj.type === "Admin" && organizationId !== 0) {
      values.status = SUBMIT_FOR_REVIEW;
      values.organizationId = organizationId;
    } else {
      /* Put here any action that you want to perform before update by Admin */
    }

    for (const category in initialUploadedFiles) {
      if (initialUploadedFiles[category] && !uploadedFiles[category]) {
        axios.delete(`/api/files/delete/${initialUploadedFiles[category].id}`);
      }
    }

    axios
      .post("/api/rfp/saveRFP", values)
      .then(async (res) => {
        setSubmitting(false);
        if (res.status === 200) {
          console.log("request", res);
          const rfpId = res.data.response.id;
          formData.append("type_id", rfpId);
          formData.append("type", "RFP");

          for (let i = 0; i < fileCategories.length; i++) {
            const category = fileCategories[i];
            if (
              uploadedFiles[category] &&
              uploadedFiles[category] !== initialUploadedFiles[category]
            ) {
              const formData = new FormData();
              formData.append("type_id", rfpId);
              formData.append("type", "RFP");
              formData.append("attachment", uploadedFiles[category]);
              formData.append("doctype", category.toUpperCase());

              try {
                await axios.post("/api/files/saveFiles", formData);
              } catch (fileUploadError) {
                console.error(
                  `Error uploading file for category ${category}:`,
                  fileUploadError
                );
                setStatus("failure");
                return;
              }
            }
          }
          setStatus("success");
          props.history.push("/ListRFP");

          // axios.post("/api/saveFiles", formData)
          //     .then((res) => {
          //         values.image_url = res.data.files;
          //         values.attachment_name = res.data.attachment;
          //         setStatus("success");
          //         props.history.push("/ListRFP");
          //     })
          //     .catch((error) => {
          //         console.error("Error uploading files:", error);
          //         setStatus("failure");
          //     });
        } else {
          setStatus("failure");
        }
      })
      .catch((error) => {
        console.error("Error saving RFP:", error);
        setSubmitting(false);
        setStatus("failure");
      });
  };

  const cancelRFPRequest = async () => {
    if (id !== 0) {
      await axios.post("/api/rfp/cancelRFP", { id: id });
      props.history.push("/ListRFP");
    }
  };

  const cancelRFPHandler = () => {
    setIsPopoverOpen(true);
  };

  // Save the data of new RFP without any validation
  // Saved RFP is still in In-Progess status and not
  //submitted for the review
  // const saveRFP = (values) => {
  //   const formData = new FormData();
  //   fileData.forEach((element) => {
  //     console.log(element);
  //     formData.append("imgCollection", element);
  //   });
  //   formData.append("attachment", attachment);
  //   imagePath.forEach((element) => {
  //     console.log(element);
  //     formData.append("imgUrl", element);
  //   });

  //   axios.post("/api/rfp/saveRFPFiles", formData).then((res) => {
  //     values.image_url = res["data"]["files"];
  //     values.attachment_name = res["data"]["attachment"];

  //     axios.post("/api/rfp/saveRFP", values).then((res) => {
  //       if (res.status === 200) {
  //         props.history.push("/ListRFP");
  //       } else {
  //       }
  //     });
  //   });
  // };

  const selectFiles = (e) => {
    console.log(e.target.files);
    let arr = e.target.files;
    arr = Object.values(arr);
    let tempArray = [];
    let formDataArray = [];
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      const reader = new FileReader();
      reader.readAsDataURL(element);
      reader.onload = (e) => {
        var rawLog = reader.result;
        formDataArray.push(element);
        tempArray.push(rawLog);
      };
    }
    setTimeout(() => {
      setFileData([...fileData, ...formDataArray]);
      setbase64([...base64, ...tempArray]);
    }, 2500);
  };

  const drop = (index) => {
    console.log(index);
    let temp = fileData.filter((e, i) => i !== index);
    let arr = base64.filter((ele, i) => i !== index);
    setFileData(temp);
    setbase64(arr);
    if (id !== 0) {
      let imgUrl = imagePath.filter((e, i) => i !== index);
      setImgArray(imgUrl);
    }
  };

  const ShowPreview = (props) => {
    //console.log(categoriesPair[rfpData.productCategoryId]);
    // if(id !== 0){
    //    initialValues.image_url = imagePath;
    //   console.log(imagePath);
    // }
    let source = props.source;
    source = source.map((ele, index) => {
      // console.log(index,fileData[index]);
      return (
        <>
          {" "}
          <span className="img-wrapper">
            <span
              onClick={() => {
                props.onRemove(index);
              }}
              className="spanx"
            >
              x
            </span>
            <img src={ele} className="imgStyle" rounded alt="file" />
          </span>
        </>
      );
    });

    return <span className={source.length > 0 ? "img-div" : ""}>{source}</span>;
  };

  if (pageLoading) {
    return <Loader loading={pageLoading} />;
  } else {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={
            //   ()=>{
            _.merge({}, initialValues, rfpData)
            // }
            //rfpData.productCategoryId = categoriesPair[rfpData.productCategoryId
            // console.log(categoriesPair[rfpData.productCategoryId])
          }
          validationSchema={RFPSchema}
          onSubmit={submitHandler}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit} className="mt-5 p-md-5">
              <Row className="product-row">
                <Col className="d-flex flex-wrap">
                  <ShowPreview
                    source={base64}
                    onRemove={(i) => {
                      drop(i);
                    }}
                    style={{ height: "100px", width: "100px" }}
                  ></ShowPreview>
                  <div className="file-group">
                    <label for="file" className="pro-file-label"></label>
                    <input
                      type="file"
                      multiple
                      name="file"
                      id="file"
                      className="pro-file"
                      onChange={selectFiles}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <Form.Group>
                    <BonoloField
                      label="RFP Title*"
                      name="title"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <BonoloField
                      placeholder="I want to buy......."
                      label="Required Product/Service Name*"
                      name="product_name"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <BonoloField
                      type="textarea"
                      label="RFP Details*"
                      name="detail"
                      formik={formik}
                    />
                    <div className="attachment-wrapper">
                      {fileCategories.map((category) => (
                        <span
                          key={category}
                          onClick={() => handleCategoryClick(category)}
                          className="btn btn-light btn-sm font-weight-bold add-attachment"
                        >
                          {uploadedFiles[category]
                            ? ` ${category} - ${uploadedFiles[category].name ||
                                uploadedFiles[category]?.orignal_name} `
                            : category}
                          {uploadedFiles[category] ? (
                            <i className="fa fa-times" aria-hidden="true"></i>
                          ) : (
                            <i
                              className="fa fa-paperclip"
                              aria-hidden="true"
                            ></i>
                          )}
                        </span>
                      ))}
                    </div>
                    <input
                      id="attachment"
                      accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      type="file"
                      className="input-image"
                      name="attachment_name"
                      onChange={handleFileChange}
                      hidden
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <hr className="hr" />
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <Form.Group>
                    <BonoloField
                      label="Delivery Type*"
                      name="delivery_type"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <BonoloField
                      placeholder="District, State"
                      label="Delivery Location*"
                      name="delivery_location"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <BonoloField
                      label="Delivery Schedule*"
                      name="delivery_schedule"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <BonoloField
                      type="textarea"
                      label="Other Delivery Details*"
                      name="delivery_detail"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <Form.Group>
                    <BonoloField
                      placeholder="Select option"
                      type="select"
                      label="Is Delivery Cost Included?*"
                      name="delivery_cost"
                      values={[
                        ["Yes", "Yes"],
                        ["No", "No"],
                      ]}
                      formik={formik}
                    />
                  </Form.Group>
                </Col>

                <Col md="4">
                  <Form.Group>
                    <BonoloField
                      placeholder="Select option"
                      type="select"
                      label="Is Installation Cost Included?*"
                      name="installation_cost"
                      values={[
                        ["Yes", "Yes"],
                        ["No", "No"],
                      ]}
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <BonoloField
                      placeholder="Specify the required AMC period in months"
                      label="Duration of AMC included (Months)*"
                      name="amc_cost"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <BonoloField
                      placeholder="List any other costs to be included in delivery"
                      label="Any other Costs to be Included?"
                      name="custom_cost"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {userObj.type === "Admin" ? (
                <>
                  {" "}
                  <Row>
                    <Col>
                      <Form.Group>
                        <BonoloField
                          placeholder="Bid decrement value"
                          label="Bid Decrement Value*"
                          name="bid_decrement_value"
                          formik={formik}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              ) : (
                ""
              )}

              <Row>
                <Col md="4">
                  <Form.Group>
                    <BonoloField
                      placeholder="in Lakh rupees"
                      label="Min. Vendor Turnover* (in Lakh rupees)"
                      name="min_vendor_turnover"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <BonoloField
                      placeholder="in number of years"
                      label="Min. Vendor Experience* (in number of years)"
                      name="vendor_experience"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <BonoloField
                      placeholder="in Lakh rupees"
                      label="Min. Vendor Networth* (in Lakh rupees)"
                      name="min_vendor_networth"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Form.Group>
                    <BonoloField
                      placeholder="For eg., 2019, 2020 etc"
                      label="Manufacturing Year"
                      name="manufacturing_year"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <BonoloField
                      placeholder="The maximum amount you are willing to pay"
                      label="Hurdle Rate* (in rupees)"
                      name="hurdle_rate"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
                {userObj.type === "Admin" && (
                  <Col md="4">
                    <Form.Group>
                      <BonoloField
                        placeholder="Select Status of RFP"
                        type="select"
                        label="Status"
                        name="status"
                        formik={formik}
                        values={statusOption}
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <BonoloField
                      placeholder="List out the custom vendor constraints, if any"
                      label="Custom Constraint"
                      name="custom_vendor_constraints"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <BonoloField
                      placeholder="Technical requirements of the RFP are….."
                      label="Technical Bid Description*"
                      name="technical_bid_required"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <hr className="hr" />
                </Col>
              </Row>

              <Row>
                <Col>
                  <h3>JOB DESCRIPTION</h3>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Form.Group>
                    <BonoloField
                      placeholder=""
                      label="BID Open Date"
                      name="bid_open_date"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <BonoloField
                      placeholder="Bid Code"
                      label="Bid Code"
                      name="bid_code"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>

                  <Col md="4">
                    <Form.Group>
                      <BonoloField
                        placeholder="Project Code"
                        label="Project Code"
                        name="project_code"
                        formik={formik}
                      />
                    </Form.Group>
                  </Col>
              </Row>
              <Row>
                <Col md="4">
                <Form.Group>
                      <BonoloField
                        placeholder="Part Code"
                        label="Part Code"
                        name="part_code"
                        formik={formik}
                      />
                    </Form.Group>
                </Col>
                <Col md="4">
                <Form.Group>
                      <BonoloField
                        placeholder="Part Name"
                        label="Part Name"
                        name="part_name"
                        formik={formik}
                      />
                    </Form.Group>
                </Col>
                <Col md="4">
                <Form.Group>
                      <BonoloField
                        placeholder="MOC"
                        label="MOC"
                        name="moc"
                        formik={formik}
                      />
                    </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                <Form.Group>
                      <BonoloField
                        placeholder="Dimension"
                        label="Dimension"
                        name="dimension"
                        formik={formik}
                      />
                    </Form.Group>
                </Col>
                <Col md="4">
                <Form.Group>
                      <BonoloField
                        placeholder="Finish"
                        label="Finish"
                        name="finish"
                        formik={formik}
                      />
                    </Form.Group>
                </Col>
                <Col md="4">
                <Form.Group>
                      <BonoloField
                        placeholder="Qty"
                        label="Qty"
                        name="qty"
                        formik={formik}
                      />
                    </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                <Form.Group>
                      <BonoloField
                        placeholder="Processes"
                        label="Processes"
                        name="processes"
                        formik={formik}
                      />
                    </Form.Group>
                </Col>
                <Col md="4">
                <Form.Group>
                      <BonoloField
                        placeholder="Select Scope"
                        type="select"
                        label="Scope"
                        name="scope"
                        formik={formik}
                        values={scopeOptions}
                      />
                    </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <hr className="hr" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3>JOB TERMS</h3>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Form.Group>
                    <BonoloField
                      placeholder=""
                      label="Delivery At"
                      name="delivery_at"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group>
                    <BonoloField
                      placeholder="Lead Time"
                      label="Lead Time"
                      name="lead_time"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>

                  <Col md="4">
                    <Form.Group>
                      <BonoloField
                        placeholder="Payment Terms"
                        label="Payment Terms"
                        // type="select"
                        // values={paymentOptions}
                        name="payment_terms"
                        formik={formik}
                      />
                    </Form.Group>
                  </Col>
              </Row>
              <Row>
                <Col md="6">
                <Form.Group>
                    <BonoloField
                      placeholder=""
                      label="Price Estimation (in Rupees)"
                      name="price_estimation"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                <Form.Group>
                    <BonoloField
                      placeholder="LD Clause"
                      label="LD Clause"
                      name="ld_clause"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                <Form.Group>
                    <BonoloField
                      placeholder="Enter any Bonus Clause"
                      type="textarea"
                      label="Bonus Clause"
                      name="bonus_clause"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <hr className="hr" />
                </Col>
              </Row>


              <Row>
                <Col>
                  <Form.Group>
                    <BonoloField
                      placeholder="Enter any additional Terms and Conditions for delivery of product"
                      type="textarea"
                      label="Additional Terms and Conditions"
                      name="terms_conditions"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-group">
                    <div className="input-checkbox-outer">
                      <input
                        type="checkbox"
                        name="acceptTerms"
                        className="input-checkbox"
                        {...formik.getFieldProps("acceptTerms")}
                      />
                      <label className="checkbox">
                        {" "}
                        I accept{" "}
                        <a href={`${STATIC_WEB_URL}/terms-conditions.html`}>
                          terms and conditions
                        </a>
                        *
                      </label>
                    </div>
                    {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.acceptTerms}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* <Form.Group controlId="formBasicCheckbox" className="d-flex">
                  <input type="checkbox" className="checkme" id="checkeoi" name="acceptTerms" />
                  <label for="checkeoi">I accept the <a href={`${STATIC_WEB_URL}/terms-conditions.html`}>terms and conditions* </a></label>
                </Form.Group> */}
                </Col>
              </Row>

              {/* <BonoloField label="Earnest Money" name="earnest_money" formik={formik} />
            <BonoloField label="Bid Decrement Value" name="bid_decrement_value" formik={formik} /> */}

              {/* <Form.Group controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Check me out" />
            </Form.Group> */}
              <Row className="row-upper" style={{ top: "-10px" }}>
                <Col>
                  <hr className="hr" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    disabled={
                      [
                        // INACTIVE,
                        EOI_CLOSED,
                        CANCELED,
                        PUBLISHED,
                        IN_PROGRESS,
                      ].includes(rfpData.status) &&
                      userObj.type === "User" &&
                      Object.keys(rfpData).length > 0
                    }
                    variant="primary"
                    className="submit-btn ml-5"
                    name="submit"
                    type="submit"
                  >
                    Submit
                  </Button>
                  {(userObj.type === "User" ||
                    props.match.url.includes("createRFP")) && (
                    <>
                      {/* <Button
                        disabled={
                          !formik.values.title ||
                          ([
                            // INACTIVE,
                            EOI_CLOSED,
                            CANCELED,
                            PUBLISHED,
                            IN_PROGRESS,
                          ].includes(rfpData.status) &&
                            userObj.type === "User" &&
                            Object.keys(rfpData).length > 0)
                        }
                        variant="primary"
                        className="submit-btn submit-btn-light ml-5"
                        name="submit"
                        onClick={() => {
                          saveRFP(formik.values);
                        }}
                      >
                        Save
                      </Button> */}
                      <Button
                        disabled={id === 0}
                        variant="outline-primary"
                        className="submit-btn ml-5"
                        name="submit"
                        onClick={cancelRFPHandler}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        <Modal
          size="lg"
          show={isPopoverOpen}
          onHide={() => setIsPopoverOpen(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Cancel RFP</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              Are you sure you want to cancel the RFP? You might be charged a
              penalty for cancellation.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setIsPopoverOpen(false)}>
              Close
            </Button>
            <Button variant="danger" onClick={cancelRFPRequest}>
              Cancel RFP
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default AddRFP;
