import React from "react";
import {BounceLoader} from "react-spinners"
import { css } from "@emotion/react";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top:20%
`;
 
const Loader = ({loading})=>{
 
    return (
      <div className="sweet-loading">
        <BounceLoader
          css={override}
          size={50}
          color={"#2e302e"}
          loading={loading}
        />
      </div>
    );
  }

  export {
      Loader
  }

