import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { selectFilter,textFilter } from 'react-bootstrap-table2-filter';
import { UserContext } from "../utils/UserContext";
import Alert from "react-bootstrap/Alert";
import { userQueries } from "../modules/Auth/_redux/authCrud";
import axios from "axios";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

import Button from "react-bootstrap/Button";
import { Loader } from "../components/toast/Loader";
import moment from "moment";
export class UserContactUs extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  
  static contextType = UserContext;
  constructor(props) {
    super(props);
    const { location } = this.props;
    this.state = {
      pageLoading: true,
      userList: [],
      columns: [
        {
          dataField: "username",
          text: "Name",
          formatter: this.GetNullText,
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
          sort: true,
        },
        {
          dataField: "email",
          text: "Email",
          formatter: this.GetNullText,
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
          sort: true,
        },
        {
          dataField: "phone",
          text: "Mobile",
          formatter: this.GetNullText,
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "subject",
          text: "Status",
          formatter: this.GetNullText,
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "comment",
          text: "Query",
          formatter: this.GetNullText,
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "created_at",
          text: "Date",
          formatter: this.DateFormatter,
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
            },
          }),
          sort: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "isresolved",
          text: "Status",
          filter: selectFilter({
            options: resolvedSelectOptions,
            placeholder:"Select",
            defaultValue:location.defaultSelect === undefined ? "" : location.defaultSelect,
          }),
          formatter: this._changeResolvedStatus,
          align: "center",
          headerAlign: "center",
        },
      ],
    };
  }

  _changeResolvedStatus = (cell, row) => {
    if (row.isresolved === true) {
      return <p>resolved by {row.resolvedby}</p>;
    } else {
      return (
        <div>
          <Button
            type="button"
            size="sm"
            className="bRadius6"
            onClick={() => this._onClickMarkResolved(row)}
          >
            Mark Resolved
          </Button>
        </div>
      );
    }
  };

  _onClickMarkResolved = async (row) => {
    try {
      this.setState({
        pageLoading: true,
      });
      const res = await axios.post(`/api/admin/contactUs/${row.id}`);
      console.log(res);
      if (res.status === 200) {
        this.setState( {
            userList:res.data.response,
            pageLoading: false,
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({
        pageLoading: false,
      });
    }
  };

  componentDidMount() {
    if (this.context.user.get().type !== "Admin") {
      window.history.back();
    } else {
      userQueries().then((res) => {
        this.setState({
          userList: res.response,
          pageLoading: false,
        });
      });
    }
  }

  GetNullText(cell, row) {
    if (this.dataField.includes(".")) {
      let field = this.dataField.split(".");
      return row[field[0]][field[1]];
    }
    if (row[this.dataField]) {
      return row[this.dataField];
    } else {
      return "NA";
    }
  }

  DateFormatter(cell, row) {
    if (row[this.dataField]) {
      return moment(row[this.dataField]).format("lll");
    } else {
      return "NA";
    }
  }

  render() {
    const options = {
      page: 1,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "20",
          value: 20,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "All",
          value: this.state.userList.length,
        },
      ],
      sizePerPage: 5,
      pageStartIndex: 1,
      paginationSize: 10,
      prePage: "Prev",
      nextPage: "Next",
      firstPage: "First",
      lastPage: "Last",
      paginationPosition: "bottom",
    };

    if (this.state.pageLoading) {
      return (
        <>
          <Loader loading={this.state.pageLoading} />
        </>
      );
    } else {
      return (
        <>
          {this.state.userList.length > 0 ? (
            <>
              <div className="container">
                <BootstrapTable
                  id="userTable"
                  striped
                  hover
                  keyField="id"
                  data={this.state.userList}
                  columns={this.state.columns}
                  filter={ filterFactory() }
                  pagination={
                    this.state.userList.length > 5
                      ? paginationFactory(options)
                      : false
                  }
                />
              </div>
            </>
          ) : (
            <>
              <p>
                <Alert variant="success">
                  <Alert.Heading>User List</Alert.Heading>
                  <p>No Queries found.</p>
                </Alert>
              </p>
            </>
          )}
        </>
      );
    }
  }
}
export default withRouter(UserContactUs);



const resolvedSelectOptions = {
  "true": 'Closed',
  "false": 'Open'
};
