import React from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { registerOtp,sendOtp } from "../_redux/authCrud";
// import { login } from "../_redux/authCrud";

function RegistrationOtp(props) {
  let history = useHistory();
  const initialValues = {
    ...JSON.parse(sessionStorage.getItem("REGISTER")),
    otp: "",
    acceptTerms: false,
  };

  // const [otp,setOTP] = useState()
  const { intl } = props;
 
//  useEffect( ()=>{
//     let tempOTP = "";
//     tempOTP = JSON.parse(sessionStorage.getItem("OTP")).otpvalue
//     setOTP(tempOTP);
//   },[])

  //const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    companyName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    mobile: Yup.string()
      // .email("Wrong email format")
      .min(10, "Minimum 3 symbols")
      .max(15, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    otp: Yup.string()
    .matches(/^[0-9]+$/, "Please enter valid OTP.")
    .min(6, 'Please enter six-digit OTP.')
    .max(6, 'Please enter six-digit OTP.')
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions"
    ),
  });

  // const enableLoading = () => {
  //   setLoading(true);
  // };

  // const disableLoading = () => {
  //   setLoading(false);
  // };

  const resendOTP =()=>{
    //console.log(12345656,);
    sendOtp(initialValues.mobile)
    .then(async (data) => {
     console.log(data);
    }).catch((e) => {
      console.log("Failwed Res")
      console.log(e);
    })
  }

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log(props);
      //enableLoading();
      registerOtp(values.name, values.email, values.mobile, values.companyName, values.otp)
        .then((data) => {
          if(data.status === "fail"){
              setStatus(
              intl.formatMessage({
                id: data.msg,
              })
              
            );
           // history.push('/auth/registration');
          }else{
             if(data.response){
            console.log(data.response.token);
            localStorage.setItem('AUTH_TOKEN', data.response.token);
            //disableLoading();
            window.location.reload(); 
            history.push('/dashboard');
          }else{
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INCORRECT_OTP",
              })
            );
          }
          }
        })
    },
  });


  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your details to create your account
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Name"
            type="text"
            disabled
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "name"
            )}`}
            name="name"
            {...formik.getFieldProps("name")}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.name}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            disabled
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Mobile"
            type="text"
            disabled
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "mobile"
            )}`}
            name="mobile"
            {...formik.getFieldProps("mobile")}
          />
          {formik.touched.mobile && formik.errors.mobile ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.mobile}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Company Name"
            type="text"
            disabled
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "companyName"
            )}`}
            name="companyName"
            {...formik.getFieldProps("companyName")}
          />
          {formik.touched.companyName && formik.errors.companyName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.companyName}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="OTP"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "otp"
            )}`}
            name="otp"
            {...formik.getFieldProps("otp")}
          />
          {formik.touched.otp && formik.errors.otp ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.otp}</div>
            </div>
          ) : null}
        </div>

        {/* displaying OTP on registration page */}
        {/* <p>{otp}</p> */}
        {/* end displaying OTP on registration page */}

        <div className="form-group d-flex flex-wrap flex-center">
        <Link
            onClick={()=>{
              resendOTP()
            }}>
            <span>Resend OTP</span>
          </Link>
          <button
            type="submit"
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {/* {loading && <span className="ml-3 spinner spinner-white"></span>} */}
          </button>
         
          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(RegistrationOtp));



