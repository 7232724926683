import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import BonoloField from "../../BonoloField";
import { Form, Row, Col, Button, InputGroup, Modal } from "react-bootstrap";

function InputModal(props) {
  const priceSchema = Yup.object().shape({
    newBidPrice: Yup.number()
      .required("This field is required.")
      .typeError("Must be only digits.")
      .min(1, "Minimum amount can not less than 1."),
  });
  const [show, setShow] = useState(props.show);
  // const [inputDisabled, setInputDisabled] = useState("Bid");
  const initialValues = {
    newBidPrice: props.bidPrice,
    userBidStatus: true,
  };

  const submitHandler = (values) => {
    console.log("Submit Pressed");
    props.callBack(props.bidId, values.newBidPrice, values.userBidStatus, 1);
    handleClose();
  };

  

  const handleClose = () => {
    props.callBack("", "", "Active", 0);
    setShow(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="sm">
      <Modal.Header closeButton>
        <Modal.Title>Current bid: {props.bidPrice}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={priceSchema}
          onSubmit={submitHandler}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col className="pl-3">
                  <InputGroup>
                    <Form.Group>
                      <BonoloField
                        disabled={true}
                        label="New Bid: "
                        styleClass="black-field"
                        name="newBidPrice"
                        formik={formik}
                      />
                    </Form.Group>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputGroup>
                    <Form.Group>
                      <BonoloField
                        name="userBidStatus"
                        id="userBidStatus"
                        label="User Bid Status"
                        type="active_inactive"
                        formik={formik}
                      />
                    </Form.Group>
                  </InputGroup>
                </Col>
              </Row>
              <Modal.Footer>
                <Button
                  variant="primary"
                  name="submit"
                  className="submit-btn"
                  type="submit"
                >
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default InputModal;
