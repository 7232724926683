import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
//import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import { UserContext } from "../../utils/UserContext";
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
import { Loader } from '../../components/toast/Loader';
export class ShowAllRFP extends Component {

  static contextType = UserContext;
  //static rfpFlag;
  constructor(props) {
    super(props);
    // console.log(window.location.href.split("/").includes("all"));
    this.GetActionFormat = this.GetActionFormat.bind(this);
    this.state = {
      pageLoading: true,
      userObj: {},
      rfpList: [],
      columns: [
        {
          dataField: 'id',
          text: 'ID',
          formatter: this.GetNullText,
          //filter: textFilter(),
          sort: true,
          hidden: true
        }, {
          dataField: 'title',
          text: 'Title',
          formatter: this.GetNullText,
          //filter: textFilter(),
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              // console.log(row);
              window.location.href = `viewRFP/${row.id}`
            },
          },
          sort: true
        }, {
          dataField: 'product_name',
          text: 'Product Name',
          formatter: this.GetNullText,
          //filter: textFilter(),
          sort: true
        }, {
          dataField: 'status',
          text: 'Status',
          formatter: this.GetNullText,
          //filter: textFilter(),
          sort: true
        }, {
          dataField: 'auction_start',
          text: 'Auction Date',
          formatter: this.DateFormatter,
          //filter: textFilter(),
          sort: true
        }
      ],
      columns_admin: [
        {
          dataField: "",
          text: "Auction",
          formatter: this.GetActionFormat3
        }
      ],
      columns_user: [
        {
          dataField: "",
          text: "EOI",
          formatter: this.GetActionFormat2
        }
      ],
      columns_all_2: [
        {
          dataField: "",
          text: "Action",
          formatter: this.GetActionFormat
        }
      ]
    }
  }
  DateFormatter(cell, row) {
    if (row[this.dataField]) {
      return moment(row[this.dataField]).format('lll');
    } else {
      return "To be decided";
    }
  }
  componentDidMount() {
    const { user } = this.context;
    const userObj = user.get();
    // console.log(this.rfpFlag);

    this.setState({
      columns: this.state.columns.concat(this.state.columns_user)
    });

    this.setState({
      userObj: userObj
    });

    axios.get('/api/rfp/listUserRFP', { params: { filter: "RFP_WITHOUT_EOI" } })
      .then(res => {
        console.log(res.data);
        this.setState({
          rfpList: res.data.response,
          pageLoading: false
        });
      });

  }

  GetActionFormat(cell, row) {
    return (
      <div>
        <Button type="button" size="sm" href={"/editRFP/" + row.id} >Edit</Button>
      </div>
    );
  }

  GetActionFormat2(cell, row) {
    return (
      <div>
        <Button type="button" className="eoi-btn" size="sm" href={"/addEOI/0/" + row.id} >Add EOI</Button>
      </div>
    );
  }

  GetActionFormat3(cell, row) {
    return (
      <div>
        <Button type="button" size="sm" href={"/addAuction/0/" + row.id} >Auction</Button>
      </div>
    );
  }

  GetNullText(cell, row) {
    //console.log(this.dataField,row);
    if (row[this.dataField]) {
      return row[this.dataField];
    } else {
      return "NA";
    }
  }
  // setAddDiv(type) {
  //   if(type === 'User'){
  //     return (
  //         <div style={{ display: "flex" }}>
  //           <Button style={{ marginLeft: "auto" }} size="sm" href="/addRFP">ADD</Button>
  //         </div>
  //     );
  //   } else {
  //     return '';
  //   }
  // }

  render() {

    const options = {
      page: 1,
      sizePerPageList: [
        {
          text: '5', value: 5
        }, {
          text: '10', value: 10
        }, {
          text: 'All', value: this.state.rfpList.length
        }
      ],
      sizePerPage: 5,
      pageStartIndex: 1,
      paginationSize: 100,
      prePage: 'Prev',
      nextPage: 'Next',
      firstPage: 'First',
      lastPage: 'Last',
      paginationPosition: 'bottom'
    };


    if (this.state.pageLoading) {
      return (
        <Loader loading={this.state.pageLoading} />
      )
    } else {

      return (
        <>
          {
            (this.state.rfpList.length > 0) ? (
              <>
                <div className="container">
                  <BootstrapTable
                    hover
                    keyField='id'
                    id="showAllRfpTable"
                    data={this.state.rfpList}
                    columns={this.state.columns}
                    pagination={(this.state.rfpList.length > 5) ? paginationFactory(options) : false}
                  />
                </div>
              </>
            ) : (
                <>
                  <p>   <Alert variant="success">
                    <Alert.Heading>All RFP List</Alert.Heading>
                    <p>
                      No RFP found.
  </p>
                  </Alert></p>
                </>
              )
          }
        </>
      )
    }
  }
}
export default ShowAllRFP
