import axios from 'axios';

export default function setupAxios() {
  axios.interceptors.request.use(
    config => {
      const authToken = localStorage.getItem("AUTH_TOKEN") 

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
}
