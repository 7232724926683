import axios from "axios";
import React, { Component } from "react";
import { Alert, Button, Modal, Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { withRouter } from "react-router";
import PropTypes from "prop-types";

// Custom imports
import { Loader } from "../../components/toast/Loader";

export class ListOrganization extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    // binding this
    this.GetActionFormat = this.GetActionFormat.bind(this);
    this.GetUserFormat = this.GetUserFormat.bind(this);
    this.CreateRFPFormat = this.CreateRFPFormat.bind(this);
    this.toAddNewOrganisation = this.toAddNewOrganisation.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.mergeSubmit = this.mergeSubmit.bind(this);
    this.primaryOrgSelect = this.primaryOrgSelect.bind(this);

    this.state = {
      pageLoading: true,
      showMergeModal: false,
      selectMergeOrgs: {
        count: 0,
        list: [],
        id: [],
        rowIndexes: [],
      },
      mergePrimaryOrg: 0,
      primaryOrg: null,
      organizationList: [],
      mergeOrgColumns: [],

      columns: [
        {
          dataField: "name",
          text: "Organizations Name",
          headerStyle: () => {
            return { width: "30 %" };
          },
          formatter: this.GetNullText,
          filter: textFilter({
            placeholder: "Search",
            style: {
              boxSizing: "border-box",
              margin: "auto",
              width: "80%",
            },
          }),
          align: "center",
          headerAlign: "center",
          sort: true,
        },
        {
          dataField: "address",
          text: "Address",
          headerStyle: () => {
            return { width: "30%" };
          },
          formatter: this.GetNullText,
          filter: textFilter({
            placeholder: "Address",
            style: {
              boxSizing: "border-box",
              margin: "auto",
              width: "100%",
            },
          }),

          headerAlign: "center",
          align: "center",
          sort: true,
        },
        {
          dataField: "",
          text: "Action",
          formatter: this.GetActionFormat,
          align: "center",
          headerAlign: "center",
          hidden: 
            this.props.setIdToUpperComponent &&
            typeof this.props.setIdToUpperComponent === "function"
            ? true : false
        },
        {
          dataField: "",
          text: "Users",
          formatter: this.GetUserFormat,
          align: "center",
          headerAlign: "center",
          hidden: 
            this.props.setIdToUpperComponent &&
            typeof this.props.setIdToUpperComponent === "function"
            ? true : false
        },
        {
          dataField: "",
          text: "Cretae RFP",
          formatter: this.CreateRFPFormat,
          align: "center",
          headerAlign: "center",
        },
      ],
      hiddenColumns: [
        //For Searching Purpose
        {
          dataField: "pincode",
          text: "Pincode",
          hidden: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "type",
          text: "Type",
          hidden: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "GST",
          text: "GST",
          hidden: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "turnover",
          text: "Turnover",
          hidden: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "startYear",
          text: "Start Year",
          hidden: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "client_list",
          text: "client list",
          hidden: true,
        },
        {
          dataField: "website",
          text: "Website",
          hidden: true,
          align: "center",
          headerAlign: "center",
        },
        {
          dataField: "industrysector",
          text: "Industry sector",
          hidden: true,
          align: "center",
          headerAlign: "center",
        },
      ],
    };
  }

  componentDidMount() {
    if (
      this.props.setIdToUpperComponent &&
      typeof this.props.setIdToUpperComponent === "function"
    ) {
      // In this case component will be child of ListRFP component
      // This component will be used to select the org for creating
      // EOI for a particular rfp (Selected from rfp list)
      // Props setIdToUpperComponent will be used to set the org Id
      // in parent RFP List Component
      let colums = this.state.columns;
      colums[4] = {
        dataField: "",
        text: "Select Org",
        headerAlign: "center",
        align: "center",
        formatter: (cell, row) =>
          this.SelectOrgFormat(cell, row, this.props.setIdToUpperComponent),
      };
      this.setState({ columns: colums });
    }
    this.setState({
      columns: [...this.state.columns, ...this.state.hiddenColumns],
    });

    const { location } = this.props;
    if (location.defaultSelect === "true") {
      _fetchOrganization((err, res) => {
        if (err === null) {
          this.setState({
            organizationList: res,
            pageLoading: false,
          });
        } else {
          this.setState({
            pageLoading: false,
          });
        }
      });
    } else {
      axios
        .get("/api/organization/listOrganization")
        .then((res) => {
          console.log(res.data);
          this.setState({
            organizationList: res.data.response,
            pageLoading: false,
          });
        })
        .catch(() => {
          this.setState({
            pageLoading: false,
          });
        });
    }
  }

  GetActionFormat(cell, row) {
    return (
      <div>
        <Button disabled={this.state.selectMergeOrgs.count !== 0} type="button" size="sm" href={"/updateOrganisation/" + row.id}>
          Edit
        </Button>
      </div>
    );
  }

  GetUserFormat(cell, row) {
    return (
      <div>
        <Button disabled={this.state.selectMergeOrgs.count !== 0} type="button" size="sm" href={"/userList/" + row.id}>
          Users
        </Button>
      </div>
    );
  }

  CreateRFPFormat(cell, row) {
    return (
      <div>
        <Button disabled={this.state.selectMergeOrgs.count !== 0} type="button" size="sm" href={"/admin/createRFP/" + row.id}>
          Create RFP
        </Button>
      </div>
    );
  }

  SelectOrgFormat(cell, row, setIdToUpperComponent) {
    return (
      <div>
        <Button
          type="button"
          size="sm"
          onClick={() => {
            setIdToUpperComponent(row.id);
          }}
        >
          Select Org
        </Button>
      </div>
    );
  }

  GetNullText(cell, row) {
    //console.log(this.dataField,row);
    if (row[this.dataField]) {
      return row[this.dataField];
    } else {
      return "NA";
    }
  }

  toAddNewOrganisation() {
    this.props.history.push("/updateOrganisation/");
  }

  toMergeOrganizations() {
    this.props.history.push("/mergeOrganizations");
  }

  onRowSelect(row, isSelect, rowIndex, e) {
    // console.log(this.state.organizationList);

    if (isSelect) {
      if (this.state.selectMergeOrgs.count !== 2) {
        this.setState(
          (state) => ({
            selectMergeOrgs: {
              count: state.selectMergeOrgs.count + 1,
              list: [...state.selectMergeOrgs.list, row.name],
              id: [...state.selectMergeOrgs.id, row.id],
              rowIndexes: [...state.selectMergeOrgs.rowIndexes, rowIndex],
            },
          }),
          () => console.log(this.state.selectMergeOrgs)
        );
      } else {
        return false;
      }
    }

    if (!isSelect) {
      this.setState(
        (state) => ({
          selectMergeOrgs: {
            count: state.selectMergeOrgs.count - 1,
            list: state.selectMergeOrgs.list.filter(
              (orgName) => orgName !== row.name
            ),
            id: state.selectMergeOrgs.id.filter((id) => id !== row.id),
            rowIndexes: state.selectMergeOrgs.rowIndexes.filter(
              (i) => i !== rowIndex
            ),
          },
        }),
        () => console.log(this.state.selectMergeOrgs)
      );
    }
    console.log(this.state);
  }

  mergeSubmit() {
    let primaryId = this.state.selectMergeOrgs.id[this.state.primaryOrg];
    let secondaryId = this.state.selectMergeOrgs.id.filter(
      (id) => id !== primaryId
    )[0];
    console.log(primaryId, secondaryId);
    let url = "/api/organization/mergeOrganization";
    axios
      .post(url, { primaryId: primaryId, secondaryId: secondaryId })
      .then((res) => {
        if (res.data.success) {
          alert("Organizations merged Successfully");
          window.location.reload();
        } else {
          alert("Merge Unsuccessful");
          window.location.reload();
        }
      })
      .catch((err) => {
        alert("Merge Unsuccessful");
        console.error(err);
      });
  }

  primaryOrgSelect(row, isSelect, rowIndex, e) {
    this.setState({
      primaryOrg: rowIndex,
    });
  }

  render() {
    const options = {
      page: 1,
      sizePerPageList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "All",
          value: this.state.organizationList.length,
        },
      ],
      sizePerPage: 5,
      pageStartIndex: 1,
      paginationSize: 10,
      prePage: "Prev",
      nextPage: "Next",
      firstPage: "First",
      lastPage: "Last",
      paginationPosition: "inline",
    };

    if (this.state.pageLoading) {
      return <Loader loading={this.state.pageLoading} />;
    } else {
      return this.state.organizationList.length > 0 ? (
        <>
          <div className="container align-right">
            {!(
              this.props.setIdToUpperComponent &&
              typeof this.props.setIdToUpperComponent === "function"
            ) ? (
              <div>
                <Button
                  className="add-organization-btn"
                  size="sm"
                  disabled={
                    this.state.selectMergeOrgs.count === 2 ? false : true
                  }
                  onClick={() =>
                    this.setState({
                      showMergeModal: true,
                    })
                  }
                >
                  Merge Organizations
                </Button>

                <Button
                  className="add-organization-btn"
                  size="sm"
                  disabled={
                    this.state.selectMergeOrgs.count === 0 ? false : true
                  }
                  onClick={this.toAddNewOrganisation}
                >
                  Add Organization
                </Button>
              </div>
            ) : null}
            <BootstrapTable
              key={this.state.selectMergeOrgs.count}
              keyField="id"
              striped
              hover
              columns={this.state.columns}
              data={this.state.organizationList}
              filter={filterFactory()}
              pagination={paginationFactory(options)}
              id="listOrganizationTable"
              headerClasses="header-classasx"
              selectRow={{
                mode: "checkbox",
                bgColor: "grey",
                style: { color: "white" },
                clickToSelect: false,
                hideSelectAll: true,
                selected: this.state.selectMergeOrgs.id,
                onSelect: this.onRowSelect,
                hideSelectColumn: this.props.setIdToUpperComponent && typeof this.props.setIdToUpperComponent === "function" ? true : false
              }}
              
            />
          </div>
          {this.state.showMergeModal && (
            <Modal
              size="lg"
              show={this.state.showMergeModal}
              onHide={() =>
                this.setState((state) => ({
                  showMergeModal: false,
                  primaryOrg: null,
                }))
              }
            >
              <Modal.Body>
                <BootstrapTable
                  keyField="id"
                  striped
                  hover
                  columns={[
                    {
                      dataField: "name",
                      text: "Select Primary Organization",
                      headerStyle: () => {
                        return { width: "30 %" };
                      },
                      formatter: this.GetNullText,

                      align: "center",
                      headerAlign: "center",
                      sort: true,
                    },
                  ]}
                  data={[
                    this.state.organizationList[
                      this.state.selectMergeOrgs.rowIndexes[0]
                    ],
                    this.state.organizationList[
                      this.state.selectMergeOrgs.rowIndexes[1]
                    ],
                  ]}
                  id="listOrganizationTable"
                  headerClasses="header-classasx"
                  selectRow={{
                    mode: "radio",
                    bgColor: "grey",
                    style: { color: "white" },
                    clickToSelect: true,
                    hideSelectAll: true,
                    onSelect: this.primaryOrgSelect,
                  }}
                />
              </Modal.Body>
              <Modal.Footer style={{ backgroundColor: "#E5E8F1" }}>
                <Row>
                  <Col className="text-danger">
                    Note: All the Users, RFPs, and EOIs will be added to
                    selected primary organization. The seond organization will
                    be deleted.
                  </Col>
                  <Col>
                    <Button
                      disabled={this.state.primaryOrg === null ? true : false}
                      onClick={this.mergeSubmit}
                    >
                      Merge
                    </Button>
                  </Col>
                </Row>
              </Modal.Footer>

              {/* <Formik
                initialValues={{
                  primaryOrg: "",
                }}
                enableReinitialize={true}
                onSubmit={this.mergeSubmit}
              >
                {(formik) => (
                  <Form>
                    <Modal.Body></Modal.Body>
                    <Modal.Footer style={{ backgroundColor: "#E5E8F1" }}>
                      <input
                        type="radio"
                        name="primaryOrg"
                        className="merge-radio"
                        value={
                          this.state.organizationList[
                            this.state.selectMergeOrgs.rowIndexes[0]
                          ].id
                        }
                      />
                      {
                        this.state.organizationList[
                          this.state.selectMergeOrgs.rowIndexes[0]
                        ].name
                      }

                      <input
                        type="radio"
                        className="merge-radio"
                        name="primaryOrg"
                        value={
                          this.state.organizationList[
                            this.state.selectMergeOrgs.rowIndexes[1]
                          ].id
                        }
                      />
                      {
                        this.state.organizationList[
                          this.state.selectMergeOrgs.rowIndexes[1]
                        ].name
                      }
                      <Button
                        type="submit"
                        name="submit"
                        className="btn btn-primary btn-sm"
                      >
                        Merge
                      </Button>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik> */}

              {/*   <Modal.Header closeButton>
                 <Modal.Title>
                  {this.state.selectMergeOrgs.count
                    ? "Selected orgs: " +
                      this.state.selectMergeOrgs.list.reduce(
                        (string, name) => string + ", " + name
                      )
                    : "Select two organizations two merge."}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <BootstrapTable
                  keyField="id"
                  striped
                  hover
                  columns={this.state.mergeOrgColumns}
                  data={this.state.organizationList}
                  filter={filterFactory()}
                  pagination={paginationFactory(options)}
                  id="listOrganizationTableMerge"
                  headerClasses="header-classasx"
                  selectRow={{
                    mode: "checkbox",
                    bgColor: "#187de4",
                    style: { color: "white" },
                    clickToSelect: true,
                    hideSelectAll: true,
                    onSelect: this.onRowSelect,
                  }}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  disabled={
                    this.state.selectMergeOrgs.count === 2 ? false : true
                  }
                  onClick={() =>
                    this.props.history.push(
                      `/admin/mergeOrganizations/${this.state.selectMergeOrgs.id[0]}/${this.state.selectMergeOrgs.id[1]}`
                    )
                  }
                  className="btn btn-primary btn-lg"
                >
                  Merge
                </Button>
              </Modal.Footer> */}
            </Modal>
          )}
        </>
      ) : (
        <>
          <p>
            <Alert variant="success">
              <Alert.Heading>Organization List</Alert.Heading>
              <p>No Organization found.</p>
            </Alert>
          </p>
        </>
      );
    }
  }
}
export default withRouter(ListOrganization);

//api for fetching organization count
const _fetchOrganization = async (callback) => {
  try {
    const res = await axios.get("/api/organization/organizationCount", {
      params: {
        q: "unfilled_organization",
      },
    });
    callback(null, res.data);
  } catch (err) {
    callback(err, null);
  }
};
