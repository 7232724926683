import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register,gmailLogin } from "../_redux/authCrud";
import configFile from '../../../../config.js';
import 'bootstrap/dist/css/bootstrap.min.css';

const STATIC_WEB_URL = configFile.STATIC_WEB_URL;

function RegistrationOld(props) {
  const login = {tupe:"OTP"};
  const initialValues = {
    name: "",
    email: "",
    mobile: "",
    companyName: "",
    acceptTerms: false,
  };  
  if(props.location.state){
   console.log(props.location.state.googleLogin);
    initialValues.mobile = props.location.state.number;
    initialValues.email = props.location.state.email;
    initialValues.name = props.location.state.name;
    login.type  = props.location.state.googleLogin;
 }
  useEffect(() => {
   // console.log(props.location.state.number);
    return () => new AbortController().abort();
  })
  const history = useHistory();
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Must be 3-50 characters")
      .max(50, "Must be 3-50 characters")
      .trim()
      .matches(/[a-zA-Z]+$/, "Invalid Name")
      .required("Please enter your name"),
    companyName: Yup.string()
      .min(3, "Must be 3-50 characters")
      .max(50, "Must be 3-50 characters")
      .required("Please enter your company name"),
    email: Yup.string()
      .email("Invalid email address")
      .min(3, "Must be 3-50 characters")
      .max(50, "Must be 3-50 characters")
      .required("Please enter your email address"),
    mobile: Yup.string()
    .matches(/^[0-9]+$/, "Invalid Mobile Number.")
    .typeError("Must be only digits")
    .min(10, 'Enter 10 digit numeric value')
    .max(10, 'Enter 10 digit numeric value')
    .required("Please enter your 10 digit mobile number"),
    acceptTerms: Yup.bool().oneOf([true], "You must accept the terms and conditions"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    
    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      if(login.type === "gmail"){
        console.log(initialValues.gmail);
        //initialValues.gmail;
        
        gmailLogin(values).then((res)=>{
          console.log(res);
          if(res.msg !== "User Register Successful"){
            setStatus(
              intl.formatMessage({
                id:res.msg,
              })
            );
          }else{
            console.log(res.response)
            if(res.response.token){
                console.log(res.response.token);
                localStorage.setItem('AUTH_TOKEN', res.response.token);
                window.location.reload();
                history.push('/dashboard');
            }
          }
          // if(data.response){
          //   console.log(data.response.token);
          //   localStorage.setItem('AUTH_TOKEN', data.response.token);
          //   //disableLoading();
          //   window.location.reload(); 
          //   history.push('/dashboard');
          // }else{
          //   setStatus(
          //     intl.formatMessage({
          //       id: "AUTH.VALIDATION.INCORRECT_OTP",
          //     })
          //   );
          // }
        }).catch((err)=>{
          console.log(err);
        });
        console.log("gmillogin");
      }else{
        register(values.name, values.email, values.mobile, values.companyName)
        .then(async (data) => {
          if(data.success){
              // sessionStorage.setItem("OTP",JSON.stringify({"otpvalue":data.message}))
              sessionStorage.setItem("REGISTER",JSON.stringify(values));
              history.push('/auth/registration-otp');
              disableLoading();
          }else{
              setStatus(
                intl.formatMessage({
                  id:data.message,
                })
              );
              disableLoading();
          }
        }).catch((e) => {
          console.log("Failwed Res")
          console.log(e);
        })
      }
    },
  });


  // return 

  return (
    <>
    <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
      <span className="font-weight-bold text-dark-50">Already have an account?</span>
      <Link to="/auth/login#" className="font-weight-bold ml-2" id="kt_login_signup">Sign In!</Link>
    </div>
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">Enter your details to create your account</p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}
        {(login.type === "gmail")?(
           <>
           {/* begin: name */}
      {/* <div className="form-group fv-plugins-icon-container">
        <input
          placeholder="Name"
          type="text"
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
            "name"
          )}`}
          name="name"
          autoFocus={true}
          {...formik.getFieldProps("name")}
        />
        {formik.touched.name && formik.errors.name ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.name}</div>
          </div>
        ) : null}
      </div> */}
      {/* end: name */}

      {/* begin: email */}
      {/* <div className="form-group fv-plugins-icon-container">
        <input
          placeholder="Email"
          type="email"
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
            "email"
          )}`}
          name="email"
          {...formik.getFieldProps("email")}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.email}</div>
          </div>
        ) : null}
      </div> */}
      {/* end: email */}
      
      

      {/* begin: Mobile */}
      <div className="form-group fv-plugins-icon-container">
        <input
          placeholder="Mobile"
          type="text"
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
            "mobile"
          )}`}
          name="mobile"
          {...formik.getFieldProps("mobile")}
        />
        {formik.touched.mobile && formik.errors.mobile ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.mobile}</div>
          </div>
        ) : null}
      </div>
      {/* end: Mobile */}

      {/* begin: Company Name*/}
      <div className="form-group fv-plugins-icon-container">
        <input
          placeholder="Company Name"
          type="text"
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
            "companyName"
          )}`}
          name="companyName"
          {...formik.getFieldProps("companyName")}
        />
        {formik.touched.companyName && formik.errors.companyName ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.companyName}</div>
          </div>
        ) : null}
      </div>
      {/* end: Company Name */}
        </>
        ):(
          <>
          {/* begin: name */}
       <div className="form-group fv-plugins-icon-container">
        <input
          placeholder="Name"
          type="text"
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
            "name"
          )}`}
          name="name"
          autoFocus={true}
          {...formik.getFieldProps("name")}
        />
        {formik.touched.name && formik.errors.name ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.name}</div>
          </div>
        ) : null}
      </div> 
      {/* end: name */}

      {/* begin: email */}
       <div className="form-group fv-plugins-icon-container">
        <input
          placeholder="Email"
          type="email"
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
            "email"
          )}`}
          name="email"
          {...formik.getFieldProps("email")}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.email}</div>
          </div>
        ) : null}
      </div> 
      {/* end: email */}
          <div className="form-group fv-plugins-icon-container">
        <input
          placeholder="Mobile"
          type="text"
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
            "mobile"
          )}`}
          name="mobile"
          {...formik.getFieldProps("mobile")}
        />
        {formik.touched.mobile && formik.errors.mobile ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.mobile}</div>
          </div>
        ) : null}
      </div>
      {/* end: Mobile */}

      {/* begin: Company Name*/}
      <div className="form-group fv-plugins-icon-container">
        <input
          placeholder="Company Name"
          type="text"
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
            "companyName"
          )}`}
          name="companyName"
          {...formik.getFieldProps("companyName")}
        />
        {formik.touched.companyName && formik.errors.companyName ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.companyName}</div>
          </div>
        ) : null}
      </div>
      {/* end: Company Name */}
        </>
        )}
      
        {/* begin: Terms and Conditions */}
        <div className="form-group">
        <div className="input-checkbox-outer">
          <input
            type="checkbox"
            name="acceptTerms"
            className="input-checkbox"
            {...formik.getFieldProps("acceptTerms")}
          />
          <label className="checkbox">I accept <a href={`${STATIC_WEB_URL}/terms-conditions.html`}>terms and conditions</a></label>
          </div>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </div>
    </>
  );
}
export default injectIntl(connect(null, auth.actions)(RegistrationOld));
