import React, { useState, useEffect, useContext } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import Dropdown from 'react-bootstrap/Dropdown';
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import BonoloField from "../../BonoloField";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { Loader } from "../../components/toast/Loader";
import { UserContext } from "../../utils/UserContext";
//const imgStyle =
const initialValues = {
  name: "",
  address: "",
  pincode: "",
  industrySector: "",
  type: "",
  turnover: 0,
  startYear: "",
  GST: "",
  client_list: "",
  website: "",
  categoryIds: [-1],
  imageUrl: "",
  updatedFlag: 1,
};

const OragnisationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Must be 3-100 characters")
    .max(100, "Must be 3-100 characters")
    .typeError("(*)Marked fields are required")
    .required("(*)Marked fields are required"),
  address: Yup.string()
    .min(5, "Must be 5-120 characters")
    .max(120, "Must be 5-120 characters")
    .typeError("(*)Marked fields are required")
    .required("(*)Marked fields are required"),
  pincode: Yup.string()
    .min(6, "Must be 6 digit numeric value")
    .max(6, "Must be 6 digit numeric value")
    .matches(/^[0-9]/, "Enter Valid Pincode")
    .required("(*)Marked fields are required")
    .typeError("Must be numeric values"),
  industrysector: Yup.string()
    .min(5, "Must be 5-120 characters")
    .max(120, "Must be 5-120 characters")
    .required("(*)Marked fields are required")
    .typeError("(*)Marked fields are required"),
  type: Yup.string()
    .required("(*)Marked fields are required")
    .typeError("(*)Marked fields are required"),
  turnover: Yup.string()
    .required("(*)Marked fields are required")
    .typeError("(*)Marked fields are required"),
  startYear: Yup.string()
    .min(4, "Must be 4 digit numeric value")
    .max(4, "Must be 4 digit numeric value")
    .required("(*)Marked fields are required")
    .typeError("Must be numeric value"),
  GST: Yup.string()
    .min(15, "Must be 15 alphanumeric value")
    .max(15, "Must be 15 alphanumeric value")
    .matches(/^[a-zA-Z0-9]+$/, "Enter valid GST number")
    .required("(*)Marked fields are required")
    .typeError("Must be alphanumeric value"),
  categoryIds: Yup.array().required("(*)Marked fields are required"),
  // website: Yup.string()
  //   .matches(
  //     /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  //     "Must be valid website url"
  //   )
  //   .required("(*)Marked fields are required")
  //   .typeError("Must be valid website url"),
});

function UpdateOrganisation(props) {
  let history = useHistory();
  const [multiSelections, setMultiSelections] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const { user } = useContext(UserContext);
  const userObj = user.get() || "";
  let id = 0;
  if (props.match.params.id !== undefined) {
    id = props.match.params.id;
  }

  const [orgData, setOrgData] = useState({});
  const [categoriesData, setCategoriesData] = useState({});
  const [base64, setbase64] = useState("/media/custom/NoImage.jpg");
  useEffect(() => {
    if (id !== 0 && userObj.type === "User") {
      history.push("/updateOrganisation");
    } else if(id === 0 && userObj.type === "Admin"){

      const fetchData = async () => {
        let catUrl = "/api/organization/getCategories";
        const catRes = await axios.get(catUrl);
        setCategoriesData(catRes.data.response);
        setPageLoading(false);
      };
      fetchData();
      
    } else {
      const fetchData = async () => {
        let url = "/api/organization/getUserOrganization";
        if (id !== 0) {
          url = `/api/organization/getOrganization?id=${id}`;
        }
        const res = await axios.get(url);
        if (res.data.response.imageUrl) {
          let path = `/uploads/${res.data.response.imageUrl}`;
          setbase64(path);
        }
        let catUrl = "/api/organization/getCategories";
        const catRes = await axios.get(catUrl);
        setCategoriesData(catRes.data.response);
        setOrgData(res.data.response);
        setPageLoading(false);
        let selectedCategory = res.data.response.categoryIds;
        let temp = [];
        for (let index = 0; index < selectedCategory.length; index++) {
          const element = selectedCategory[index];
          let selectedObj = _.find(catRes.data.response, { id: element });
          temp.push(selectedObj);
        }
        setMultiSelections(temp);
      };
      fetchData();
    }
  }, [id, history, userObj.type]);

  const submitHandler = (values, { setStatus, setSubmitting }) => {
    const file = document.getElementById("org_img").files[0];
    setSubmitting(true);
    let updateUrl = "/api/organization/updateOwnOrganization";
    if (id !== 0) {
      updateUrl = "/api/organization/updateOrganization";
    }
    if (multiSelections.length > 0) {
      let categories = multiSelections.reduce((accu, currentIndex) => {
        accu.push(currentIndex.id);
        return accu;
      }, []);
      values.categoryIds = categories;
    }

    // console.log(categories,values);
    axios
      .post(updateUrl, values)
      .then((res) => {
        if (res.status === 200) {
          if (typeof file !== "undefined") {
            setPageLoading(false);
            const formData = new FormData();
            formData.append("file", file, "org_img_" + Date.now());
            axios
              .post("/api/organization/updateOrgImage", formData)
              .then((res) => {
                setStatus("success");
                setSubmitting(false);
                alert("Organization Added/Updated.");
                if (id !== 0) {
                  history.push("/admin/listOrganizations");
                } else {
                  history.push("/dashboard");
                }
              });
          } else {
            setStatus("success");
            setSubmitting(false);
            alert("Organization Added/Updated.");
            if (id !== 0) {
              history.push("/admin/listOrganizations");
            } else {
              history.push("/dashboard");
            }
          }
        } else {
          setStatus("failure");
          setPageLoading(false);
        }
      })
      .catch(() => {
        setPageLoading(false);
      });
  };

  const eventhandler = (data) => {
    const file = document.getElementById("org_img").files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      //console.log("test",reader.result);
      setbase64(reader.result);
      //setFileData(file);
    };
  };

  if (pageLoading) {
    return <Loader loading={pageLoading} />;
  } else {
    return (
      // <FileUpload />

      <>
        <Formik
          enableReinitialize={true}
          initialValues={_.merge({}, initialValues, orgData)}
          validationSchema={OragnisationSchema}
          onSubmit={submitHandler}
        >
          {/* <div> {orgData.imageUrl ? 'currently' : 'not'}</div> */}
          {(formik) => (
            <Form onSubmit={formik.handleSubmit} className="mt-5 p-md-5">
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={2}
                  className="organizationImageContainer"
                >
                  <div>
                    <img src={base64} className="organizationImage" alt="img" />

                    <Form.Group>
                      <label htmlFor="org_img" className="organizationImageLabel">
                        Browse File
                      </label>
                      <Form.File
                        className="position-relative file"
                        name="file"
                        onChange={eventhandler}
                        id="org_img"
                      />
                    </Form.Group>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={10}>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                      <Form.Group>
                        <BonoloField
                          placeholder="For eg., Hindustan Unilever limited"
                          styleClass="prasad lokhande"
                          label="Company Name*"
                          name="name"
                          formik={formik}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                      <Form.Group>
                        <BonoloField
                          placeholder="Select Company Type"
                          type="select"
                          label="Company Type*"
                          name="type"
                          formik={formik}
                          values={[
                            ["", "Select Company Type"],
                            ["others", "Others"],
                            ["proprietorship", "Proprietorship"],
                            ["partnership", "Partnership"],
                            ["pvt_ltd", "Pvt. Ltd."],
                            ["public_ltd", "Public Ltd."],
                            ["govt_enterprise", "Govt. Enterprise"],
                          ]}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                      <Form.Group>
                        <BonoloField
                          placeholder="For eg., https://nestle.in/"
                          label="Company Website"
                          name="website"
                          formik={formik}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                      <Form.Group>
                        <BonoloField
                          placeholder="Enter your 15 digit GSTIN"
                          label="GST Number*"
                          name="GST"
                          formik={formik}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={9} md={9} lg={9} xl={9}>
                  <Form.Group>
                    <BonoloField
                      placeholder="Room No., Building name, Street Name, District, State"
                      styleClass="address"
                      label="Company Address*"
                      name="address"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={3} md={3} lg={3} xl={3}>
                  <Form.Group>
                    <BonoloField
                      placeholder="For eg., 144111"
                      styleClass="Pincode*"
                      label="Pincode *"
                      name="pincode"
                      min="6"
                      max="6"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* horizontal line break */}
              <hr className="hr" />

              <Row>
                <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Form.Group>
                    <BonoloField
                      placeholder="Retail/Manufacturing/Automobile/Agriculture etc."
                      label="Industry Sectors*"
                      name="industrysector"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Form.Group>
                    <BonoloField
                      placeholder="Select turnover range for last year"
                      type="select"
                      label="Company Turnover*"
                      name="turnover"
                      formik={formik}
                      values={[
                        ["0", "Select Turnover Range"],
                        ["1", "0 - 10 Lakh"],
                        ["2", "10 Lakh - 1 Crore"],
                        ["3", "1 - 20 Crore"],
                        ["4", "20 Crore+"],
                      ]}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Form.Group>
                    <BonoloField
                      placeholder="For eg., 1984"
                      label="Year of Establishment*"
                      name="startYear"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Form.Group>
                    <BonoloField
                      placeholder="List out some of your clients whom you serve to"
                      type="textarea"
                      label="Clientele"
                      name="client_list"
                      formik={formik}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Form.Group>
                    <Form.Label>Categories of Interest</Form.Label>
                    <Typeahead
                      contenteditable
                      id="basic-typeahead-multiple"
                      labelKey="name"
                      multiple
                      onChange={setMultiSelections}
                      options={categoriesData}
                      placeholder="Choose all categories that you are willing to sell"
                      selected={multiSelections}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <Button
                      variant="primary"
                      className="submit-btn"
                      name="submit"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default UpdateOrganisation;
