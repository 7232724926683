import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Loader } from "../components/toast/Loader";
import { Link } from "react-router-dom";

import { UserContext } from "../utils/UserContext";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export function DashboardPage() {
  const [myRFP, setMyRFP] = useState(0);
  const [myEOI, setMyEOI] = useState([]);
  const [openRFP, setOpenRFP] = useState([]);
  const [auction, setAuction] = useState([]);
  const [rfpData, setRfpData] = useState([1, 3]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext);
  const userObj = user.get() || "";
  var slider = "";
  
  useEffect(() => {
    
    const fetchData = async () => {
      await axios.get(`/api/rfp/listRFP?allPublished=true`).then((res) => {
        setRfpData(res.data.response);
        setOpenRFP(res.data.response.length);
      });
      await axios.get("/api/eoi/listEOI").then((res) => {
        setMyEOI(res.data.response.length);
      });
      await axios.get("/api/rfp/listUserRFP?showAll=true").then((res) => {
        // setOpenRFP(res.data.response.length);
        if (userObj.type !== "Admin") {
          setMyRFP(res.data.response.length);
        }
      });
      await axios
        .get("/api/auction/listAuction?showtotalauction=true")
        .then((res) => {
          setAuction(res.data.response.length);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  function SliderDom() {
    if (rfpData.length > 0) {
      var loop = 0;
      if (rfpData.length > 5) {
        loop = rfpData.length;
      } else {
        loop = rfpData.length;
      }
      for (let index = 0; index < loop; index++) {
        const element = rfpData[index];
        let imgUrl = element.image_url || "NoImage.jpg";
        let imgSrc = "";
        let temp = imgUrl.split(",");
        if (temp === 1) {
          imgSrc = imgUrl;
        } else {
          imgSrc = temp[0];
        }
        if (userObj.type === "Admin") {
          slider += `<a href="/viewRFP/${element.id}" class="dashboard__slider" ><div class="dashboardSlider row">
        
        <div class="col-12 col-md-3 d-flex align-items-center">
          <img src="/uploads/${imgSrc}" class="sliderImg" alt="tmpimg"/>
        </div>
        <div class="col-12 col-md-9 pt-3 mt-5">
          <h2>${element.title}</h2>
          <p>${element.product_name}</p>
          <h3>Company Name: <span>${element.organization_name}</span></h3>
        </div>
      
    </div></a>`;
        } else {
          slider += `<a href="/viewRFP/${element.id}" class="dashboard__slider"><div class="dashboardSlider row">
        
        <div class="col-12 col-md-3 d-flex align-items-center">
          <img src="/uploads/${imgSrc}" class="sliderImg" alt="tmpimg"/>
        </div>
        <div class="col-12 col-md-9 pt-3 mt-5">
          <h2>${element.title}</h2>
          <p>${element.product_name}</p>
        </div>
      
    </div></a>`;
        }
      }
    }
    if (slider) {
      return (
        <>
          <OwlCarousel
            className="owl-theme"
            autoplay
            loop
            items={1}
            margin={24}
            nav
            navText={[
              '<img src="https://i.ibb.co/ZmWbZyn/leftarrow.png">',
              '<img src="https://i.ibb.co/fYRPSH9/rightarrow.png">',
            ]}
            id="owl-dashboard"
            dangerouslySetInnerHTML={{ __html: slider }}
          ></OwlCarousel>
        </>
      );
    } else {
      return (
        <>
          <Card>
            <Card.Img
              variant="top"
              src="/media/bg/dashboard.png"
              className="img-responsive rfp-card-img"
            />
            <Card.Body>
              <Card.Text>
                <h3>No Ongoing RFP found... Please add RFP in RFP section</h3>
              </Card.Text>
            </Card.Body>
          </Card>
        </>
      );
    }
  }

  if (loading) {
    return <Loader loading={loading} />;
  } else
    return (
      <>
       
          {userObj.type === "User" ? (
            <>
            <Row className="bd-cards">
            <Col xs="12" md="6" xl="3">
              <Link to="listRFP">
                <div class="dash-card mx-auto m-5">
                <div class="content">
                  <div>
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/drfp.svg")} className="icon"/>
                    </div>
                    <div class="ml-2">
                      <h3 class="mb-4">My RFP</h3>
                      <span>{`${myRFP}`}</span>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>

            <Col xs="12" md="6" xl="3">
              <Link to = "/listEOI">
              <div class="dash-card mx-auto m-5">
                <div class="content">
                  <div>
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/deoi.svg")} className="icon"/>
                    </div>
                    <div class="ml-2">
                      <h3 class="mb-4">My EOI</h3>
                      <span>{`${myEOI}`}</span>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>

            <Col xs="12" md="6" xl="3">
              <Link activeClassName="link" to="/allRFP">
              <div class="dash-card mx-auto m-5">
                <div class="content">
                  <div>
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/dopenrfp.svg")} className="icon"/>
                    </div>
                    <div class="ml-2">
                      <h3 class="mb-4">Open RFP</h3>
                      <span>{`${openRFP}`}</span>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>

            <Col xs="12" md="6" xl="3">
             <div class="dash-card mx-auto m-5">
              <div class="content">
                <div>
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Design/dtotalauction.svg")} className="icon"/>
                  </div>
                  <div class="ml-2">
                    <h3 class="mb-4">Total Auction</h3>
                    <span>{`${auction}`}</span>
                  </div>
                </div>
              </div>
            </Col> 
            </Row>  	
            </>
          ) : (
          
            <>
              <Row className="bd-cards">
            <Col xs="12" md="6" xl="3">
              <Link to="listRFP">
                <div class="dash-card mx-auto m-5">
                <div class="content">
                  <div>
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/drfp.svg")} className="icon"/>
                    </div>
                    <div class="ml-2">
                      <h3 class="mb-4">My RFP</h3>
                      <span>{`${myRFP}`}</span>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>

            <Col xs="12" md="6" xl="3">
              <Link to = "/listEOI">
              <div class="dash-card mx-auto m-5">
                <div class="content">
                  <div>
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/deoi.svg")} className="icon"/>
                    </div>
                    <div class="ml-2">
                      <h3 class="mb-4">My EOI</h3>
                      <span>{`${myEOI}`}</span>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>

            <Col xs="12" md="6" xl="3">
              <Link activeClassName="link" to="/allRFP">
              <div class="dash-card mx-auto m-5">
                <div class="content">
                  <div>
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Design/dopenrfp.svg")} className="icon"/>
                    </div>
                    <div class="ml-2">
                      <h3 class="mb-4">Open RFP</h3>
                      <span>{`${openRFP}`}</span>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>

            <Col xs="12" md="6" xl="3">
             <div class="dash-card mx-auto m-5">
              <div class="content">
                <div>
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Design/dtotalauction.svg")} className="icon"/>
                  </div>
                  <div class="ml-2">
                    <h3 class="mb-4">Total Auction</h3>
                    <span>{`${auction}`}</span>
                  </div>
                </div>
              </div>
            </Col> 
            </Row>  
            </>
          )}

        <Row className="px-md-5 px-3">
          <Col sm="12" className="mb-5">
            <h2>Ongoing RFP's</h2>
          </Col>
          <Col>
            <SliderDom></SliderDom>
          </Col>
        </Row>
      </>
    );
}
