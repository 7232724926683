
import React from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { loginOtp } from "../_redux/authCrud";
import { Link } from "react-router-dom";
import { login } from "../_redux/authCrud";
import {FaArrowCircleLeft} from "react-icons/fa"
import configFile from '../../../../config.js';

const STATIC_WEB_URL = configFile.STATIC_WEB_URL;
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/


function LoingOtp(props) {
  let history = useHistory();
  const initialValues = {
    ...JSON.parse(sessionStorage.getItem("LOGIN")),
    otp: "",
  };
  const { intl } = props;
  //const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    mobile: Yup.string()
      .min(10, "Minimum 3 symbols")
      .max(15, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    otp: Yup.string()
      .matches(/^[0-9]+$/, "Please enter valid OTP.")
      .min(6, 'Please enter six-digit OTP.')
      .max(6, 'Please enter six-digit OTP.')
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
  });

  // const enableLoading = () => {
  //   setLoading(true);
  // };

  // const disableLoading = () => {
  //   setLoading(false);
  // };
  const resendOTP =()=>{
    //console.log(12345656,);
    login(initialValues.mobile)
    .then(async (data) => {
     
    }).catch((e) => {
      console.log("Failwed Res")
      console.log(e);
    })
  }
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
        loginOtp(values.mobile, values.otp)
          .then(async (data) => {
            const {token,user} = data;
            if(token){
              localStorage.setItem("LOGGED_USER",JSON.stringify(user))
              localStorage.setItem('AUTH_TOKEN', token);

              //iframe local storage issue
              var win = document.getElementsByTagName('iframe')[0].contentWindow;
              win.postMessage(JSON.stringify(
                {key: 'LOGGED_USER', data: user}
              ), "*");
              win.postMessage(JSON.stringify(
                {key: 'AUTH_TOKEN', data: token}
              ), "*");
              //end iframe

              setTimeout(() => {
                window.location.reload(); 
                history.push('/dashboard');
              }, 1000);
            }else{
              setStatus(
                intl.formatMessage({
                   id: "AUTH.VALIDATION.INCORRECT_OTP",
                }));
            }
          })
          .catch((e) => {
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
    },
  });

  return (
    <>
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
       
      <div className="text-center mb-10 mb-lg-20" >
        <h3 className="font-size-h1">
        <Link 
            type='submit'
            to='/auth/login'
          >
            <FaArrowCircleLeft style = {{marginRight:10}}/>
          </Link> 
          
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Mobile"
            type="mobile"
            disabled
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "mobile"
            )}`}
            name="mobile"
            {...formik.getFieldProps("mobile")}
          />
          {formik.touched.mobile && formik.errors.mobile ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.mobile}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="OTP"
            type="otp"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "otp"
            )}`}
            name="otp"
            {...formik.getFieldProps("otp")}
          />
          {formik.touched.otp && formik.errors.otp ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.otp}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
        <Link
            onClick={()=>{
              resendOTP()
            }}>
            <span>Resend OTP</span>
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
           
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
    <iframe title="iframe" style={{display:"none", visibility:"hidden"}} src={`${STATIC_WEB_URL}iframe.html`}>https://bonolo.in/iframe.html</iframe>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(LoingOtp));
